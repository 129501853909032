import i18n from "i18n"
import { List } from "immutable"

import { param } from "shared/djangio/Manager"
import regexes from "shared/imports/regex"
/**
 * Function that determines whether ANY of the provided permissions are satisfied;
 * if so, return true.
 */
export const hasAnyPermissions = (orgPermissions, requiredPermissions) => {
    if (!requiredPermissions) {
        return true
    }

    return requiredPermissions.some(
        (permissionValue) => orgPermissions[DjangIO.app.models.PermissionType.by_value(permissionValue).label],
    )
}

/**
 * Function that determines whether the required permissions are a subset
 * of the org permissions. If they are, return true. If they aren't, return false.
 * ALL permissions pass to requiredPermissions must be enabled for the function
 * to pass.
 *
 * @param  Object orgPermissions                an object of all the permissions an object has.
 * @param  Array<Number> requiredPermissions    the necessary permissions.
 * @return Boolean      whether or not the required permissions are all within orgPermissions.
 */
export const hasAllPermissions = (orgPermissions, requiredPermissions) => {
    // check to see if our organization has all necessary permissions
    // in order to show a widget.

    // if there are no required permissions, then we can always show it!
    if (!requiredPermissions) {
        return true
    }

    return requiredPermissions.every(
        (permissionValue) => orgPermissions[DjangIO.app.models.PermissionType.by_value(permissionValue).label],
    )
}

/**
 * Given a path, strip starting and trailing slashes. The uniqueWidgetId
 * can be generated by the current route path, which can be formatted
 * as "campaign/whale", "/campaign/whale", or "/campaign/whale/". For
 * the sake of consistency, we'll remove all exterior slashes.
 *
 * @param  {string} path the path to be stripped.
 * @return {string}
 */
export const trimPath = (path) => path.replace(/^\/|\/$/g, "")

/**
 * Given a base path and a subpath, append the paths together
 * while also avoiding a problem where our joined path becomes
 * something like "path//subpath". Ah, trailing slashes.
 *
 * @param  {string} base
 * @param  {string} appended
 *
 * @return {string} the joined path, with no extra slashes in between.
 */
export const joinPaths = (base, appended, { shouldAppendTrailingSlash = true } = {}) => {
    const strippedBase = trimPath(base.toString())
    const strippedAppended = appended ? "/" + trimPath(appended.toString()) : ""

    return shouldAppendTrailingSlash ? `/${strippedBase}${strippedAppended}/` : `/${strippedBase}${strippedAppended}`
}

/**
 * Given a full url (think campaign.success_redirection_url), determine
 * if the url is internal to the site (something we can access via React Router)
 * or external (something we need to use window.location). Right now
 * specifically used for campaign-redirects; can be generalized later.
 *
 * @param  {string} url the url to be parsed.
 * @param  {object} options the various options to configure the path object
 *                      addRedirectParam - adds redirect param '?redirect=true' to the url
 * @return {object} an object with an isInternal (bool) and the url.
 */
export const getPathObject = (url, options = { addRedirectParam: true }) => {
    // if it starts with a slash, we'll assume that it is an internal redirect.
    if (url.charAt(0) === "/") {
        return {
            isInternal: true,
            url: joinPaths(url, options.addRedirectParam ? "?redirect=true" : "", { shouldAppendTrailingSlash: false }),
        }
    }

    return url.includes(window.location.origin)
        ? {
              isInternal: true,
              url: joinPaths(
                  url.split(window.location.origin).pop(),
                  options.addRedirectParam ? "?redirect=true" : "",
                  { shouldAppendTrailingSlash: false },
              ),
          }
        : { isInternal: false, url }
}

/**
 * Given text and a supporter, rudimentarily substitute
 * placeholders. This is only if we stick to using simple placeholders.
 * But why wouldn't we?
 */
export const simpleReplacePlaceholders = (text, supporter = {}) => {
    if (!text) {
        return text
    }

    const address = supporter.input_address || ""

    const placeholderMap = {
        "{{contact_first_name}}": supporter.firstname || "Friend",
        // these are only here to support old campaigns that might use them.
        // We're not going to use them going forward.
        "{{contact_last_name}}": supporter.lastname || "Friend",
        "{{contact_full_name}}": supporter.name || "Friend",
        "{{contact_state_name}}": supporter.state_name || "your state",
        "{{contact_address}}": address.replace(", USA", "") || "your address",
        "{{contact_email}}": supporter.email || "your email",
        "{{contact_state_capital}}": "your state capital",
        "{{contact_phone_number}}": supporter.phone_number || "your phone number",
        "{{contact_quorum_id}}": supporter.id || "0",
    }

    const replacedText = Object.keys(placeholderMap).reduce((acc, key) => {
        const re = new RegExp(key, "g")

        return acc.replace(re, placeholderMap[key])
    }, text)

    return replacedText
}

export const editablePlaceholderMap = {
    // Contact placeholders
    "{{contact_first_name}}": "@first_name",
    "{{contact_last_name}}": "@last_name",
    "{{contact_state_name}}": "@state",
    "{{contact_country_name}}": "@country",
    "{{contact_email}}": "@email",
    "{{contact_full_name}}": "@full_name",
    "{{contact_address}}": "@address",
    "{{contact_state_capital}}": "@state_capital",
    "{{contact_appropriate_greeting}}": "@greeting",
    "{{contact_organization_name}}": "@organization",
    "{{contact_state_upper_rep}}": "@state_upper_representative",
    "{{contact_state_lower_rep}}": "@state_lower_representative",
    "{{contact_representative}}": "@representative",
    "{{contact_senators}}": "@senator",
    "{{contact_state_upper_rep_phone_number}}": "@state_upper_representative's_phone_number",
    "{{contact_state_lower_rep_phone_number}}": "@state_lower_representative's_phone_number",
    "{{contact_representative_phone_number}}": "@representative's_phone_number",
    "{{contact_senators_phone_number}}": "@senator's_phone_number",
    "{{contact_city_and_state}}": "@city_and_state",
    "{{contact_phone_number}}": "@phone_number",
    "{{contact_quorum_id}}": "@quorum_id",
    // official placeholders
    "{{target_first_name}}": "@legislator_first_name",
    "{{target_last_name}}": "@legislator_last_name",
    "{{target_appropriate_greeting}}": "@legislator_greeting",
    "{{target_pronoun}}": "@legislator_pronoun",
    "{{target_pronoun_object}}": "@legislator_pronoun_object",
    "{{target_full_name}}": "@legislator_full_name",
    "{{target_title}}": "@legislator_title",
    "{{target_twitter_handle}}": "@legislator_twitter",
    "{{target_state}}": "@legislator_state",
    "{{target_organization_name}}": "@legislator_organization",
    "{{target_title_last_name}}": "@legislator_title_last_name",
    "{{target_office_or_committee}}": "@legislator_committee",
    "{{target_chamber}}": "@legislator_chamber",
    "{{target_district_tag}}": "@legislator_district",
}

/**
 * Given the campaign message, replace any existing placeholders with a default text.
 *
 * @param  {string} text campaign message including placeholders
 * @return {string} campaign message with placeholders replaced
 */
export const campaignMessagePreviewReplacePlaceholders = (text) => {
    if (!text) {
        return text
    }

    const placeholderMap = {
        // Contact placeholders
        "{{contact_first_name}}": "First Name",
        "{{contact_last_name}}": "Last Name",
        "{{contact_state_name}}": "State",
        "{{contact_country_name}}": "Country",
        "{{contact_email}}": "Email",
        "{{contact_full_name}}": "Full Name",
        "{{contact_address}}": "Address",
        "{{contact_state_capital}}": "State Capital",
        "{{contact_appropriate_greeting}}": "Greeting",
        "{{contact_organization_name}}": "Organization",
        "{{contact_state_upper_rep}}": "State Upper Representative",
        "{{contact_state_lower_rep}}": "State Lower Representative",
        "{{contact_representative}}": "Representative",
        "{{contact_senators}}": "Senator",
        "{{contact_state_upper_rep_phone_number}}": "State Upper Representative's Phone Number",
        "{{contact_state_lower_rep_phone_number}}": "State Lower Representative's Phone Number",
        "{{contact_representative_phone_number}}": "Representative's Phone Number",
        "{{contact_senators_phone_number}}": "Senator's Phone Number",
        "{{contact_city_and_state}}": "City and State",
        "{{contact_phone_number}}": "Phone Number",
        "{{contact_quorum_id}}": "Quorum ID",
        // Official placeholders
        "{{target_first_name}}": "Official First Name",
        "{{target_last_name}}": "Official Last Name",
        "{{target_appropriate_greeting}}": "Official Greeting",
        "{{target_pronoun}}": "Official Pronoun",
        "{{target_pronoun_object}}": "Official Pronoun",
        "{{target_full_name}}": "Official Full Name",
        "{{target_title}}": "Official Title",
        "{{target_twitter_handle}}": "Official Twitter",
        "{{target_state}}": "Official State",
        "{{target_organization_name}}": "Official Organization",
        "{{target_title_last_name}}": "Official Title",
        "{{target_office_or_committee}}": "Official Committee",
        "{{target_chamber}}": "Official Chamber",
        "{{target_district_tag}}": "Official District",
    }

    const replacedText = Object.keys(placeholderMap).reduce((acc, key) => {
        const re = new RegExp(key, "g")

        return acc.replace(re, editablePlaceholderMap[key])
    }, text)

    return replacedText
}

/**
 * Replace the backend placeholders on the text for user-readable ones.
 * @param {string} text
 * @returns {string} text with user-readable placeholders
 */
export const replaceEditablePlaceholders = (text) => {
    if (!text) {
        return text
    }

    const replacedText = Object.keys(editablePlaceholderMap).reduce((acc, key) => {
        const re = new RegExp(key, "g")

        return acc.replace(re, editablePlaceholderMap[key])
    }, text)

    return replacedText
}
/**
 * Replaces the placeholders in the message with the user-readable ones.
 * @param {object} message
 * @returns {object} message with user-readable placeholders
 */
export const insertMessageEditablePlaceholders = (message) => {
    return {
        ...message,
        bodies: [replaceEditablePlaceholders(message.bodies?.[0])],
        subject: replaceEditablePlaceholders(message.subject),
        prebody: replaceEditablePlaceholders(message.prebody),
        postbody: replaceEditablePlaceholders(message.postbody),
    }
}

/**
 * Replace the user-readable placeholders with the ones that are used in the backend.
 * @param {string} text
 * @returns {string} text with placeholders replaced with their original values
 */
export const reinsertPlaceholders = (text) => {
    if (!text) {
        return text
    }

    const reversedPlaceholderMap = Object.fromEntries(
        Object.entries(editablePlaceholderMap).map(([key, value]) => [value, key]),
    )

    //sorting the placeholders so that we avoid mixing @legislator_something_name with @legislator_something
    const sortedKeys = Object.keys(reversedPlaceholderMap).sort((a, b) => b.length - a.length)

    const replacedText = sortedKeys.reduce((acc, key) => {
        const re = new RegExp(key, "g")
        return acc.replace(re, reversedPlaceholderMap[key])
    }, text)

    return replacedText
}

/**
 * Opens an external window. Used for social media shares; we want them
 * to share, but don't want to route them away from Quorum!
 *
 * @param  {string} actionUrl   the url that the new window should open to.
 * @return {null}
 */
const openSocialWindow = (actionUrl) => window.open(actionUrl, "", "width=500,height=300")

/**
 * Open a window with pre-populated Facebook content.
 *
 * @param  {object} an object that contains share details
 *                  {string} url    the link to be shared.
 * @return {null}
 */
const shareOnFacebook = ({ url }) => {
    const actionUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`

    openSocialWindow(actionUrl)
}

/**
 * Open a window with pre-populated Twitter content.
 *
 * @param  {object} an object that contains share details
 *                  {string} url    the link to be shared.
 *                  {string} text   the content of the message.
 * @return {null}
 */
export const shareOnTwitter = ({ text, url }) => {
    // strip out the undefineds!
    const params = param({ text, url }, true)

    const actionUrl = `https://twitter.com/intent/tweet?${params}`

    openSocialWindow(actionUrl)
}

/**
 * Open a window with pre-populated Pinterest content.
 *
 * @param  {object} an object that contains share details
 *                  {string} url    the link to be shared.
 *                  {string} text   the content of the message.
 *                  {media} media   link to image associated with this campaign.
 * @return {null}
 */
const shareOnPinterest = ({ text, url, media }) => {
    const params = param({ url, media, description: text }, true)

    const actionUrl = `http://pinterest.com/pin/create/button/?${params}`

    openSocialWindow(actionUrl)
}

/**
 * Open a window with pre-populated LinkedIn content.
 *
 * @param  {object} an object that contains share details
 *                  {string} url    the link to be shared.
 *                  {string} text   the content of the message.
 * @return {null}
 */
const shareOnLinkedIn = ({ text, url }) => {
    const params = param({ title: text, url, summary: text }, true)

    const actionUrl = `http://www.linkedin.com/shareArticle?${params}`

    openSocialWindow(actionUrl)
}

const { SocialMediaType } = DjangIO.app.grassroots.enums

/**
 * Given a list of allowed social media, we must generate
 * buttons that'll link to each respective social media type.
 * Best to make a map for that.
 */
export const socialMediaShareMap = {
    [SocialMediaType.facebook.value]: shareOnFacebook,
    [SocialMediaType.twitter.value]: shareOnTwitter,
    [SocialMediaType.linkedin.value]: shareOnLinkedIn,
    [SocialMediaType.pinterest.value]: shareOnPinterest,
}

/**
 * Given a url pathname, strip the surrounding '/' characters.
 * Replace invalid characters with '-'
 * Valid characters include letters, numbers, hyphens, and underscores
 *
 * @param  {string} pathname the current url pathname
 * @return {string} parsed url pathname
 */
export const getBodyClassnameFromPathname = (pathname = "") => {
    let className = pathname

    // Remove first character if that character is '/'
    if (className && className[0] === "/") {
        className = className.slice(1)
    }

    // Remove last character if character is '/'
    if (className && className[className.length - 1] === "/") {
        className = className.slice(0, -1)
    }

    // Replace all invalid characters with '-'
    return className.replace(/[^a-zA-Z0-9-_]/g, "-")
}

/**
 * Run Javascript provided by the user
 *
 * @param js Javascript provided by the user
 */
export const runUserJavascript = (js) => {
    try {
        // (0, eval) ensures eval is an indirect call so it runs in the global scope
        // eslint-disable-next-line no-eval
        ;(0, eval)(js)
    } catch (e) {
        console.warn("user-provided javascript failed, error will be logged immediately below")
        console.error(e)
    }
}

/**
 * Updates or creates a style tag with the given CSS. If no element exists with the id, a style with that id
 * is created and put in the HEAD of the document, unless css is an empty string in which case no element
 * is created.
 *
 * @param {string} id id of the script tag
 * @param {string} css string of CSS to put in style tag
 */
export const updateOrCreateStyleTagWithId = (id, css) => {
    let styleTag = document.getElementById(id)
    if (!styleTag && !css) return
    if (!styleTag) {
        styleTag = document.createElement("style")
        styleTag.id = id
        document.head.appendChild(styleTag)
    }
    styleTag.innerHTML = css
}

/**
 * This will initiate the navigation bar with keyboard controls for accessbility
 * Scripts found at https://www.w3.org/TR/wai-aria-practices/examples/menubar/menubar-1/menubar-1.html#ex_label
 * @param {string} menubarId id of the menubar element
 */
export const navigationBarInit = (menubarId) => {
    let firstItem = null
    let lastItem = null

    let popupObj = false

    /*
     *   Copyright © [2021] World Wide Web Consortium, (Massachusetts Institute of Technology, European Research Consortium for Informatics and Mathematics, Keio University, Beihang).
     *   All Rights Reserved. This work is distributed under the W3C® Software License [1] in the hope that it will be useful,
     *   but WITHOUT ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
     *   [1] http://www.w3.org/Consortium/Legal/copyright-software
     */
    var PopupMenu = function (domNode, controllerObj) {
        var msgPrefix = "PopupMenu constructor argument domNode "

        // Check whether domNode is a DOM element
        // eslint-disable-next-line no-unsafe-negation
        if ((!domNode) instanceof Element) {
            throw new TypeError(msgPrefix + "is not a DOM Element.")
        }
        // Check whether domNode has child elements
        if (domNode.childElementCount === 0) {
            throw new Error(msgPrefix + "has no element children.")
        }
        // Check whether domNode descendant elements have A elements
        var childElement = domNode.firstElementChild
        while (childElement) {
            var menuitem = childElement.firstElementChild
            if (menuitem && menuitem === "A") {
                throw new Error(msgPrefix + "has descendant elements that are not A elements.")
            }
            childElement = childElement.nextElementSibling
        }

        this.isMenubar = false

        this.domNode = domNode
        this.controller = controllerObj

        this.menuitems = [] // See PopupMenu init method
        this.firstChars = [] // See PopupMenu init method

        firstItem = null // See PopupMenu init method
        lastItem = null // See PopupMenu init method

        this.hasFocus = false // See MenuItem handleFocus, handleBlur
        this.hasHover = false // See PopupMenu handleMouseover, handleMouseout
    }

    /*
     *   @method PopupMenu.prototype.init
     *
     *   @desc
     *       Add domNode event listeners for mouseover and mouseout. Traverse
     *       domNode children to configure each menuitem and populate menuitems
     *       array. Initialize firstItem and lastItem properties.
     */
    PopupMenu.prototype.init = function () {
        var childElement, menuElement, menuItem, textContent, numItems

        // Configure the domNode itself

        this.domNode.addEventListener("mouseover", this.handleMouseover.bind(this))
        this.domNode.addEventListener("mouseout", this.handleMouseout.bind(this))

        // Traverse the element children of domNode: configure each with
        // menuitem role behavior and store reference in menuitems array.
        childElement = this.domNode.firstElementChild

        while (childElement) {
            menuElement = childElement.firstElementChild || childElement

            if (menuElement && menuElement.tagName === "A") {
                menuItem = new MenuItem(menuElement, this)
                menuItem.init()
                this.menuitems.push(menuItem)
                textContent = menuElement.textContent.trim()
                this.firstChars.push(textContent.substring(0, 1).toLowerCase())
            }
            childElement = childElement.nextElementSibling
        }

        // Use populated menuitems array to initialize firstItem and lastItem.
        numItems = this.menuitems.length
        if (numItems > 0) {
            firstItem = this.menuitems[0]
            lastItem = this.menuitems[numItems - 1]
        }
    }

    /* EVENT HANDLERS */

    PopupMenu.prototype.handleMouseover = function () {
        this.hasHover = true
    }

    PopupMenu.prototype.handleMouseout = function () {
        this.hasHover = false
        setTimeout(this.close.bind(this, false), 1)
    }

    /* FOCUS MANAGEMENT METHODS */

    PopupMenu.prototype.setFocusToController = function (command, flag) {
        if (typeof command !== "string") {
            command = ""
        }

        function setFocusToMenubarItem(controller, close) {
            while (controller) {
                if (controller.isMenubarItem) {
                    controller.domNode.focus()
                    return controller
                } else {
                    if (close) {
                        controller.menu.close(true)
                    }
                    controller.hasFocus = false
                }
                controller = controller.menu.controller
            }
            return false
        }

        if (command === "") {
            if (this.controller && this.controller.domNode) {
                this.controller.domNode.focus()
            }
            return
        }

        if (!this.controller.isMenubarItem) {
            this.controller.domNode.focus()
            this.close()

            if (command === "next") {
                var menubarItem = setFocusToMenubarItem(this.controller, false)
                if (menubarItem) {
                    menubarItem.menu.setFocusToNextItem(menubarItem, flag)
                }
            }
        } else {
            if (command === "previous") {
                this.controller.menu.setFocusToPreviousItem(this.controller, flag)
            } else if (command === "next") {
                this.controller.menu.setFocusToNextItem(this.controller, flag)
            }
        }
    }

    PopupMenu.prototype.setFocusToFirstItem = function () {
        this.domNode.firstElementChild.focus()
    }

    PopupMenu.prototype.setFocusToLastItem = function () {
        lastItem.domNode.focus()
    }

    PopupMenu.prototype.setFocusToPreviousItem = function (currentItem) {
        var index

        if (currentItem === firstItem) {
            lastItem.domNode.focus()
        } else {
            index = this.menuitems.indexOf(currentItem)

            const previousMenuItem = this.menuitems[index - 1]
            if (previousMenuItem) {
                previousMenuItem.domNode.focus()
            }
        }
    }

    PopupMenu.prototype.setFocusToNextItem = function (currentItem) {
        var index

        if (currentItem === lastItem) {
            firstItem.domNode.focus()
        } else {
            index = this.menuitems.indexOf(currentItem)

            const nextMenuItem = this.menuitems[index + 1]
            if (nextMenuItem) {
                nextMenuItem.domNode.focus()
            }
        }
    }

    PopupMenu.prototype.setFocusByFirstCharacter = function (currentItem, char) {
        var start,
            index,
            charLower = char.toLowerCase()

        // Get start index for search based on position of currentItem
        start = this.menuitems.indexOf(currentItem) + 1
        if (start === this.menuitems.length) {
            start = 0
        }

        // Check remaining slots in the menu
        index = this.getIndexFirstChars(start, charLower)

        // If not found in remaining slots, check from beginning
        if (index === -1) {
            index = this.getIndexFirstChars(0, charLower)
        }

        // If match was found...
        if (index > -1) {
            this.menuitems[index].domNode.focus()
        }
    }

    PopupMenu.prototype.getIndexFirstChars = function (startIndex, char) {
        for (var i = startIndex; i < this.firstChars.length; i++) {
            if (char === this.firstChars[i]) {
                return i
            }
        }
        return -1
    }

    /* MENU DISPLAY METHODS */

    PopupMenu.prototype.open = function () {
        // Get position and bounding rectangle of controller object's DOM node
        var rect = this.controller.domNode.getBoundingClientRect()

        // Set CSS properties
        if (!this.controller.isMenubarItem) {
            this.domNode.parentNode.style.position = "relative"
            this.domNode.style.display = "block"
            this.domNode.style.position = "absolute"
            this.domNode.style.left = rect.width + "px"
            this.domNode.style.zIndex = 100
        } else {
            this.domNode.style.display = "block"
            this.domNode.style.position = "absolute"
            this.domNode.style.top = rect.height - 1 + "px"
            this.domNode.style.zIndex = 100
        }

        this.controller.setExpanded(true)
    }

    PopupMenu.prototype.close = function (force) {
        var controllerHasHover = this.controller.hasHover

        var hasFocus = this.hasFocus

        for (var i = 0; i < this.menuitems.length; i++) {
            var mi = this.menuitems[i]
            if (mi.popupMenu) {
                hasFocus = hasFocus | mi.popupMenu.hasFocus
            }
        }

        if (!this.controller.isMenubarItem) {
            controllerHasHover = false
        }

        if (force || (!hasFocus && !this.hasHover && !controllerHasHover)) {
            this.domNode.style.display = "none"
            this.domNode.style.zIndex = 0
            this.controller.setExpanded(false)
        }
    }

    /*
     *   Copyright © [2021] World Wide Web Consortium, (Massachusetts Institute of Technology, European Research Consortium for Informatics and Mathematics, Keio University, Beihang).
     *   All Rights Reserved. This work is distributed under the W3C® Software License [1] in the hope that it will be useful,
     *   but WITHOUT ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
     *   [1] http://www.w3.org/Consortium/Legal/copyright-software
     */
    var MenuItem = function (domNode, menuObj) {
        if (typeof popupObj !== "object") {
            popupObj = false
        }

        this.domNode = domNode
        this.menu = menuObj
        this.popupMenu = false
        this.isMenubarItem = false

        this.keyCode = Object.freeze({
            TAB: 9,
            RETURN: 13,
            ESC: 27,
            SPACE: 32,
            PAGEUP: 33,
            PAGEDOWN: 34,
            END: 35,
            HOME: 36,
            LEFT: 37,
            UP: 38,
            RIGHT: 39,
            DOWN: 40,
        })
    }

    MenuItem.prototype.init = function () {
        this.domNode.tabIndex = -1

        this.domNode.addEventListener("keydown", this.handleKeydown.bind(this))
        this.domNode.addEventListener("click", this.handleClick.bind(this))
        this.domNode.addEventListener("focus", this.handleFocus.bind(this))
        this.domNode.addEventListener("blur", this.handleBlur.bind(this))
        this.domNode.addEventListener("mouseover", this.handleMouseover.bind(this))
        this.domNode.addEventListener("mouseout", this.handleMouseout.bind(this))

        // Initialize flyout menu

        var nextElement = this.domNode.nextElementSibling

        if (nextElement && nextElement.tagName === "UL") {
            this.popupMenu = new PopupMenu(nextElement, this)
            this.popupMenu.init()
        }
    }

    MenuItem.prototype.isExpanded = function () {
        return this.domNode.getAttribute("aria-expanded") === "true"
    }

    /* EVENT HANDLERS */

    MenuItem.prototype.handleKeydown = function (event) {
        var tgt = event.currentTarget,
            char = event.key,
            flag = false,
            clickEvent

        function isPrintableCharacter(str) {
            return str.length === 1 && str.match(/\S/)
        }

        switch (event.keyCode) {
            case this.keyCode.SPACE:
            case this.keyCode.RETURN:
                if (this.popupMenu) {
                    this.popupMenu.open()
                    this.popupMenu.setFocusToFirstItem()
                } else {
                    // Create simulated mouse event to mimic the behavior of ATs
                    // and let the event handler handleClick do the housekeeping.
                    try {
                        clickEvent = new MouseEvent("click", {
                            view: window,
                            bubbles: true,
                            cancelable: true,
                        })
                    } catch (err) {
                        if (document.createEvent) {
                            // DOM Level 3 for IE 9+
                            clickEvent = document.createEvent("MouseEvents")
                            clickEvent.initEvent("click", true, true)
                        }
                    }
                    tgt.dispatchEvent(clickEvent)
                }

                flag = true
                break

            case this.keyCode.UP:
                this.menu.setFocusToPreviousItem(this)
                flag = true
                break

            case this.keyCode.DOWN:
                this.menu.setFocusToNextItem(this)
                flag = true
                break

            case this.keyCode.LEFT:
                this.menu.setFocusToController("previous", true)
                this.menu.close(true)
                flag = true
                break

            case this.keyCode.RIGHT:
                if (this.popupMenu) {
                    this.popupMenu.open()
                    this.popupMenu.setFocusToFirstItem()
                } else {
                    this.menu.setFocusToController("next", true)
                    this.menu.close(true)
                }
                flag = true
                break

            case this.keyCode.HOME:
            case this.keyCode.PAGEUP:
                this.menu.setFocusToFirstItem()
                flag = true
                break

            case this.keyCode.END:
            case this.keyCode.PAGEDOWN:
                this.menu.setFocusToLastItem()
                flag = true
                break

            case this.keyCode.ESC:
                this.menu.setFocusToController()
                this.menu.close(true)
                flag = true
                break

            case this.keyCode.TAB:
                this.menu.setFocusToController()
                break

            default:
                if (isPrintableCharacter(char)) {
                    this.menu.setFocusByFirstCharacter(this, char)
                    flag = true
                }
                break
        }

        if (flag) {
            event.stopPropagation()
            event.preventDefault()
        }
    }

    MenuItem.prototype.setExpanded = function (value) {
        if (value) {
            this.domNode.setAttribute("aria-expanded", "true")
        } else {
            this.domNode.setAttribute("aria-expanded", "false")
        }
    }

    MenuItem.prototype.handleClick = function () {
        this.menu.setFocusToController()
        this.menu.close(true)
    }

    MenuItem.prototype.handleFocus = function () {
        this.menu.hasFocus = true
    }

    MenuItem.prototype.handleBlur = function () {
        this.menu.hasFocus = false
        setTimeout(this.menu.close.bind(this.menu, false), 300)
    }

    MenuItem.prototype.handleMouseover = function () {
        this.menu.hasHover = true
        this.menu.open()
        if (this.popupMenu) {
            this.popupMenu.hasHover = true
            this.popupMenu.open()
        }
    }

    MenuItem.prototype.handleMouseout = function () {
        if (this.popupMenu) {
            this.popupMenu.hasHover = false
            this.popupMenu.close(true)
        }

        this.menu.hasHover = false
        setTimeout(this.menu.close.bind(this.menu, false), 300)
    }

    /*
     *   Copyright © [2021] World Wide Web Consortium, (Massachusetts Institute of Technology, European Research Consortium for Informatics and Mathematics, Keio University, Beihang).
     *   All Rights Reserved. This work is distributed under the W3C® Software License [1] in the hope that it will be useful,
     *   but WITHOUT ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
     *   [1] http://www.w3.org/Consortium/Legal/copyright-software
     */

    var Menubar = function (domNode) {
        var msgPrefix = "Menubar constructor argument menubarNode "

        // Check whether menubarNode is a DOM element
        // eslint-disable-next-line no-unsafe-negation
        if ((!domNode) instanceof Element) {
            throw new TypeError(msgPrefix + "is not a DOM Element.")
        }

        // Check whether menubarNode has descendant elements
        if (domNode.childElementCount === 0) {
            throw new Error(msgPrefix + "has no element children.")
        }

        // Check whether menubarNode has A elements
        let e = domNode.firstElementChild
        while (e) {
            var menubarItem = e.firstElementChild
            if (e && menubarItem && menubarItem.tagName !== "A") {
                throw new Error(msgPrefix + "has child elements are not A elements.")
            }
            e = e.nextElementSibling
        }

        this.isMenubar = true

        this.domNode = domNode

        this.menubarItems = [] // See Menubar init method
        this.firstChars = [] // See Menubar init method

        firstItem = null // See Menubar init method
        lastItem = null // See Menubar init method

        this.hasFocus = false // See MenubarItem handleFocus, handleBlur
        this.hasHover = false // See Menubar handleMouseover, handleMouseout
    }

    /*
     *   @method Menubar.prototype.init
     *
     *   @desc
     *       Adds ARIA role to the menubar node
     *       Traverse menubar children for A elements to configure each A element as a ARIA menuitem
     *       and populate menuitems array. Initialize firstItem and lastItem properties.
     */
    Menubar.prototype.init = function () {
        var menubarItem, menuElement, textContent, numItems

        // Traverse the element children of menubarNode: configure each with
        // menuitem role behavior and store reference in menuitems array.
        let elem = this.domNode.firstElementChild

        while (elem) {
            menuElement = elem.firstElementChild

            if (elem && menuElement && menuElement.tagName === "A") {
                menubarItem = new MenubarItem(menuElement, this)
                menubarItem.init()
                this.menubarItems.push(menubarItem)
                textContent = menuElement.textContent.trim()
                this.firstChars.push(textContent.substring(0, 1).toLowerCase())
            }

            elem = elem.nextElementSibling
        }

        // Use populated menuitems array to initialize firstItem and lastItem.
        numItems = this.menubarItems.length
        if (numItems > 0) {
            firstItem = this.menubarItems[0]
            lastItem = this.menubarItems[numItems - 1]
        }
        firstItem.domNode.tabIndex = 0
    }

    /* FOCUS MANAGEMENT METHODS */

    Menubar.prototype.setFocusToItem = function (newItem) {
        var flag = false

        for (var i = 0; i < this.menubarItems.length; i++) {
            var mbi = this.menubarItems[i]

            // eslint-disable-next-line eqeqeq
            if (mbi.domNode.tabIndex == 0) {
                flag = mbi.domNode.getAttribute("aria-expanded") === "true"
            }

            mbi.domNode.tabIndex = -1
            if (mbi.popupMenu) {
                mbi.popupMenu.close()
            }
        }

        newItem.domNode.focus()
        newItem.domNode.tabIndex = 0

        if (flag && newItem.popupMenu) {
            newItem.popupMenu.open()
        }
    }

    Menubar.prototype.setFocusToFirstItem = function () {
        this.setFocusToItem(firstItem)
    }

    Menubar.prototype.setFocusToLastItem = function () {
        this.setFocusToItem(lastItem)
    }

    Menubar.prototype.setFocusToPreviousItem = function (currentItem) {
        var index, newItem

        if (currentItem === firstItem) {
            newItem = lastItem
        } else {
            index = this.menubarItems.indexOf(currentItem)
            newItem = this.menubarItems[index - 1]
        }

        this.setFocusToItem(newItem)
    }

    Menubar.prototype.setFocusToNextItem = function (currentItem) {
        var index, newItem

        if (currentItem === lastItem) {
            newItem = firstItem
        } else {
            index = this.menubarItems.indexOf(currentItem)
            newItem = this.menubarItems[index + 1]
        }

        this.setFocusToItem(newItem)
    }

    Menubar.prototype.setFocusByFirstCharacter = function (currentItem, char) {
        var start,
            index,
            charLower = char.toLowerCase()

        // Get start index for search based on position of currentItem
        start = this.menubarItems.indexOf(currentItem) + 1
        if (start === this.menubarItems.length) {
            start = 0
        }

        // Check remaining slots in the menu
        index = this.getIndexFirstChars(start, charLower)

        // If not found in remaining slots, check from beginning
        if (index === -1) {
            index = this.getIndexFirstChars(0, charLower)
        }

        // If match was found...
        if (index > -1) {
            this.setFocusToItem(this.menubarItems[index])
        }
    }

    Menubar.prototype.getIndexFirstChars = function (startIndex, char) {
        for (var i = startIndex; i < this.firstChars.length; i++) {
            if (char === this.firstChars[i]) {
                return i
            }
        }
        return -1
    }

    /*
     *   Copyright © [2021] World Wide Web Consortium, (Massachusetts Institute of Technology, European Research Consortium for Informatics and Mathematics, Keio University, Beihang).
     *   All Rights Reserved. This work is distributed under the W3C® Software License [1] in the hope that it will be useful,
     *   but WITHOUT ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
     *   [1] http://www.w3.org/Consortium/Legal/copyright-software
     */
    var MenubarItem = function (domNode, menuObj) {
        this.menu = menuObj
        this.domNode = domNode
        this.popupMenu = false

        this.hasFocus = false
        this.hasHover = false

        this.isMenubarItem = true

        this.keyCode = Object.freeze({
            TAB: 9,
            RETURN: 13,
            ESC: 27,
            SPACE: 32,
            PAGEUP: 33,
            PAGEDOWN: 34,
            END: 35,
            HOME: 36,
            LEFT: 37,
            UP: 38,
            RIGHT: 39,
            DOWN: 40,
        })
    }

    MenubarItem.prototype.init = function () {
        this.domNode.tabIndex = -1

        this.domNode.addEventListener("keydown", this.handleKeydown.bind(this))
        this.domNode.addEventListener("focus", this.handleFocus.bind(this))
        this.domNode.addEventListener("blur", this.handleBlur.bind(this))
        this.domNode.addEventListener("mouseover", this.handleMouseover.bind(this))
        this.domNode.addEventListener("mouseout", this.handleMouseout.bind(this))

        // Initialize pop up menus

        var nextElement = this.domNode.nextElementSibling

        if (nextElement && nextElement.tagName === "UL") {
            this.popupMenu = new PopupMenu(nextElement, this)
            this.popupMenu.init()
        }
    }

    MenubarItem.prototype.handleKeydown = function (event) {
        var char = event.key,
            flag = false

        function isPrintableCharacter(str) {
            return str.length === 1 && str.match(/\S/)
        }

        switch (event.keyCode) {
            case this.keyCode.SPACE:
            case this.keyCode.RETURN:
            case this.keyCode.DOWN:
                if (this.popupMenu) {
                    this.popupMenu.open()
                    this.popupMenu.setFocusToFirstItem()
                    flag = true
                }
                break

            case this.keyCode.LEFT:
                this.menu.setFocusToPreviousItem(this)
                flag = true
                break

            case this.keyCode.RIGHT:
                this.menu.setFocusToNextItem(this)
                flag = true
                break

            case this.keyCode.UP:
                if (this.popupMenu) {
                    this.popupMenu.open()
                    this.popupMenu.setFocusToLastItem()
                    flag = true
                }
                break

            case this.keyCode.HOME:
            case this.keyCode.PAGEUP:
                this.menu.setFocusToFirstItem()
                flag = true
                break

            case this.keyCode.END:
            case this.keyCode.PAGEDOWN:
                this.menu.setFocusToLastItem()
                flag = true
                break

            case this.keyCode.TAB:
                this.popupMenu.close(true)
                break

            case this.keyCode.ESC:
                this.popupMenu.close(true)
                break

            default:
                if (isPrintableCharacter(char)) {
                    this.menu.setFocusByFirstCharacter(this, char)
                    flag = true
                }
                break
        }

        if (flag) {
            event.stopPropagation()
            event.preventDefault()
        }
    }

    MenubarItem.prototype.setExpanded = function (value) {
        if (value) {
            this.domNode.setAttribute("aria-expanded", "true")
        } else {
            this.domNode.setAttribute("aria-expanded", "false")
        }
    }

    MenubarItem.prototype.handleFocus = function () {
        this.menu.hasFocus = true
    }

    MenubarItem.prototype.handleBlur = function () {
        this.menu.hasFocus = false
    }

    MenubarItem.prototype.handleMouseover = function () {
        this.hasHover = true
        if (this.popupMenu) {
            this.popupMenu.open()
        }
    }

    MenubarItem.prototype.handleMouseout = function () {
        this.hasHover = false
        if (this.popupMenu) {
            setTimeout(this.popupMenu.close.bind(this.popupMenu, false), 300)
        }
    }

    // Custom logic
    const menubarElement = document.getElementById(menubarId)

    if (menubarElement) {
        var menubar = new Menubar(menubarElement)
        menubar.init()
    }
}

/**
 * Helpers copied from app/static/frontend/forms/helperFunctions to avoid importing the frontend router
 */

// Return 'value' field of the formValue
export const normalizeSingleChoiceValue = (formValue) =>
    formValue && typeof formValue.value !== "undefined" ? formValue.value : null

// Return a warning message string if the provided phone number
// is not of the correct format to be eligible for texting
export const phoneNumberFormatWarning = (formValue) => {
    if (!formValue) {
        return undefined
    }

    const message =
        i18n.t("form.phone_texting_format") ||
        `\
        In order to receive texts, your number must include an area code and\
        a US or Canada country code (begin with a 1).
    `
    const cleanedNumber = formValue.replace(regexes.phoneNumberReplacerRegex, "")
    if (cleanedNumber.length <= 10 || cleanedNumber[0] !== "1") {
        return message
    }
    return undefined
}

// Check if a value is given for a required field
export const requiredFieldValidation = (formValue, maxLength) => {
    const errorMessage = i18n.t("form.required_field") || "This field is required."

    if (formValue) {
        if (Array.isArray(formValue) && !formValue.length) {
            // Check if the value is an empty array. If empty, return the error message. Otherwise, do not return an error
            return errorMessage
        } else if (List.isList(formValue) && !formValue.size) {
            // Also check if value is an empty Immutable List
            return errorMessage
        } else if (formValue.trim && !formValue.trim()) {
            // Return error message if value is only empty space(s)
            return errorMessage
        }

        if (maxLength && formValue.length > maxLength) {
            // Check if the value is a string and if its length is greater than the maxLength. If so, return the error message
            return "Please limit to " + maxLength + " characters."
        }

        return undefined
    } else if (formValue === false) {
        // Checks the cases where the value is 'false'
        return undefined
    }

    return errorMessage
}

export const optionalFieldValidationWithLength = (formValue) => {
    const maxLength = 255 // Max database length for fields in database such as title, to be safe.
    if (formValue && formValue.length > maxLength) {
        // Check if the value is a string and if its length is greater than the maxLength. If so, return the error message
        return "Please limit to " + maxLength + " characters."
    }
}
export const requiredFieldValidationWithLength = (formValue) => {
    return requiredFieldValidation(formValue, 255) // Max database length for fields such as title.
}
export const requiredFieldPhoneValidation = (formValue) => {
    return requiredFieldValidation(formValue, 15) // Max database length for phone numbers in database.
}

export const getGamificationAchievementIcon = (iconTypeValue) => {
    const iconEnum = DjangIO.app.grassroots.types.GamificationAchievementIconType.by_value(iconTypeValue)

    return (iconEnum && iconEnum.fa4_icon) || "fa-trophy"
}

export const getThanksSocialButtonProps = (campaign, actionCenterSettings, organization, organizationDesign) => {
    if (!Object.keys(campaign).length || !campaign.social_media_platforms) {
        return
    }

    const socialPlatforms = campaign.social_media_platforms

    const campaignType = DjangIO.app.grassroots.campaign.types.CampaignType.by_value(campaign.campaign_type)

    const i18n_key = campaignType.use_i18n_social_share_key
        ? `campaign.thanks.share.${campaignType.key}`
        : "campaign.thanks.share.default"

    return socialPlatforms.map((platformType) => {
        const platform = DjangIO.app.grassroots.enums.SocialMediaType.by_value(platformType)

        const submitFunc = socialMediaShareMap[platformType]

        const url = window.location.href.replace("/thanks", "")
        const media = campaign.social_image_url || organizationDesign.background_image || organization.logo

        const orgName =
            platformType === DjangIO.app.grassroots.enums.SocialMediaType.twitter.value &&
            actionCenterSettings.twitter_handle
                ? `@${actionCenterSettings.twitter_handle}`
                : organization.name

        const text = campaign.social_share_text || i18n.t(i18n_key, { organization: orgName })

        return {
            onClick: () => submitFunc({ url, media, text }),
            label: i18n.t("campaign.thanks.share.button", { platform: platform.label }),
            // Replace label with new_label when ff_ngg_campaigns is remediated
            new_label: `Share ${platform.label}`,
            icon: platform.icon,
            color: platform.color,
            platform: platformType,
            dataCy: platform.label,
        }
    })
}

export const getIndexRoute = (indexPageRoute, permissions, regPageId) => {
    const hasGrassroots = permissions[DjangIO.app.models.PermissionType.action_center.label]
    const hasInteractionLogger = permissions[DjangIO.app.models.PermissionType.external_interactions.label]
    const hasRelationshipLogger = permissions[DjangIO.app.models.PermissionType.external_relationship.label]
    const hasStakeholder = permissions[DjangIO.app.models.PermissionType.stakeholder.label]

    // right now the logic is:
    // - If you have grassroots, then we operate as normal.
    // - If you don't have grassroots but you have the external logger, you get directed to the
    //   external logger.
    // - If you don't have the external logger but have the relationship logger, show that.
    // - If you have only stakeholder, show the sign up page. Note that this is NOT the sign in widget.
    //   The sign in widget's responsibility is to figure out whether or not to show a registration
    //   or sign up page.
    //
    // If you don't have any, then what the hell are you doing here?

    if (hasGrassroots) {
        return indexPageRoute
    } else if (hasInteractionLogger) {
        return DjangIO.app.grassroots.types.GrassrootsWidgetType.log_interaction.widget_url
    } else if (hasRelationshipLogger) {
        return DjangIO.app.grassroots.types.GrassrootsWidgetType.log_relationship.widget_url
    } else if (hasStakeholder) {
        return joinPaths(DjangIO.app.grassroots.types.GrassrootsWidgetType.sign_up.widget_url, regPageId)
    } else {
        console.warn("Organization has no relevant grassroots permission.")
    }
}
