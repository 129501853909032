import { connect } from "react-redux"

import { CombinedCampaignForm } from "QuorumGrassroots/campaign-forms/components/CombinedCampaignForm"
import { getMessageFormWrapper } from "QuorumGrassroots/campaign-forms/wrappers/GetMessagesFormWrapper"
import { combinedCampaignSelectors } from "QuorumGrassroots/campaign-forms/selectors/combined-campaign-selectors"

const messageFormWrapper = getMessageFormWrapper({
    formName: "combined_campaign",
    getKwargs: {},
    generateGrassrootsActions: (messageValues, globalFormValues, props) => {
        return messageValues.reduce((acc, value) => {
            const campaignId = value.get("campaignId")

            const gsaInfo = {
                text: [value.get("prebody"), value.get("body"), value.get("postbody")]
                    .filter((text) => text)
                    .join("\n\n"),
                subject: value.get("subject"),
                campaign: DjangIO.app.grassroots.campaign.models.Campaign.resourceUriFromId(campaignId),
                // must coerce number to string or else immutable won't find the key. Fun!
                supporter_action_type: props.extraMessageData.getIn([campaignId.toString(), "campaign_type"]),
            }

            if (!value.getIn(["target", "is_custom"]) && value.getIn(["target", "id"])) {
                gsaInfo["person"] = DjangIO.app.person.models.Person.resourceUriFromId(value.getIn(["target", "id"]))
            }

            acc.push(gsaInfo)

            return acc
        }, [])
    },
    getIdFromMessage: (message) => {
        const targetId = message.target ? message.target.id : ""
        return `${message.campaign_id}_${targetId}`
    },
    isCombinedCampaign: true,
})(CombinedCampaignForm)

export default connect(combinedCampaignSelectors, null)(messageFormWrapper)
