import React from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import PropTypes from "prop-types"

import { Icon } from "quorumdesign"

import "frontend/components/stylus/Tooltip.styl"
import "tooltip-i.styl"

export const Tip = ({ label, ...props }) => {
    const overlay = (props) => (
        <Tooltip id={label} className="tooltip-z-index" {...props}>
            {label}
        </Tooltip>
    )

    return label ? (
        <OverlayTrigger rootClose overlay={overlay} {...props} className="cursor-pointer margin-left" />
    ) : (
        props.children
    )
}

Tip.propTypes = {
    label: PropTypes.string.isRequired,
    placement: PropTypes.string,
    delayShow: PropTypes.number,
    children: PropTypes.node,
    rootClose: PropTypes.bool,
}

Tip.defaultProps = {
    rootClose: true,
    placement: "bottom",
    delayShow: 150,
    children: (
        // forwardRef isn't working on the Icon component for some reason
        <span>
            <Icon
                icon="info-circle"
                iconFamily="fas"
                // Need to set style on this element to override form-step.styl SVG rule
                style={{ height: "14px" }}
            />
        </span>
    ),
}

export default Tip
