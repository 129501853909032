import PropTypes from "prop-types"
import React, { useMemo } from "react"
import { Field } from "redux-form/immutable"

import WriteMessageFormSection from "QuorumGrassroots/campaign-forms/components/WriteMessageFormSection"
import { StyledButton } from "QuorumGrassroots/styled-components/components/StyledButton"
import { StyledGroupSelector } from "QuorumGrassroots/styled-components/components/StyledGroupSelector"
import { StyledLargeBoldText } from "QuorumGrassroots/widgets/styled-components/styles"
import { connect } from "react-redux"
import { submit } from "redux-form"
import { InputWithLock } from "QuorumGrassroots/styled-components/components/InputWithLock"
import { writeSectionConfigs } from "QuorumGrassroots/campaign-forms/write-form-constants"
import { InputField } from "app/static/frontend/forms/components/InputField"
import { shouldShowOrganizationField } from "QuorumGrassroots/campaign-forms/helpers"

const REGISTRATION_FORM_NAME = "registration"

const { CampaignType } = DjangIO.app.grassroots.campaign.types

const UnconnectedCampaignMessagePreview = ({
    campaignSubject,
    campaignMessage,
    combinedCampaignMessages,
    isCombinedCampaign,
    messageGroups,
    t,
    dispatch,
    ...props
}) => {
    const renderSubmitButton = () => {
        return (
            <StyledButton
                className="submit-button"
                onClick={() => dispatch(submit(REGISTRATION_FORM_NAME))}
                type="submit"
                isCampaignPage
                activateNGGTheme
            >
                {props.registrationSubmitButtonText ||
                    (props.submitting ? t("form.submitting") : t("campaign.write.submit_all_letters"))}
            </StyledButton>
        )
    }

    const draftOption = useMemo(() => {
        return messageGroups?.length
            ? messageGroups?.find((group) => Number(group.id) === Number(props.activeGroupId ?? messageGroups[0].id))
                  ?.advocate_editing_permission
            : props.campaign.draft_requirements
    }, [props.activeGroupId, messageGroups, props.campaign.draft_requirements])
    if (isCombinedCampaign) {
        return (
            <>
                {combinedCampaignMessages.map((campaign, idx) => (
                    <div className="message-preview-combined">
                        <StyledLargeBoldText>{campaign.name}</StyledLargeBoldText>
                        {campaign.subject && (
                            <Field
                                component={InputWithLock}
                                name={`campaign-subject-${idx}`}
                                label={t("campaign.write.subject")}
                                disabled
                            />
                        )}
                        {campaign.message && (
                            <Field
                                component={InputWithLock}
                                name={`campaign-message-${idx}`}
                                label={t("campaign.write.body")}
                                disabled
                                isTextarea
                                maxRows={8}
                            />
                        )}
                    </div>
                ))}
                {renderSubmitButton()}
            </>
        )
    }

    const isCommentOnRegulation = props.campaign.campaign_type === CampaignType.comment_on_regulation.value

    if (messageGroups?.length > 0 || isCommentOnRegulation) {
        // global is the name of the SingleBodyMessageLayout form. Just keeping it consistent for campaigns without groups
        const innerFormName = messageGroups?.length ? props.activeGroupId ?? String(messageGroups[0].id) : "global"
        return (
            <>
                {messageGroups.length > 1 && (
                    <StyledGroupSelector
                        value={props.activeGroupId ?? String(messageGroups[0].id)}
                        onChange={(groupId) => props.changeMessageGroup(props.uniqueWidgetId, groupId)}
                        data={messageGroups.map((group) => ({ value: String(group.id), label: group.name }))}
                    />
                )}
                <div>
                    <WriteMessageFormSection
                        activeId={innerFormName}
                        draftOption={draftOption}
                        formName="campaignMessagePreviewForm"
                        key={innerFormName}
                        submitting={props.submitting}
                        t={t}
                        uniqueWidgetId={props.uniqueWidgetId}
                        writeSectionConfigs={writeSectionConfigs[props.campaign.campaign_type] ?? {}}
                    />
                    {shouldShowOrganizationField(props.campaign) && (
                        <Field
                            component={InputField}
                            formName="campaignMessagePreviewForm"
                            name="global.submitting_org_name"
                            label={t("campaign.comment_on_regulation.organization")}
                            isCampaignPage
                        />
                    )}
                </div>
                {renderSubmitButton()}
            </>
        )
    }

    return (
        <>
            {campaignSubject && (
                <Field
                    component={InputWithLock}
                    name="subject"
                    label={t("campaign.write.subject")}
                    disabled
                    dataCy="subject"
                />
            )}
            {campaignMessage && (
                <Field
                    component={InputWithLock}
                    name="message"
                    label={t("campaign.write.body")}
                    disabled
                    isTextarea
                    dataCy="body"
                    maxRows={8}
                />
            )}
            {renderSubmitButton()}
        </>
    )
}

UnconnectedCampaignMessagePreview.propTypes = {
    campaignMessage: PropTypes.string,
    campaignSubject: PropTypes.string,
    combinedCampaignMessages: PropTypes.array,
    isCombinedCampaign: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
}

UnconnectedCampaignMessagePreview.defaultProps = {
    campaignMessage: "",
    campaignSubject: "",
    combinedCampaignMessages: [],
}

const mapStateToProps = (state) => {
    return {
        submitting: state.form?.getIn([REGISTRATION_FORM_NAME, "submitting"]),
    }
}

export const CampaignMessagePreview = connect(mapStateToProps)(UnconnectedCampaignMessagePreview)
