// React + Redux + React Router setup
import React, { Component, StrictMode } from "react"
import { QueryClient, QueryClientProvider, QueryCache } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import { createStore, applyMiddleware, compose } from "redux"
import thunk from "redux-thunk"
import { swalConfigs } from "QuorumGrassroots/swalConfigs"
import BACKENDERROR from "frontend/imports/backenderror"
import "bootstrap"
import "@/api"
import "font-awesome/css/font-awesome.css"
import "QuorumGrassroots/stylus/global.styl"
import "app/static/stylus/loading_new.styl"
import "imports/extensions.js"

/*
 * Internal setup, attaching DjangIO to the window. The variable
 * window.DJANGIO_ENTRY_POINT is set in `djangio_processor` in
 * `quorum/context_processors.py` (it's injected as a script). That path
 * tells the JS where to look in order to find our DjangIO cache.
 */
import "shared/djangio/initialize"
import "shared/djangio/config"

// Using i18next's tools for internationalization (it's in the main quorum-site)
import "i18n"
import App from "QuorumGrassroots/framework/containers/index"
import { reducer } from "QuorumGrassroots/reducer"

// For certain entry points in our application (like the Grassroots
// Frontend and other public facing bundles), we need to use our Selects,
// but must explicitly exclude Search and Searchify from the application's
// dependency graph. Search includes in its dependency graph whole sets of
// functionality that will not work without globally scoped variables and
// resources like Userdata or D3, as well as bloats the bundle size dramatically.
// This entry point spoofs the Searchify behavior in our Select library, and allows
// us to use our abstracted Selects without search.
import SearchifyWrapper from "search-new/components/Searchify/SearchifyWrapperDummy"

import { withRouter } from "QuorumGrassroots/withRouter"

/* **************************************************************
 * REDUCER LOGIC HERE
 **************************************************************** */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const middlewares = [thunk]

const store = createStore(reducer, composeEnhancers(applyMiddleware(...middlewares)))

window.store = store
window.SearchifyWrapper = SearchifyWrapper

// Needed to render CustomData maps in GR
// eslint-disable-next-line @typescript-eslint/no-var-requires
const d3 = require("app/static/js/libs/d3.min.js")

window.d3 = d3

// Scrolls you to the top of the window whenever a location changes.
// Taken from https://reacttraining.com/react-router/web/guides/scroll-restoration
class ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0)
        }
    }

    render() {
        return this.props.children
    }
}

const RouteredScrollToTop = withRouter(ScrollToTop)

//https://tkdodo.eu/blog/breaking-react-querys-api-on-purpose#defining-on-demand-messages
const queryClient = new QueryClient({
    queryCache: new QueryCache({
        onError: (error) => BACKENDERROR(error, swalConfigs.loadContentError, false, false),
    }),
})

/* **************************************************************
 * WRAP AND RENDER.
 **************************************************************** */
const RouterApp = (
    <Provider store={store}>
        <BrowserRouter>
            <RouteredScrollToTop>
                <QueryClientProvider client={queryClient}>
                    <StrictMode>
                        <App />
                    </StrictMode>
                    <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
                </QueryClientProvider>
            </RouteredScrollToTop>
        </BrowserRouter>
    </Provider>
)

ReactDOM.render(RouterApp, document.getElementById("grassroots-app"))
