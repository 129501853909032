import swal from "sweetalert"
import BACKENDERROR from "frontend/imports/backenderror"
import { swalConfigs } from "QuorumGrassroots/swalConfigs"
import { resourceGetList, sendAction } from "shared/djangio/action-creators"
import { destroyAllWidgetSlices } from "QuorumGrassroots/widgets/action-creators"
import { fromJS } from "immutable"
import {
    actionTypes,
    logoutActionTypes,
    loadFrameworkActionTypes,
    recoverAccountActionTypes,
    resetPasswordActionTypes,
    postGrassrootsVisitActionTypes,
    loadPageRegionFilterTypes,
} from "QuorumGrassroots/framework/action-types"

/**
 * Logs out the grassroots user. Takes no arguments. Calls action
 * grassroots_logout, found in `NewSupporterResource.` Destroys any
 * information stored within the widget slices.
 *
 * @name logout
 * @function
 **/
export const logout = () => (dispatch) => {
    Promise.all([
        dispatch(destroyAllWidgetSlices()),
        dispatch(
            sendAction(DjangIO.app.grassroots.models.NewSupporter, logoutActionTypes, {
                action: "grassroots_logout",
                method: "get",
            }),
        ),
    ]).then(() => {
        window.location.reload()
    })
}

/**
 * Loads data that has not already been provided by context.
 * If they are not already in the store (if they're in the store, it's because
 * they were provided to the window via context), then go and load them!
 *
 * We're going to simplify this into an action and just let the backend deal
 * with it, I think.
 */
export const preloadData = () => (dispatch, getState) => {
    const loadField = (fieldEnum) => {
        const resource = DjangIO.modelAtPath(fieldEnum.model)

        const filters = {
            limit: 0,
            max_limit: 0,
            only_fields: fieldEnum.only_fields && !fieldEnum.resource && fieldEnum.only_fields,
            order_by: fieldEnum.order_by && fieldEnum.order_by.length && fieldEnum.order_by[0],
            ...fieldEnum.filters,
        }

        Object.keys(filters).forEach((key) => filters[key] == null && delete filters[key])

        if (!fieldEnum.detail) {
            dispatch(
                resourceGetList(resource, loadFrameworkActionTypes, filters, {
                    fieldKey: fieldEnum.store_key,
                    isDetail: fieldEnum.detail,
                }),
            )
        }
    }

    const framework = getState().framework

    DjangIO.app.grassroots.types.GrassrootsContextField.region_supported_items().forEach(
        (field) => !framework.get(field.store_key) && loadField(field),
    )
}

export const recoverAccount = (loginValues) => (dispatch) => {
    return dispatch(
        sendAction(DjangIO.app.grassroots.models.NewSupporter, recoverAccountActionTypes, {
            action: "grassroots_recover_account",
            method: "post",
            kwargs: loginValues,
        }),
    )
        .then(() => {
            swal(swalConfigs.recoverAccountSuccess)
        })
        .catch((error) => BACKENDERROR(error, undefined, undefined, false))
}

export const resetPassword = (password, _props) => async (dispatch) => {
    return await dispatch(
        sendAction(DjangIO.app.grassroots.models.NewSupporter, resetPasswordActionTypes, {
            action: "grassroots_reset_password",
            method: "patch",
            kwargs: { password: password.get("password") },
        }),
    )
        .then(async () => {
            await swal(swalConfigs.resetPasswordSuccess)

            // Refresh after password reset
            // Since we don't log out after resets we could segue
            window.location.assign(window.location.origin + "/")
        })
        .catch((error) => BACKENDERROR(error, undefined, undefined, false))
}

export const trackGrassrootsVisit = (path) => (dispatch) => {
    // path processing may change later, but for now, let's just make sure it fires whenever
    // we hit a campaign.
    const campaignSlug = path
        .replace(DjangIO.app.grassroots.types.GrassrootsWidgetType.campaign.widget_url, "")
        .split("/")[0]

    const payload = {
        campaign_slug: campaignSlug,
    }

    return dispatch(
        sendAction(DjangIO.app.grassroots.models.GrassrootsPageVisit, postGrassrootsVisitActionTypes, {
            action: "track_visit",
            method: "post",
            kwargs: payload,
        }),
    )
}

export const updateSupporterPoints = (points) => ({
    type: actionTypes.UPDATE_SUPPORTER_POINTS,
    points,
})

export const updatePointsEarned = (points) => ({
    type: actionTypes.UPDATE_POINTS_EARNED,
    points,
})

export const showGamificationTierModal = (points) => {
    // https://github.com/mathusummut/confetti.js
    if (window.confetti) {
        window.confetti.start()
    }

    return {
        type: actionTypes.SHOW_GAMIFICATION_TIER_MODAL,
        points,
    }
}

export const hideGamificationTierModal = (points) => {
    if (window.confetti) {
        window.confetti.remove()
    }

    return {
        type: actionTypes.HIDE_GAMIFICATION_TIER_MODAL,
        points,
    }
}

/**
 * After submission of registration form for a campaign with one-click registration enabled,
 * set the action center to be enabled for one-click registration for a specific campaign
 * @param {Number} campaignId Id of One Click enabled campaign
 * @return {object} returns redux action.
 */
export const enableOneClickRegistration = (campaignId) => ({
    type: actionTypes.ENABLE_ONE_CLICK_REGISTRATION,
    campaignId,
})

/**
 * After a campaign has completed one click registration,
 * set the action center to be disabled for one-click registration
 *
 * @return {object} returns redux action.
 */
export const disableOneClickRegistration = () => ({
    type: actionTypes.DISABLE_ONE_CLICK_REGISTRATION,
})

/**
 * When the page requires a page region, get the region filter for the widgets on the page
 * @param  {object} region         the region type obtained from page params
 * @return {Promise}                The promise that is fetching data
 */
export const getPageRegionFilters = (region, regionKey) => (dispatch) =>
    dispatch(
        sendAction(DjangIO.app.geography.models.GeoShape, loadPageRegionFilterTypes, {
            action: "get_region_filter_for_grs_page",
            method: "get",
            kwargs: { region },
            payload: { regionKey },
        }),
    ).catch((error) => BACKENDERROR(error, swalConfigs.loadContentError, false, false))
