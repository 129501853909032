import { WriteWhiteHouseForm } from "QuorumGrassroots/campaign-forms/components/WriteWhiteHouseForm"

import { getMessageFormWrapper } from "QuorumGrassroots/campaign-forms/wrappers/GetMessagesFormWrapper"
import { generateGrassrootsActionsWithImmutableValues } from "QuorumGrassroots/campaign-forms/helpers"

export const generateWriteWhiteHouseActions = (messageValues, globalFormValues, props) => {
    return messageValues.reduce((acc, value) => {
        acc.push({
            text: [value.prebody, value.body, value.postbody].filter((text) => text).join("\n\n"),
            subject: value.subject,
            campaign: DjangIO.app.grassroots.campaign.models.Campaign.resourceUriFromId(props.campaign.id),
            supporter_action_type: props.campaign.campaign_type,
        })
        return acc
    }, [])
}

export default getMessageFormWrapper({
    formName: "write_white_house",
    generateGrassrootsActions: (messageValues, globalFormValues, props) =>
        generateGrassrootsActionsWithImmutableValues(
            generateWriteWhiteHouseActions,
            messageValues,
            globalFormValues,
            props,
        ),
})(WriteWhiteHouseForm)
