import { useMemo } from "react"
import moment from "moment"

// constants
import dashboardConstants from "app/static/frontend/dashboards/constants"

// helper functions
import { stanceWithCustomLabelByValue } from "shared/stance/helperFunctions"

export const getVisualizationOverrideHeight = ({ data, height }) => {
    if (height / dashboardConstants.visualizationOverrideConstant <= data.length) {
        return data.length * (dashboardConstants.visualizationOverrideConstant * 2)
    }

    return undefined
}

export const getVisualizationOverrideWidth = ({ data, width }) => {
    if (width / dashboardConstants.visualizationOverrideConstant <= data.length) {
        return data.length * (dashboardConstants.visualizationOverrideConstant * 2)
    }

    return undefined
}

export const getVisualizationXAxisLabelLimit = ({ height, width = null }) => {
    const smallerDimension = width ? Math.min(height, width) : height

    if (smallerDimension < 150) {
        return 2
    }

    return smallerDimension / dashboardConstants.visualizationXAxisLabelLimit
}

// gets the enum string from the frequencyTupleField and converts it to its DjangIO Enum
// ("app.person.types.Party" to the DjangIO.app.person.types.Party Enum object)
export const getVisualizationFrequencyTupleFieldEnum = ({ dataSources, quorumDataType }) => {
    const frequencyTupleField =
        // we need a QuorumDataType to exist to ensure
        // that we can access the specific enum value in the frequencyTupleField
        quorumDataType &&
        dataSources.length &&
        // app/widgets/types.py DashboardCountVisualizationWidget
        // will not have a group_by entry
        !dataSources[0].count &&
        // we only want to show enum labels when we are doing a Bar group_by
        dataSources[0].group_by.frequency_tuple_field &&
        !dataSources[0].group_by.date_field &&
        // make sure that the current frequency_tuple_field is contained within the
        // FrequencyTupleFields enum
        DjangIO.app.widgets.types.FrequencyTupleFields.by_value(dataSources[0].group_by.frequency_tuple_field)

    if (frequencyTupleField && frequencyTupleField.enum) {
        // es6 eval
        // converts
        // "app.person.types.Party"
        // to
        // ["app", "person", "types", "Party"]
        // and calls
        // DjangIO.app.person.types.Party
        // https://stackoverflow.com/a/33066839/6201291
        return frequencyTupleField.enum[quorumDataType.value]
            .split(".")
            .reduce((accumulator, path) => accumulator[path], DjangIO)
    }

    return undefined
}

// checks to see if the Visualization uses the date_field group_by
export const getVisualizationDateField = ({ dataSources }) =>
    dataSources.length &&
    // app/widgets/types.py DashboardCountVisualizationWidget
    // will not have a group_by entry
    !dataSources[0].count &&
    // we only want to convert the x-axis date labels when we are doing
    // a Bar group_by that contains a date_field
    dataSources[0].group_by.frequency_tuple_field &&
    dataSources[0].group_by.date_field &&
    DjangIO.app.widgets.types.FrequencyTupleFields.date_fields.includes(
        dataSources[0].group_by.frequency_tuple_field,
    ) &&
    // make sure that the date_field is contained within the DateFields enum
    DjangIO.app.widgets.types.DateFields.by_value(dataSources[0].group_by.date_field)

export const getVisualizationXAxisLabel = ({ dateField, field, fieldEnum, stancesWithCustomLabels }) => {
    // converts the ISO 8601 syntax (YYYY-MM-DD) returned from the
    // app/widgets/engine.py DataSourceEngine group() logic
    // into more human-readable syntax
    // (2020-11-01 to 11/1/2020)
    if (dateField) {
        return moment(field).format("M/D/YYYY")
    } else if (fieldEnum === DjangIO.app.userdata.types.LegislativeStanceType && stancesWithCustomLabels) {
        return stanceWithCustomLabelByValue(stancesWithCustomLabels, parseInt(field)).label
    } else if (fieldEnum && fieldEnum.by_value(field)) {
        return fieldEnum.by_value(field).label
    }

    return field
}

// used in useVisualizationMargin (through getVisualizationXAxisLabelsScaleFactor) to determine
// the scale factor of the x-axis bottom margin
export const getVisualizationXAxisLabelsLongestLength = ({
    data,
    dataSources,
    height,
    width,
    isHorizontal,
    quorumDataType,
}) => {
    const xAxisLabelLimit = getVisualizationXAxisLabelLimit({ height, width })

    const fieldEnum = getVisualizationFrequencyTupleFieldEnum({
        dataSources,
        quorumDataType,
    })

    const dateField = getVisualizationDateField({
        dataSources,
    })

    const dataArray = Array.isArray(data)
        ? // [{1: 5, 2: 16}, {2: 13, 3: 1}]
          // to
          // [1, 2, 2, 3]
          // flatMap only works with Node 11+,
          // which our jest/enzyme does not use
          // data.flatMap(item => Object.keys(item))
          data.reduce(
              (acc, dataEntry) =>
                  acc.concat(
                      Object.entries(dataEntry).map((datum) => ({
                          idOrEnumValue: datum[0],
                          groupKeyValue: datum[1].groupKeyValue,
                      })),
                  ),
              [],
          )
        : Object.entries(data).map((datum) => ({
              idOrEnumValue: datum[0],
              groupKeyValue: datum[1].groupKeyValue,
          }))

    const xAxisLabelsLongestLength =
        dataArray &&
        dataArray.length &&
        dataArray.reduce((accumulator, { idOrEnumValue, groupKeyValue }) => {
            const field = groupKeyValue || idOrEnumValue

            const xAxisLabel = getVisualizationXAxisLabel({
                dateField,
                field,
                fieldEnum,
            })

            return xAxisLabel.length > accumulator.length ? xAxisLabel : accumulator
        }, "").length

    return xAxisLabelsLongestLength > xAxisLabelLimit
        ? // + 3 for the extra ... that we add to the truncated label
          xAxisLabelLimit + 3
        : xAxisLabelsLongestLength
}

// used in useVisualizationMargin to determine
// the length of the y-axis left margin
export const getVisualizationYAxisLabelsLongestLength = ({
    data,
    // 100% stacked
    percentage,
}) => {
    const dataArray = Array.isArray(data)
        ? // [{1: 5, 2: 16}, {2: 13, 3: 1}]
          // to
          // [1, 2, 2, 3]
          // flatMap only works with Node 11+,
          // which our jest/enzyme does not use
          // data.flatMap(item => Object.keys(item))
          data.reduce((acc, dataEntry) => acc.concat(Object.entries(dataEntry).map((datum) => datum[1].agg)), [])
        : Object.entries(data).map((datum) => datum[1].agg)

    // y-axis range will always be between 0%-100% for percentage BarStacked widgets
    if (percentage) {
        // "100%".length = 4
        return 4
    }

    return (
        dataArray &&
        dataArray.length &&
        dataArray.reduce(
            (accumulator, agg) =>
                (agg && agg.toLocaleString().length) > accumulator.toLocaleString().length
                    ? agg.toLocaleString()
                    : accumulator,
            "",
        ).length
    )
}

// used in useVisualizationMargin to determine
// the scale factor of the x-axis bottom margin
export const getVisualizationXAxisLabelsScaleFactor = ({
    data,
    dataSources,
    height,
    width,
    isHorizontal,
    quorumDataType,
}) => {
    const xAxisLabelsLongestLength = getVisualizationXAxisLabelsLongestLength({
        data,
        dataSources,
        height,
        width,
        isHorizontal,
        quorumDataType,
    })

    if (!xAxisLabelsLongestLength) {
        return xAxisLabelsLongestLength
    }

    if (isHorizontal) {
        return xAxisLabelsLongestLength * 7.5
    }

    if (xAxisLabelsLongestLength < 5) {
        return xAxisLabelsLongestLength * 2.5
    } else if (xAxisLabelsLongestLength >= 5 && xAxisLabelsLongestLength < 15) {
        return xAxisLabelsLongestLength * 5
    }

    return xAxisLabelsLongestLength * 7.5
}

// margins for Bar svg x/y axes
export const useVisualizationMargin = ({
    data,
    dataSources,
    height,
    width,
    isHorizontal,
    // 100% stacked bar
    percentage,
    quorumDataType,
}) =>
    useMemo(() => {
        const xAxisLabelsLongestLength = getVisualizationXAxisLabelsLongestLength({
            data,
            dataSources,
            height,
            width,
            isHorizontal,
            quorumDataType,
        })
        const yAxisLabelsLongestLength = getVisualizationYAxisLabelsLongestLength({
            data,
            percentage,
        })
        const xAxisLabelsScaleFactor = getVisualizationXAxisLabelsScaleFactor({
            data,
            dataSources,
            height,
            width,
            isHorizontal,
            quorumDataType,
        })

        const xAxis = xAxisLabelsLongestLength ? xAxisLabelsScaleFactor + 20 : 20

        const yAxis = yAxisLabelsLongestLength ? yAxisLabelsLongestLength * 3 + 40 : 40

        return {
            top: 20,
            right: 20,
            bottom: isHorizontal ? yAxis : xAxis,
            left: isHorizontal ? xAxis : yAxis,
        }
    }, [data, height, percentage])
