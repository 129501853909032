import i18n from "i18n"
import moment from "moment"
import { change } from "redux-form"

import {
    actionTypes,
    callOfficeActionTypes,
    getCampaignMessagesActionTypes,
    patchGrassrootsSupporterActionActionTypes,
    postExternalLogTypes,
    postGrassrootsSupporterActionActionTypes,
    simpleGetCampaignMessagesActionTypes,
    submitCampaignMessagesActionTypes,
} from "QuorumGrassroots/campaign-forms/action-types"
import * as formSectionSelectors from "QuorumGrassroots/campaign-forms/selectors/form-section-selectors"
import * as generalSelectors from "QuorumGrassroots/campaign-forms/selectors/general-selectors"
import {
    showGamificationTierModal,
    updatePointsEarned,
    updateSupporterPoints,
} from "QuorumGrassroots/framework/action-creators"
import { actionTypes as frameworkActionTypes } from "QuorumGrassroots/framework/action-types"
import {
    selectActionCenterSettings,
    selectCurrentTier,
    selectGamificationPointsOfCampaign,
    selectOrganizationId,
    selectShowGamificationTiers,
    selectUserId,
} from "QuorumGrassroots/framework/selectors"
import { runUserJavascript, reinsertPlaceholders } from "QuorumGrassroots/helperFunctions"
import { successfullySubmittedWidget } from "QuorumGrassroots/widgets/action-creators"
import { resourcePatchDetail, resourcePatchList, resourcePostList, sendAction } from "shared/djangio/action-creators"
import * as actionDicts from "shared/profiles/supporter/supporter-profile-action-types"

import { swalConfigs } from "QuorumGrassroots/swalConfigs"
import BACKENDERROR from "frontend/imports/backenderror"
import { fromJS } from "immutable"

const { CustomInteractionType } = DjangIO.app.userdata.notes.models

const COMPLETE_CAMPAIGN_DELAY = 100

/**
 * Fetches the targeted messages of the existing campaign.
 * Simple inserts the received messages into the "messages" slice
 * of the store without any formatting modification. Messages will
 * be stored as an array.
 *
 * @param  {int} campaignId         the ID of the campaign
 * @param  {string} uniqueWidgetId  the key referring to the current active widget.
 * @return {Promise}                The promise that is fetching data
 */
export const simpleGetCampaignMessages = (campaignId, uniqueWidgetId) => (dispatch) => {
    return dispatch(
        sendAction(DjangIO.app.grassroots.campaign.models.GrassrootsCampaign, simpleGetCampaignMessagesActionTypes, {
            action: "get_campaign_messages",
            method: "get",
            getParams: { campaign_id: campaignId },
            payload: { uniqueWidgetId },
        }),
    ).catch((error) => BACKENDERROR(error, swalConfigs.loadContentError, false, false))
}

/**
 * Fetches the targeted messages of the existing campaign and
 * converts the messages into an immutable map, keyed on "message_id".
 * The message id is the "key" for the internal message object, which
 * will contain various details including: subject, bodies, prebody, postbody,
 * and any edits that have subsequently been made to the body. This is for
 * use in campaigns that need to keep track of edits / versions! If you don't
 * need to keep track of message versions, it's highly suggested you use
 * simpleGetCampaignMessages instead.
 *
 * @param  {int}      options.campaignId        the ID of the campaign
 * @param  {string}   options.uniqueWidgetId    the key referring to the current active widget.
 * @param  {Function} options.getIdFromMessage  A function that, given a message returned from the
 *                                             backend, creates a unique message ID for each target.
 *
 * @return {Promise}                           The promise that is fetching data
 */
export const getCampaignMessages =
    ({ campaignId, uniqueWidgetId, getIdFromMessage = (message) => message.target.id.toString(), getKwargs = {} }) =>
    (dispatch, getState) => {
        const oneClickEditedMessages = getState().framework.get("oneClickEditedMessages")
        return dispatch(
            sendAction(DjangIO.app.grassroots.campaign.models.GrassrootsCampaign, getCampaignMessagesActionTypes, {
                action: "get_campaign_messages",
                method: "get",
                getParams: { campaign_id: campaignId, ...getKwargs },
                payload: { uniqueWidgetId, getIdFromMessage, oneClickEditedMessages },
            }),
        ).catch((error) => BACKENDERROR(error, swalConfigs.loadContentError, false, false))
    }

/**
 * Loads campaign messages by calling getCampaignMessages and updating redux form initial values
 * if the messages have not been previously loaded
 *
 * @param  {dict}       campaign                        dict of campaign information
 * @param  {dict}       context                         (optional) component context containing props, if called from component
 * @param  {function}   getIdFromMessage                function that returns the ID of message
 * @param  {dict}       getKwargs                       kwargs of message
 * @param  {function}   initialize                      redux form initialize function to update initial values
 * @param  {string}     uniqueWidgetId                  unique ID of the widget
 */
export const loadCampaignMessages =
    ({
        campaign,
        context,
        getCampaignMessagesFromProps,
        getIdFromMessage,
        getKwargs,
        initialize,
        messagesLoaded,
        uniqueWidgetId,
    }) =>
    () => {
        if (!messagesLoaded) {
            getCampaignMessagesFromProps({
                campaignId: campaign.id,
                uniqueWidgetId,
                getKwargs,
                getIdFromMessage,
            }).then(() => context && initialize(context.props.initialValues))
        } else {
            context && initialize(context.props.initialValues)
        }
    }

export const toggleTarget = (uniqueWidgetId, targetId) => ({
    type: actionTypes.TOGGLE_TARGET,
    uniqueWidgetId,
    targetId,
})

export const selectAllTargets = (uniqueWidgetId) => ({
    type: actionTypes.SELECT_ALL_TARGETS,
    uniqueWidgetId,
})

export const deselectAllTargets = (uniqueWidgetId) => ({
    type: actionTypes.DESELECT_ALL_TARGETS,
    uniqueWidgetId,
})

/**
 * Change the "active message" being displayed. For use in anything
 * that makes use of a select in order to determine what to current show.
 *
 * @param  {string} uniqueWidgetId the key referring to the current active widget.
 * @param  {string} selectedId     the soon to be active target.
 * @return {Object}                Redux action.
 */
export const changeTarget = (uniqueWidgetId, selectedId) => ({
    type: actionTypes.CHANGE_TARGET,
    uniqueWidgetId,
    selectedId,
})

export const changeMessageGroup = (uniqueWidgetId, groupId) => ({
    type: actionTypes.CHANGE_MESSAGE_GROUP,
    uniqueWidgetId,
    groupId,
})

/**
 * Given a certain message, toggle between the potential message bodies.
 * Will toggle amongst the array of bodies: a positive increment of 1 will
 * change the viewable body from a[x] to a[x+1]. Similarly, a negative increment
 * will change the viewable body from a[x] to a[x-1].
 *
 * @param  {string} options.uniqueWidgetId  the key referring to the current active widget.
 * @param  {string} options.currentId       the message slice we're currently looking at.
 * @param  {string} options.formName        the name of the redux-form we're currently in.
 * @param  {int}    options.increment       the amount we're incrementing
 *
 * @return {Promise}                The promise that is fetching data
 */
export const viewNextBody =
    ({ uniqueWidgetId, currentId, formName, increment = 1 }) =>
    (dispatch, getState) => {
        const fakeProps = { uniqueWidgetId, activeId: currentId, formName }

        const currentFormSlice = formSectionSelectors.selectMessageFormSlice(getState(), fakeProps)

        // store the current form's message in the store.
        dispatch({
            type: actionTypes.STORE_CURRENT_MESSAGE,
            uniqueWidgetId,
            currentId,
            message: currentFormSlice.get("body"),
        })

        // let's move to the next message!
        dispatch({
            type: actionTypes.VIEW_NEXT_MESSAGE,
            uniqueWidgetId,
            currentId,
            increment,
        })

        // now that we've moved to the next message in the store, we need to populate the form
        // with the correct new message!
        const newMessage = formSectionSelectors.selectActiveMessage(getState(), fakeProps)

        const newFormSlice = formSectionSelectors.selectMessageFormSlice(getState(), fakeProps).set("body", newMessage)

        dispatch(change(formName, currentId, newFormSlice))
    }

/**
 * Given the amount of points the supporter has earned, store current tier as a variable.
 * Dispatch update supporter points and points earned action creators
 * Fetch updated tier and compare to previous 'current' tier
 * If tiers are different, dispatch action to display gamification tier modal
 *
 * @param  {int} points - the amount of points earned by the supporter
 * @param  {function} dispatch - function that dispatches an action to the store
 * @param  {function} getState - function that returns the current state
 *
 * @return {Promise}                The dispatched action to display the Gamification level modal
 */
export const dispatchGamificationLevelUpModal = (points, dispatch, getState) => {
    const currentTier = selectCurrentTier(getState())

    dispatch(updateSupporterPoints(points))
    dispatch(updatePointsEarned(points))

    const updatedTier = selectCurrentTier(getState())

    const shouldShowGamificationTierModal = selectShowGamificationTiers(getState())
    if (currentTier !== updatedTier && shouldShowGamificationTierModal) {
        dispatch(showGamificationTierModal())
    }
}

/**
 * A cleanup action for campaign forms that have the option of submitting
 * one message at a time. Records all message ids that have been sent,
 * so they are no longer seen on the frontend. If there are no message IDs
 * remaining (so no new messages get sent), mark the widget as successfully submitted,
 * reset the sentIDs in case the user clicks back and wants to participate
 * again, and then route to a thank you page.
 *
 * @param  {string} uniqueWidgetId  the key referring to the current active widget.
 * @param  {int} campaignId         the id of the current active campaign.
 * @param  {Array<string>} sentIds  array of message ids of the messages that were just posted to the backend.
 *
 * @return {int}                    the length of the existing array. Used if any action creators
 *                                  wants to dispatch further actions upon no further messages remaining.
 */
export const updateMessagesAfterSend = (uniqueWidgetId, campaignId, sentIds) => (dispatch, getState) => {
    dispatch({
        type: actionTypes.UPDATE_SENT_IDS,
        uniqueWidgetId,
        sentIds,
    })

    const remainingMessageIds = generalSelectors.selectRemainingMessageIds(getState(), { uniqueWidgetId })

    // if the message we're currently showing on the form has just been submitted,
    // move to the next available non-submitted message. If there is no
    // available message, route to a thank you page.
    if (remainingMessageIds.length) {
        dispatch({
            type: actionTypes.CHANGE_TARGET,
            uniqueWidgetId: uniqueWidgetId,
            selectedId: remainingMessageIds[0],
        })
    } else {
        setTimeout(() => {
            // For redirect campaigns, we need timeout so request is created before we potentially redirect to a different site
            dispatch(successfullySubmittedWidget(uniqueWidgetId))
            dispatch(resetWriteCampaignSlice(uniqueWidgetId))
            dispatch(userCompletedCampaign(campaignId))
        }, COMPLETE_CAMPAIGN_DELAY)
    }

    return remainingMessageIds.length
}

/**
 * Rest the campaign slice to new. If there are already messages, then there's no
 * point in erasing them; we just need to clear the edited campaign slice.
 *
 * @param {string} uniqueWidgetId the key referring to the current active widget.
 */
export const resetWriteCampaignSlice = (uniqueWidgetId) => ({
    type: actionTypes.RESET_WRITE_CAMPAIGN_SLICE,
    uniqueWidgetId,
})

/**
 * Submit campaign messages from write campaigns. These campaigns allow for
 * sending messages one at a time or in bulk. Receives an array of
 * grassroots actions objects and appends a few global details to them.
 * Then sends the list of grassroots actions via TastyPie to be created
 * on the backend. We have decided to not wait for the messages to be submitted;
 * instead, we'll just direct the user to the next step as we create messages
 * in the background.
 *
 * @param  {Array<string>}  options.keys    message ids of the messages being posted
 * @param  {Array<Object>}  options.grassrootsActions   messages that are being posted
 * @param  {int}            options.campaignId          id of the current active campaign
 * @param  {string}         options.uniqueWidgetId      id of the current active widget
 * @param  {bool}           options.isCombinedCampaign  whether or not the campaign is a combined campaign
 *
 * @return {promise}    promise from post list request.
 */
export const submitCampaignMessages =
    ({ keys, grassrootsActions, campaign, uniqueWidgetId, isCombinedCampaign }) =>
    (dispatch, getState) => {
        const supporterId = selectUserId(getState())
        const organizationId = selectOrganizationId(getState())
        const campaignId = campaign.id
        const actionCenter = selectActionCenterSettings(getState()).resource_uri
        let totalPointsEarned = 0

        if (!campaignId) {
            console.error("Campaign was not passed to submitCampaignMessages!")
        }

        const modifiedGrassrootsActions = grassrootsActions.map((actionObj) => {
            const info =
                isCombinedCampaign && actionObj?.campaign
                    ? campaign.combined_campaigns_info.find(
                          (x) =>
                              DjangIO.app.grassroots.campaign.models.Campaign.resourceUriFromId(x.id) ===
                              actionObj.campaign,
                      )
                    : campaign
            const pointsEarned = selectGamificationPointsOfCampaign(getState(), { campaign: info })
            totalPointsEarned += pointsEarned
            return {
                ...actionObj,
                supporter: DjangIO.app.grassroots.models.Supporter.resourceUriFromId(supporterId),
                organization: DjangIO.app.userdata.models.Organization.resourceUriFromId(organizationId),
                action_center: actionCenter,
                points_earned: pointsEarned,
            }
        })

        const promise = dispatch(
            resourcePatchList(
                DjangIO.app.grassroots.models.GrassrootsSupporterAction.objects,
                submitCampaignMessagesActionTypes,
                modifiedGrassrootsActions,
            ),
        )

        // If we error, error quietly. We don't need it being shown to the
        // user. Plus, this helps us sidestep our performance issues.
        const remainingMessageCount = dispatch(updateMessagesAfterSend(uniqueWidgetId, campaignId, keys))

        if (!remainingMessageCount && isCombinedCampaign) {
            // if there are no remaining messages and we are in a combined campaign,
            // post an additional grassroots supporter action indicating the supporter
            // participated in a combined campaign.
            dispatch(postGrassrootsSupporterAction({ uniqueWidgetId, campaign, totalPointsEarned }))
        } else {
            dispatchGamificationLevelUpModal(totalPointsEarned, dispatch, getState)
        }

        // make sure the promise errors in Sentry in the case something goes wrong
        promise.catch((error) => {
            // skip swal, but still log in Raven. This will also log duplicate message errors.
            BACKENDERROR(error, swalConfigs.postGrassrootsActionError, true, false)
            throw error
        })

        return remainingMessageCount
    }

/**
 * Create message values for each of the messages in the write form. Then generate grassroots actions.
 * Then submit the campaign messages. Lastly, reset form if there are no more messages to send
 *
 * messageValues will be different depending on the campaign settings.
 * There are four options:
 * 1. Message Groups: similar to single body, but instead of applying everything from "global", apply edits of each message group to its messages.
 * 2. Single Body and able to Edit: take the subject, body values in "global" and apply them to all messages.
 * 3. New UI combined campaigns: Custom email message groups are merged into only one message. They are set to the same edited message here.
 * 4. Submit All: submit all messages as is.
 *
 * @param  {function}       generateGrassrootsActions   Form's function to generate grassroots actions
 * @param  {bool}           isCombinedCampaign          Boolean to specify if combined campaign
 * @param  {Immutable.Map}  values                      All of the campaign messages
 * @param  {function}       dispatch                    dispatch function
 * @param  {dict}           props                       Form component's props dict
 */
export const submitWriteForm = (generateGrassrootsActions, isCombinedCampaign) => (values, dispatch, props) => {
    let messageValues

    if (
        props.messageGroups?.length &&
        props.campaign.campaign_type === DjangIO.app.grassroots.campaign.types.CampaignType.write_member.value
    ) {
        // 1. Message Groups
        props.messageGroups.forEach((group) => {
            const [subject, body, prebody, postbody] = ["subject", "body", "prebody", "postbody"].map((field) =>
                reinsertPlaceholders(values.getIn([group.id.toString(), field]) || ""),
            )
            group.messages.forEach((message) => {
                if (!message.targeted) return //does nothing if the message for the official is not selected
                if (!messageValues) messageValues = fromJS({})
                messageValues = messageValues
                    .set(message.message_id, values.get(message.message_id))
                    .setIn([message.message_id, "subject"], subject)
                    .setIn([message.message_id, "body"], body)
                    .setIn([message.message_id, "prebody"], prebody)
                    .setIn([message.message_id, "postbody"], postbody)
                    .setIn([message.message_id, "message_group_id"], group.id)
            })
        })
    } else if (
        //on the new UI we only have single body messages, with the exception of combined campaigns
        props.campaign.campaign_type !== DjangIO.app.grassroots.campaign.types.CampaignType.combined_campaign.value ||
        (props.campaign.single_body_for_all_messages &&
            // If they are unable to edit, no point in replacing the message with a global subject and body
            props.campaign.draft_requirements !== DjangIO.app.grassroots.campaign.types.DraftOptions.cannot_edit.value)
    ) {
        // 2. Submit single body for all messages
        const globalSubject = reinsertPlaceholders(values.getIn(["global", "subject"]))
        const globalBody = reinsertPlaceholders(values.getIn(["global", "body"]))

        messageValues = values
            .filter((value, key) => props.remainingMessageIds.includes(key))
            .map((value) => value.set("subject", globalSubject).set("body", globalBody))
    } else if (
        props.campaign.campaign_type === DjangIO.app.grassroots.campaign.types.CampaignType.combined_campaign.value
    ) {
        // 3. Combined campaigns on new UI
        messageValues = values.filter((value, key) => props.remainingMessageIds.includes(key))
        props.messageGroups.forEach((group) => {
            const customMessages = {}
            group.messages.forEach((message) => {
                const isCustom = message.target.is_custom
                if (!isCustom) return
                if (!customMessages[message.group.id]) {
                    customMessages[message.group.id] = values.get(message.message_id)
                }
                const currentMessage = customMessages[message.group.id]
                messageValues = messageValues
                    .setIn([message.message_id, "subject"], currentMessage.get("subject"))
                    .setIn([message.message_id, "body"], currentMessage.get("body"))
            })
        })
    } else {
        // 4. Submit all
        messageValues = values.filter((value, key) => props.remainingMessageIds.includes(key))
    }

    const globalFormValues = values.filter((value) => !value.size)

    const grassrootsActions = generateGrassrootsActions(messageValues, globalFormValues, props)
    const keys = messageValues.keySeq().toJS()

    runUserJavascript(props.campaign.custom_thank_you_javascript)

    const remainingMessageCount = dispatch(
        submitCampaignMessages({
            campaign: props.campaign,
            uniqueWidgetId: props.uniqueWidgetId,
            keys,
            grassrootsActions,
            isCombinedCampaign,
        }),
    )

    // if there are no more messages to send, then reset the form to its original
    // glory. All edits are cleared, because they've already been sent.
    if (!remainingMessageCount) {
        props.reset(props.form)
    }
}

/**
 * Mark that the supporter has successfully completed a campaign.
 * Will mark the campaign as completed, and then indicate the widget has submitted,
 * triggering a thank you page.
 *
 * Will NOT generate any grassroots actions.
 *
 * @param  {[type]} campaignId      id of current active campaign.
 * @param  {[type]} uniqueWidgetId  id of current active widget.
 */
export const completeCampaign = (campaignId, uniqueWidgetId) => (dispatch) => {
    dispatch(successfullySubmittedWidget(uniqueWidgetId))
    dispatch(userCompletedCampaign(campaignId))
}

/**
 * Post a grassroots supporter action. If campaign should be completed, then just immediately
 * direct to the submitted page.
 *
 * @param  {string}   options.uniqueWidgetId id of the current active widget
 * @param  {int}      options.campaignId     id of the current active campaign
 * @param  {Object}   options.payload        object indicating what fields should go into the
 *                                           grassroots supporter object.
 * @param  {bool}   options.shouldCompleteCampaign shortcut indicating whether or not the form
 *                                                 should be marked as "submitted" upon successful POST.
 * @return {Promise}                          post request promise
 */
export const postGrassrootsSupporterAction =
    ({
        uniqueWidgetId,
        campaign = {},
        totalPointsEarned = 0,
        payload = {},
        shouldCompleteCampaign = false,
        customAfterRegistrationJavascript,
    }) =>
    async (dispatch, getState) => {
        const supporterId = selectUserId(getState())
        const organizationId = selectOrganizationId(getState())
        const campaignId = campaign.id
        const actionCenter = selectActionCenterSettings(getState()).resource_uri
        const pointsEarned = selectGamificationPointsOfCampaign(getState(), { campaign })
        const hasShared = getState().framework.getIn(["userdata", "has_shared_campaign_on_social_dn"])
        const isShareCampaignType =
            // eslint-disable-next-line eqeqeq
            campaign.campaign_type == DjangIO.app.grassroots.enums.SupporterActionType.shared.value
        let result

        if (!hasShared && isShareCampaignType) {
            try {
                await dispatch(
                    sendAction(DjangIO.app.grassroots.models.Supporter, actionDicts.patchSupporterActionDict, {
                        action: "patch_supporter_has_shared_campaign_on_social_dn",
                        method: "patch",
                        kwargs: { supporterId, has_shared_campaign_on_social_dn: true },
                        payload: { has_shared_campaign_on_social_dn: true },
                    }),
                )
                dispatch({
                    type: frameworkActionTypes.UPDATE_USERDATA_SLICE,
                    updatedSlice: { has_shared_campaign_on_social_dn: true },
                })
            } catch (error) {
                BACKENDERROR(error)
                throw error
            }
        }

        if (!campaignId) {
            console.error("Campaign was not passed to postGrassrootsSupporterAction!")
        }

        try {
            result = await dispatch(
                resourcePostList(
                    DjangIO.app.grassroots.models.GrassrootsSupporterAction.objects,
                    postGrassrootsSupporterActionActionTypes,
                    {
                        ...payload,
                        campaign: DjangIO.app.grassroots.campaign.models.Campaign.resourceUriFromId(campaignId),
                        supporter: DjangIO.app.grassroots.models.Supporter.resourceUriFromId(supporterId),
                        organization: DjangIO.app.userdata.models.Organization.resourceUriFromId(organizationId),
                        supporter_action_type: campaign.campaign_type,
                        action_center: actionCenter,
                        points_earned: pointsEarned,
                    },
                    { uniqueWidgetId },
                ),
            )
        } catch (error) {
            BACKENDERROR(error, swalConfigs.postGrassrootsActionError, true, false)
            throw error
        }

        // It assures that the reward / gamification points are displayed in the proper order
        await dispatchGamificationLevelUpModal(totalPointsEarned + pointsEarned, dispatch, getState)

        if (shouldCompleteCampaign) {
            runUserJavascript(campaign.custom_thank_you_javascript)

            if (customAfterRegistrationJavascript) {
                runUserJavascript(customAfterRegistrationJavascript)
            }
            // For redirect campaigns, we need timeout so request
            // is created before we potentially redirect to a different site
            setTimeout(() => {
                if (
                    campaign.campaign_type !==
                    DjangIO.app.grassroots.campaign.types.CampaignType.authorized_contribution.value
                    // Donation forms also update the supporter model, so we call this action in the donation form
                    // after the update to avoid race conditions
                ) {
                    dispatch(completeCampaign(campaignId, uniqueWidgetId))
                }
            }, COMPLETE_CAMPAIGN_DELAY)
        }

        return result
    }

/**
 * Given an existing grassroots action id, patch the grassroots action.
 * @param  {int} grassrootsActionId id of current active grassroots action
 * @param  {Object} kwargs          object of fields and their values to patch
 * @return {promise}                PATCH promise
 */
export const patchGrassrootsSupporterAction = (grassrootsActionId, kwargs) => (dispatch) => {
    return dispatch(
        resourcePatchDetail(
            DjangIO.app.grassroots.models.GrassrootsSupporterAction.objects,
            patchGrassrootsSupporterActionActionTypes,
            grassrootsActionId,
            kwargs,
        ),
    ).catch((error) => BACKENDERROR(error, undefined, false, false))
}

/**
 * Mark the user as having participated in the campaign. Will update
 * the user's userdata store.
 *
 * @param  {int} campaignId     id of the recently submitted campaign.
 */
export const userCompletedCampaign = (campaignId) => async (dispatch, getState) => {
    const participatedCampaigns = getState().framework.getIn(["userdata", "participated_campaigns"])
    const hasEverParticipated = getState().framework.getIn(["userdata", "has_participated_in_any_campaign_dn"])
    const supporterId = selectUserId(getState())

    dispatch({
        type: frameworkActionTypes.UPDATE_USERDATA_SLICE,
        updatedSlice: { participated_campaigns: participatedCampaigns.push(campaignId) },
    })
    // if this is the first campaign this person is participating in
    if (participatedCampaigns.size === 0 || !hasEverParticipated) {
        try {
            await dispatch(
                sendAction(DjangIO.app.grassroots.models.Supporter, actionDicts.patchSupporterActionDict, {
                    action: "patch_supporter_has_participated_in_any_campaign_dn",
                    method: "patch",
                    kwargs: { supporterId, has_participated_in_any_campaign_dn: true },
                    payload: { has_participated_in_any_campaign_dn: true },
                }),
            )
            dispatch({
                type: frameworkActionTypes.UPDATE_USERDATA_SLICE,
                updatedSlice: { has_ever_participated: true },
            })
        } catch (error) {
            BACKENDERROR(error)
            throw error
        }
    }
}

/**
 * Dispatches action to NewCampaignResource to call member. Promise will
 * return the ID of a call Grassroots Action that was created as a result.
 *
 * @param  {string} uniqueWidgetId id of the current active widget
 * @param  {int} campaignId        id of the current active campaign
 * @param  {string} messageKey     id of the current active recipient
 * @param  {string} targetPhone    phone number of the current active recipient
 * @return {Promise}               call action promise
 */
export const callOffice = (uniqueWidgetId, campaignId, messageKey, targetPhone) => (dispatch) => {
    return dispatch(
        sendAction(DjangIO.app.grassroots.campaign.models.GrassrootsCampaign, callOfficeActionTypes, {
            action: "call_office",
            method: "post",
            kwargs: { campaign_id: campaignId, person_id: parseInt(messageKey), phone: targetPhone },
            payload: { uniqueWidgetId, messageKey },
        }),
    ).catch((error) => {
        const swalConfigs = {
            icon: "error",
            title: "Error",
            text: error.response && error.response.data,
        }

        BACKENDERROR(error, swalConfigs, false, false)
    })
}

/**
 * Complete a call. This means the user clicks "submit" after
 * seeing the "How was the call?" text box.
 *
 * @param  {string} uniqueWidgetId     the id of the current active widget
 * @param  {int} campaignId            the id of the current active campaign
 * @param  {string} activeSelectId     the id of the recipient of the call
 * @param  {int} grassrootsActionId    the id of the grassroots supporter action to patch
 * @param  {Object} kwargs             the key and values to patch
 * @return {Promise}                   PATCH promise
 */
export const completeCall =
    (uniqueWidgetId, campaignId, activeSelectId, grassrootsActionId, kwargs) => (dispatch, getState) => {
        return dispatch(patchGrassrootsSupporterAction(grassrootsActionId, kwargs)).then(() => {
            dispatchGamificationLevelUpModal(kwargs.points_earned, dispatch, getState)
            return dispatch(updateMessagesAfterSend(uniqueWidgetId, campaignId, [activeSelectId]))
        })
    }

/**
 * This will be passed to the Redux form's 'validate' function,
 * which is called each time a field input is changed.
 * If the form is rendered, but no value has been selected,
 * return an error object with the field name as its key, and the error message as the value
 * @param {Object} immutableValues - An immutable map
 * @param {Object} props - props that dictate if form field should be rendered
 * @returns {Object}
 */
export const validateExternalLogForm = (immutableValues, props) => {
    const values = immutableValues.toJS()
    const errors = {}

    if (
        (!values.supporters || values.supporters.length < 1) &&
        props.shouldRequireOfficial &&
        values.officials &&
        values.officials.length < 1
    ) {
        errors.officials = i18n.t("error.external_form.official")
    }

    const { CampaignType } = DjangIO.app.grassroots.campaign.types

    switch (props.campaignType) {
        case CampaignType.log_interaction.value:
            if (!values.date) {
                errors.date = i18n.t("error.external_form.date")
            }

            if (!values.time) {
                errors.time = i18n.t("error.external_form.time")
            }

            break
        default:
            break
    }

    return errors
}

/**
 * User submits 'Log Interaction' form and creates a Note object
 *
 * @param  {int}   campaignType Enum value designating which type of external log form it is (interaction or relationship)
 * @param  {Object}   immutableValues
 * @param  {function}  dispatch
 * @param  {Object}   props
 * @return {Promise}  post request promise
 */
export const submitExternalLogForm = (immutableValues, dispatch, props) => {
    const supporter = DjangIO.app.grassroots.models.Supporter.resourceUriFromId(props.supporterId)
    const { officials, supporters, interaction_type, relationship_type, duration, text, projects, ...tag_dict } =
        immutableValues.toJS()

    let { date, time } = immutableValues.toJS()

    const { CampaignType } = DjangIO.app.grassroots.campaign.types

    let resource

    switch (props.campaignType) {
        case CampaignType.log_interaction.value:
            resource = DjangIO.app.userdata.notes.models.Interaction

            // Parse date/time fields. If '.format' is defined, it is a Moment object. Otherwise, create Moment object
            date = date.format ? date : moment(date)
            // Cannot create Moment object without a date, create Moment object with correct time but random date
            time = time.format ? time : moment(`January 31, 1992 ${time}`)

            // Correctly format 'date' field and delete 'time' field from the dict
            date = `${date.format("M/D/YYYY")}/${time.format("H:m")}`

            break
        case CampaignType.log_relationship.value:
            resource = DjangIO.app.userdata.notes.models.Relationship
            break
        default:
            break
    }

    const customInteractionType = interaction_type ? CustomInteractionType.resourceUriFromId(interaction_type) : null

    return dispatch(
        resourcePostList(
            resource,
            postExternalLogTypes,
            {
                // From form
                officials,
                supporters: [supporter, ...supporters], // Make sure supporter creating the note is within the supporters field
                custom_interaction_type: customInteractionType,
                relationship_type,
                date, // Only pass in parsed date value, not time
                duration,
                text,
                projects,
                tag_dict,

                // Configuration
                from_grassroots: true,
                campaign: DjangIO.app.grassroots.campaign.models.Campaign.resourceUriFromId(props.campaignId),
                organization: DjangIO.app.userdata.models.Organization.resourceUriFromId(props.organizationId),
                user: props.userUri,
                supporter,
            },
            { uniqueWidgetId: props.uniqueWidgetId },
        ),
    )
        .catch((error) => {
            BACKENDERROR(error, swalConfigs.postGrassrootsActionError, false, false)
            throw error
        })
        .then(() => {
            dispatch(completeCampaign(props.campaignId, props.uniqueWidgetId))
        })
}

/**
 * This will be passed to the Redux form's 'onSubmitFail' function,
 * which is called if the form is invalid.
 * Swals an alert listing the invalid fields
 * @param {Object} result - Value from 'onSubmit'
 * @param {Function} dispatch - function to dispatch actions
 * @param {Object?} error - error object(?)
 * @param {Object} props - component props
 * @returns {undefined}
 */
export const onSubmitExternalLogFormFail = (result, dispatch, error, props) => {
    if (props.invalid) {
        const errorText = Object.values(props.syncErrors).join("\n")

        swal({
            icon: "error",
            title: i18n.t("error.external_form.title"),
            text: errorText,
        })
    }
}
