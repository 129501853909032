import { createSelector, createStructuredSelector } from "reselect"
import { Map, List } from "immutable"
import i18n from "i18n"
import queryString from "query-string"

import { createToJsSelector } from "shared/imports/sharedHelperFunctions"
import {
    getGamificationAchievementIcon,
    getIndexRoute,
    hasAnyPermissions,
    trimPath,
} from "QuorumGrassroots/helperFunctions"
import { isFeatureEnabled } from "shared/featureflags/helperFunctions"
import { selectAugmentedWidgetContent } from "QuorumGrassroots/widgets/selectors"
import { getPointsForGamificationType, getParsedCampaignPoints } from "QuorumGrassroots/campaign-forms/helpers"

const { GrassrootsNavigationBarButtonType } = DjangIO.app.grassroots.types

export const selectFramework = (state) => state.framework

export const selectImmutableSlice = (slice, fallback = Map()) =>
    createSelector(selectFramework, (framework) => (framework && framework.get(slice)) || fallback)

export const selectImmutablePages = selectImmutableSlice("pages")
export const selectImmutableActionCenterSettings = selectImmutableSlice("actionCenterSettings")
export const selectImmutableCampaignContent = selectImmutableSlice("campaign")
export const selectImmutableOrganization = selectImmutableSlice("organization")
export const selectImmutableOrganizationDesign = selectImmutableSlice("organizationDesign")
export const selectImmutableUserdata = selectImmutableSlice("userdata")
export const selectImmutablePacUserdata = selectImmutableSlice("pacUserdata")
export const selectImmutableLoading = selectImmutableSlice("loading")
export const selectImmutablePermissions = selectImmutableSlice("permissions")
export const selectImmutableGamificationSlice = selectImmutableSlice("gamification")
export const selectPageRegions = selectImmutableSlice("pageRegion")

export const selectCustomFields = selectImmutableSlice("customFields", List())
export const selectRegistrationPages = selectImmutableSlice("registrationPages", List())
export const selectDonationForms = selectImmutableSlice("donationForms", List())
export const selectCampaignList = selectImmutableSlice("campaignList", List())
export const selectImmutableCampaignListMeta = selectImmutableSlice("campaignListMeta")
export const selectIssueList = selectImmutableSlice("issueList", List())
export const selectImmutableEventList = selectImmutableSlice("eventList", List())
export const selectSsoUrl = selectImmutableSlice("ssoUrl", null)
export const selectCookielessSafari = selectImmutableSlice("cookielessSafari", false)
export const selectOneClickRegistrationCampaignId = selectImmutableSlice("oneClickRegistrationCampaignId", null)

export const selectEventList = createToJsSelector(selectImmutableEventList, [])

export const selectPages = createSelector(selectImmutablePages, (pages) => (pages ? pages.toJS() : []))

export const selectActionCenterSettings = createSelector(selectImmutableActionCenterSettings, (acs) =>
    acs ? acs.toJS() : {},
)

export const selectOrganization = createSelector(selectImmutableOrganization, (org) => (org ? org.toJS() : {}))

export const selectOrganizationId = createSelector(selectOrganization, (org) => org.id)

export const selectOrganizationIsI360ClientWithStakeholder = createSelector(
    selectOrganization,
    (org) => org.is_i360_client_with_stakeholder,
)

export const selectOrganizationName = createSelector(selectOrganization, (org) => org.name)

export const selectOrganizationDesign = createSelector(selectImmutableOrganizationDesign, (orgDesign) =>
    orgDesign.toJS(),
)

export const selectPermissions = createSelector(selectImmutablePermissions, (permissions) => permissions.toJS())

export const selectLoading = (key) => createSelector(selectImmutableLoading, (loading) => loading.get(key))

export const selectGamificationSlice = createSelector(selectImmutableGamificationSlice, (gamification) =>
    gamification.toJS(),
)

export const selectCustomActionCenterJS = createSelector(selectImmutableActionCenterSettings, (actionCenter) =>
    actionCenter.get("custom_javascript"),
)

export const selectCampaignListMeta = (key) =>
    createSelector(selectImmutableCampaignListMeta, (campaignList) => campaignList.get(key))

export const selectCampaignListAmount = createSelector(selectCampaignList, (campaignList) => campaignList.size)

export const selectCampaignListLoading = selectLoading("campaignList")
export const selectIssueListLoading = selectLoading("issueList")
export const selectEventListLoading = selectLoading("eventList")

/* ***********************************************************************
 * USER SELECTORS
 *********************************************************************** */
export const selectUserdata = createSelector(selectImmutableUserdata, (userdata) => userdata.toJS())

export const selectFrameworkUserdata = createSelector(selectUserdata, (userdata) => ({
    firstname: userdata.firstname,
    lastname: userdata.lastname,
}))

export const selectUserId = createSelector(selectUserdata, (userdata) => userdata.id)

export const selectUserUri = createSelector(selectUserdata, (userdata) => userdata.user)

export const selectUserLoggedIn = createSelector(selectUserdata, (userdata) => Object.keys(userdata).length > 0)

export const selectEmailRelatedPerson = createSelector(selectUserdata, (userdata) => userdata.email_related_person)

/* ***********************************************************************
 * WINDOW USERDATA (PAC)
 * This slice is needed to access the PAC user data provided.
 * Only necessary in case of context change without a page reload.
 *********************************************************************** */
const selectPacUserdata = createSelector(selectImmutablePacUserdata, (userdata) => userdata.toJS())
export const selectIsPacEligible = createSelector(
    selectPacUserdata,
    (userdata) => userdata?.is_pac_eligible ?? window.Userdata.is_pac_eligible,
)
export const selectConditionalGivingLevel = createSelector(
    selectPacUserdata,
    (userdata) => userdata?.conditional_giving_level ?? window.Userdata.conditional_giving_level,
)
export const selectCannotDonateMessage = createSelector(
    selectPacUserdata,
    (userdata) => userdata?.cannot_donate_message ?? window.Userdata.cannot_donate_message,
)

/* ***********************************************************************
 * ACTION CENTER SPECIFICS
 *********************************************************************** */
export const createActionCenterSelector = (field) =>
    createSelector(selectActionCenterSettings, (actionCenter) => actionCenter[field])

export const selectActionCenterId = createActionCenterSelector("id")
export const selectActionCenterPubliclyAvailable = createActionCenterSelector("publicly_accessible")
export const selectCanLoginWithFacebook = createActionCenterSelector("can_login_with_facebook")
export const selectShowUpdateInfo = createActionCenterSelector("show_update_info")
export const selectActionCenterFooterHtml = createActionCenterSelector("footer_html")
export const selectActionCenterShouldUseFooterHtml = createActionCenterSelector("should_use_footer_html")
export const selectDefaultRegistrationPageUri = createActionCenterSelector("default_registration_page")
export const selectActionCenterName = createActionCenterSelector("name")
export const selectOrganizationUserSpecifiedName = createActionCenterSelector("organization_user_specified_name")
export const selectTooltipText = createActionCenterSelector("tooltip_text")
export const selectReadOnlyFormFields = createActionCenterSelector("read_only_form_fields")

export const selectDefaultRegistrationPageId = createSelector(selectDefaultRegistrationPageUri, (uri) =>
    DjangIO.app.grassroots.models.GrassrootsRegistrationPage.idFromResourceUri(uri),
)

/* ***********************************************************************
 * PRIVATE ACTION CENTER SPECIFICS
 *********************************************************************** */
export const selectLoginRedirectUrl = createActionCenterSelector("login_redirect_url")
export const selectLoginPagePreText = createActionCenterSelector("login_page_pre_text")
export const selectLoginPagePostText = createActionCenterSelector("login_page_post_text")
export const selectLoginPageUsernameLabel = createActionCenterSelector("login_page_username_label")
export const selectLoginPagePasswordLabel = createActionCenterSelector("login_page_password_label")

/* ***********************************************************************
 * GAMIFICATION SPECIFICS
 *********************************************************************** */
export const selectGamificationAchievementIconType = createActionCenterSelector("gamification_achievement_icon_type")
export const selectGamificationAllCampaignPoints = createActionCenterSelector("gamification_all_campaign_points")
export const selectGamificationCampaignTypePoints = createActionCenterSelector("gamification_campaign_type_points")
export const selectGamificationEnabled = createActionCenterSelector("gamification_enabled")
export const selectGamificationPointType = createActionCenterSelector("gamification_point_type")
export const selectGamificationRanks = createActionCenterSelector("gamification_ranks")
export const selectGamificationTiers = createActionCenterSelector("gamification_tiers")
export const selectShouldUseCustomStatsIcon = createActionCenterSelector("should_use_custom_stats_icon")
export const selectShowGamificationRanks = createActionCenterSelector("show_gamification_ranks")
export const selectShowGamificationTiers = createActionCenterSelector("show_gamification_tiers")

const createGamificationSelector = (field) =>
    createSelector(selectGamificationSlice, (gamification) => gamification[field])

export const selectSupporterPoints = createGamificationSelector("supporterPoints")
export const selectPreviousPointsEarned = createGamificationSelector("pointsEarned")
export const selectTierModalShown = createGamificationSelector("tierModalShown")

export const getCurrentTierArray = createSelector(selectSupporterPoints, selectGamificationTiers, (points, tiers) => {
    // Sort tiers by highest values
    const sortedTiers = Object.entries(tiers).sort(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([nameA, tierPropsA], [nameB, tierPropsB]) => tierPropsB.points - tierPropsA.points,
    )

    // Find the first tier where the amounts exceed the amount of points specified by the tier
    // If found, return a list of 2 items, key/name and tier props
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return sortedTiers.find(([_, tierProps]) => points >= tierProps.points)
})

// If currentTierArray is defined, it will have 2 items as a result of Object.entries
// the first being the key/name of the current tier
export const selectCurrentTier = createSelector(getCurrentTierArray, (currentTierArray) =>
    currentTierArray ? currentTierArray[0] : false,
)

// If currentTierArray is defined, it will have 2 items as a result of Object.entries
// the first being the props of the current tier
export const selectCurrentTierProps = createSelector(getCurrentTierArray, (currentTierArray) =>
    currentTierArray ? currentTierArray[1] : {},
)

export const selectCurrentTierCustomText = createSelector(selectCurrentTierProps, (tierProps) => tierProps.text)

export const selectCurrentTierImagePath = createSelector(selectCurrentTierProps, (tierProps) => tierProps.imagePath)

export const selectCurrentTierIcon = createSelector(
    selectShouldUseCustomStatsIcon,
    selectCurrentTierImagePath,
    (shouldUseCustomStatsIcon, currentTierImagePath) => shouldUseCustomStatsIcon && currentTierImagePath,
)

export const selectParsedCampaignPoints = createSelector(
    selectGamificationEnabled,
    selectGamificationPointType,
    selectGamificationAllCampaignPoints,
    selectGamificationCampaignTypePoints,
    (gamificationEnabled, gamificationPointType, gamificationAllCampaignPoints, gamificationCampaignTypePoints) => {
        return getParsedCampaignPoints(
            gamificationEnabled,
            gamificationPointType,
            gamificationAllCampaignPoints,
            gamificationCampaignTypePoints,
        )
    },
)

export const selectGamificationPointsOfCampaign = createSelector(
    selectParsedCampaignPoints,
    (_, props) => props.campaign && props.campaign.gamification_points_mode,
    (_, props) => props.campaign && props.campaign.custom_gamification_points,
    (_, props) => props.campaign && props.campaign.campaign_type,
    (campaignPoints, pointsMode, customPoints, campaignType) => {
        return getPointsForGamificationType(campaignPoints, pointsMode, customPoints, campaignType)
    },
)

export const selectGamificationAchievementIcon = createSelector(
    selectGamificationAchievementIconType,
    (iconTypeValue) => {
        return getGamificationAchievementIcon(iconTypeValue)
    },
)

export const gamificationTierModalProps = createStructuredSelector({
    currentTier: selectCurrentTier,
    supporterPoints: selectSupporterPoints,
    tierModalShown: selectTierModalShown,
    imagePath: selectCurrentTierImagePath,
    tierCustomText: selectCurrentTierCustomText,
    achievementIcon: selectGamificationAchievementIcon,
})

/* ***********************************************************************
 * ROUTING
 *********************************************************************** */
export const selectIndexPageRoute = selectImmutableSlice("indexRoute")

export const selectHasGrassroots = createSelector(selectPermissions, (permissions) =>
    Boolean(permissions[DjangIO.app.models.PermissionType.action_center.label]),
)

export const selectHasExternalInteractions = createSelector(selectPermissions, (permissions) =>
    Boolean(permissions[DjangIO.app.models.PermissionType.external_interactions.label]),
)

export const selectHasExternalRelationship = createSelector(selectPermissions, (permissions) =>
    Boolean(permissions[DjangIO.app.models.PermissionType.external_relationship.label]),
)

export const selectHasPacCharitableMatch = createSelector(selectPermissions, (permissions) =>
    Boolean(permissions[DjangIO.app.models.PermissionType.pac_match.label]),
)

export const selectPermissionedFoundationalRoutes = createSelector(selectActionCenterSettings, (actionCenter) => {
    const items = DjangIO.app.grassroots.types.GrassrootsFoundationalRoute.items()

    return actionCenter.publicly_accessible ? items.filter((item) => !item.private_acs_only) : items
})

export const selectFoundationalRoutes = createSelector(
    [selectPermissionedFoundationalRoutes, selectUserLoggedIn],
    (permissionedRoutes, loggedIn) => {
        const accessibleToAll = permissionedRoutes.filter((item) => !item.anonymous_only && !item.logged_in_only)

        const conditionalRoutes = loggedIn
            ? permissionedRoutes.filter((item) => item.logged_in_only)
            : permissionedRoutes.filter((item) => item.anonymous_only)

        return [...accessibleToAll, ...conditionalRoutes].map((item) => ({ value: item.value, path: item.path }))
    },
)

export const selectFoundationalRedirectPaths = createSelector(
    [selectPermissionedFoundationalRoutes, selectFoundationalRoutes],
    (permissionedRoutes, usedRoutes) => {
        return permissionedRoutes
            .filter((item) => !usedRoutes.find((route) => route.value === item.value))
            .map((item) => item.path)
    },
)

/**
 * The "base" route is going to be different depending on the permissions
 * an organization has.
 */
export const selectIndexRoute = createSelector(
    [selectIndexPageRoute, selectPermissions, selectDefaultRegistrationPageId],
    (indexPageRoute, permissions, regPageId) => {
        return getIndexRoute(indexPageRoute, permissions, regPageId)
    },
)

/**
 * Checks the widget pages that the user is allowed to see.
 * Three criteria here:
 * - The widget can be a standalone thing (campaigns, issues, etc)
 * - All permissions to see the widgets are satisfied
 * - The fields in the action center settings are satisifed (things like "show_update_info")
 */
export const selectPermissionedWidgetPages = createSelector(
    [selectPermissions, selectActionCenterSettings, selectOrganizationIsI360ClientWithStakeholder],
    (orgPermissions, actionCenter, isI360ClientWithStakeholder) => {
        return (
            DjangIO.app.grassroots.types.GrassrootsWidgetType.items()
                .filter((item) => item.can_be_independent_page)
                .filter((item) => hasAnyPermissions(orgPermissions, item.permissions))
                .filter((item) =>
                    item.action_center_route_permissions
                        ? item.action_center_route_permissions.every((field) => actionCenter[field])
                        : true,
                )
                // Filter only for items where 'exclude_i360_client_with_stakeholder=false'
                // or organization is NOT an i360 client with stakeholder
                .filter((item) => !item.exclude_i360_client_with_stakeholder || !isI360ClientWithStakeholder)
        )
    },
)

/*
 * Returns the widget enums of all widgets that will have their
 * own routes.
 *
 * Filtering goes in stages here.
 * - Get only widgets that are capable of being the only widget on a page.
 *   So for now, this excludes an image widget and a text widget. (Presumably
 *   you'll want at least an image AND text, so you'll have to create a page
 *   with 2+ widgets for it.)
 * - Secondly, if you're logged in, get only the widgets that are visible
 *   while logged in. This is to exclude the login + registration pages --
 *   we really don't want to show you the registration page if you're already
 *   logged in.
 * - Third, if emailRelatedPerson is truthy, then the Supporter is a related Person
 *   and filter for CustomEvent related widgets
 * - Yay dynamic routes!
 */
export const selectWidgetRouteTypes = createSelector(
    [selectPermissionedWidgetPages, selectUserLoggedIn, selectEmailRelatedPerson],
    (permissionedWidgets, loggedIn, emailRelatedPerson) => {
        const baseWidgets = permissionedWidgets.filter((item) => !item.anonymous_only && !item.logged_in_only)

        const conditionalWidgets = loggedIn
            ? permissionedWidgets.filter((item) => item.logged_in_only)
            : permissionedWidgets.filter((item) => item.anonymous_only)

        const widgetItems = [...conditionalWidgets, ...baseWidgets]

        return (
            widgetItems
                // If Supporter does not have a related person, all widgets are valid
                // If Supporter has a related Person, only filter for CustomEvent related widgets
                .filter(
                    (item) =>
                        !emailRelatedPerson ||
                        (item.permissions &&
                            item.permissions.includes(DjangIO.app.models.PermissionType.custom_events.value)),
                )
                // let's just provide the widgets needed for a WidgetPage right here,
                // so framework/index doesn't freak out and rerender all the time.
                .map((item) => ({ ...item, widgets: [{ widgetType: item.value }] }))
        )
    },
)

// returns all the widget links that cannot be currently accessed due
// to the user's status. For instance, if you're logged in, /login should just
// direct to the index page. If you're logged out, /update_info will direct
// you to the index page. Will only redirect widgets that the organization
// can see (if you don't have grassroots, /campaign will 404).
export const selectWidgetRedirectPaths = createSelector(
    [selectPermissionedWidgetPages, selectWidgetRouteTypes],
    (permissionedWidgets, routeWidgets) => {
        return permissionedWidgets
            .filter((item) => !routeWidgets.find((widget) => widget.value === item.value))
            .map((item) => item.widget_url)
    },
)

export const selectRedirectPaths = createSelector(
    [selectWidgetRedirectPaths, selectFoundationalRedirectPaths],
    (widgetPaths, foundationalPaths) => {
        return [...widgetPaths, ...foundationalPaths]
    },
)

/* ***********************************************************************
 * WIDGET LOGIC SELECTORS
 *********************************************************************** */

/*
 * If a page requires a login but a user is not logged in,
 * we'll only render a registration page until they register. After they register,
 * we'll show the content!
 *
 * Each widget should have a uniqueWidgetId, which should get generated here.
 */
export const selectPageWidgets = createSelector(
    [selectActionCenterSettings, selectUserLoggedIn, (_, props) => props.widgets, (_, props) => props.requiresLogin],
    (actionCenter, loggedIn, widgets, requiresLogin) => {
        return (requiresLogin || !actionCenter.publicly_accessible) && !loggedIn
            ? [{ widgetType: DjangIO.app.grassroots.types.GrassrootsWidgetType.sign_in.value }]
            : widgets
    },
)

export const selectParsedPageParams = createSelector(
    (_, props) => props.location.search,
    (search) => queryString.parse(search),
)

export const selectValidPageRegion = createSelector(
    selectParsedPageParams,
    (_, props) => props.usePageRegion,
    (params, usePageRegion) => {
        if (!usePageRegion) {
            return false
        }
        const keys = Object.keys(params)
        if (keys.includes("person")) {
            return true
        }
        if (!keys.includes("state")) {
            return false
        }
        if (keys.length > 2) {
            // if there are more than other keys then we know multiple regions, not valid
            return false
        }
        return true
    },
)

export const selectParamsRegion = createSelector(
    selectValidPageRegion,
    selectParsedPageParams,
    (validPageRegion, params) => {
        if (!validPageRegion) {
            return {}
        }
        let region = {}
        let keys = Object.keys(params)
        if (keys.includes("person")) {
            region.person = params.person
            region.district_type = "person"
            return region
        }
        region.state = params.state
        keys.pop("state")
        // eslint-disable-next-line eqeqeq
        if (keys.length == 0) {
            region.district_type = "state"
            return region
        }
        const districtType = keys[0]
        region.district_type = districtType
        region.number = params[districtType]
        return region
    },
)

export const selectPageRegionKey = createSelector(
    selectParamsRegion,
    (region) => `${region.region_type}-${region.person}-${region.state}-${region.number}`,
)

export const selectRegionFilters = createSelector(
    selectPageRegions,
    selectPageRegionKey,
    (regionStore, regionKey) =>
        regionStore.get(regionKey) &&
        regionStore.getIn([regionKey, "regionFilters"]) &&
        regionStore.getIn([regionKey, "regionFilters"]).toJS(),
)

/* ***********************************************************************
 * NAV BAR SELECTORS
 *********************************************************************** */

export const selectUserNavBarLinks = createSelector(
    [
        selectUserLoggedIn,
        selectUserdata,
        selectShowUpdateInfo,
        selectActionCenterPubliclyAvailable,
        selectEmailRelatedPerson,
    ],
    (loggedIn, userdata, showUpdateInfo, isPublic, emailRelatedPerson) => {
        const { GrassrootsWidgetType, GrassrootsNavigationBarButtonType } = DjangIO.app.grassroots.types

        if (loggedIn) {
            const firstname = userdata.firstname || userdata.email

            return {
                label: firstname ? i18n.t("navbar.greeting", { firstname }) : i18n.t("navbar.greeting_unnamed"),
                dropdown_links: [
                    // If action center settings has 'Show Update Info' set to false
                    // Or Supporter is not related to a Person (via email_related_person field)
                    // Do not display 'Update Info' link and only allow 'Logout'
                    showUpdateInfo &&
                        !emailRelatedPerson && {
                            label: i18n.t("navbar.update_info"),
                            link: GrassrootsWidgetType.update_info.widget_url,
                            type: GrassrootsNavigationBarButtonType.single_page.value,
                            dataCy: "update-info",
                        },
                    {
                        label: i18n.t("navbar.logout"),
                        link: "/logout/",
                        type: GrassrootsNavigationBarButtonType.single_page.value,
                        dataCy: "logout",
                    },
                ].filter((link) => link),
                type: GrassrootsNavigationBarButtonType.dropdown_menu.value,
                dataCy: "user-nav-button",
            }
        } else {
            return {
                label: isPublic ? i18n.t("navbar.sign_up") : i18n.t("navbar.login"),
                link: GrassrootsWidgetType.sign_in.widget_url,
                type: GrassrootsNavigationBarButtonType.single_page.value,
                dataCy: "login",
                login: true,
            }
        }
    },
)

export const selectNavigationBarLinks = createSelector(
    [selectEmailRelatedPerson, selectActionCenterSettings, selectUserNavBarLinks, selectPages],
    (emailRelatedPerson, acs, userLinks, pages) => {
        // We use a recursive function so we can reuse the same logic in filtering links in dropdown menus, but
        // we don't support more than one level of nesting
        const filterLinks = (links) =>
            links
                .map((link) => {
                    // For dropdown menu links, recursively filter its links before actual filtering begins
                    if (link.type === GrassrootsNavigationBarButtonType.dropdown_menu.value) {
                        return {
                            ...link,
                            dropdown_links: filterLinks(link.dropdown_links),
                        }
                    } else {
                        return link
                    }
                })
                .filter((link) => {
                    if (link.type === GrassrootsNavigationBarButtonType.dropdown_menu.value) {
                        // Only include dropdowns that have a nonzero number of items
                        return link.dropdown_links.length > 0
                    } else if (emailRelatedPerson && link.type !== GrassrootsNavigationBarButtonType.external.value) {
                        // If Supporter has a related Person, only display external links and dropdown menus
                        return false
                    } else if (
                        link.type === GrassrootsNavigationBarButtonType.single_page.value &&
                        isFeatureEnabled("ff_restricted_gr_pages")
                    ) {
                        // If the link is to a single page, only display that page if we have access to it
                        const page = pages.find((page) => page.path === link.link)
                        return Boolean(page && page.can_access)
                    } else {
                        // If none of the previous conditions were hit, show the link
                        return true
                    }
                })

        return filterLinks(acs.navigation_bar)
            .concat(userLinks)
            .map((link, i) => ({
                dataCy: link.dataCy || `navBar${i}`,
                ...link,
            }))
    },
)

export const selectTrimmedPath = (_, props) => trimPath(props.location.pathname)

export const selectOriginalCampaign = createSelector(
    selectImmutableCampaignContent,
    selectTrimmedPath,
    (campaign, trimmedPath) =>
        //since this is the campaign that came from the server, we have to make sure the user didn't navigate to a different campaign
        campaign && trimmedPath.startsWith(`campaign/${campaign.toJS().id}`) ? campaign.toJS() : {},
)

export const selectIsCampaignPage = createSelector(
    selectOriginalCampaign,
    selectTrimmedPath,
    selectAugmentedWidgetContent,
    (state) => state.widgets,
    (campaign, trimmedPath, content, widgets) => {
        return Boolean(
            campaign.campaign_type ||
                content.campaign_type || // campaign's content from props.location.state
                widgets.getIn([trimmedPath, "content", "campaign_type"], false), // content from state.widgets where the uniqueWidgetId is the trimmedPath
        )
    },
)

export const selectCampaignContent = createSelector(
    selectOriginalCampaign,
    selectTrimmedPath,
    selectAugmentedWidgetContent,
    (state) => state.widgets,
    (campaign, path, content, widgets) => {
        if (campaign.campaign_type) {
            return campaign
        }
        // uniqueWigetId is the trimmedPath and content should exist at widgets[uniqueWidgetId].content
        // we want to try to get this content first because it will be most up to date
        if (widgets && Map.isMap(widgets.getIn([path.replace("/thanks", ""), "content"]))) {
            // using toJS here to avoid refactoring all selectors which use selectAugmentedWidgetContent
            // & use the same content for Campaign Thank You pages
            return widgets.getIn([path.replace("/thanks", ""), "content"]).toJS()
        }
        // if we have access to the campaign's content from props.location.state then use that as a fallback
        else if (content.campaign_type) {
            return content
        }

        // if we don't have access to campaign's content, return empty object to avoid errors
        return {}
    },
)

export const selectCampaignNavigationBarStyle = createSelector(
    selectCampaignContent,
    (content) => content && content.navigation_bar_type,
)

export const selectNavigationBarIsTransparent = createSelector(
    selectCampaignContent,
    selectCampaignNavigationBarStyle,
    (content, navBarStyle) =>
        navBarStyle === DjangIO.app.grassroots.campaign.types.CampaignNavigationBarType.none.value ||
        (content && content.navigation_bar_is_transparent),
)

export const selectNavBarStyleIsNone = createSelector(selectCampaignNavigationBarStyle, (navBarStyle) => {
    if (navBarStyle) {
        return navBarStyle === DjangIO.app.grassroots.campaign.types.CampaignNavigationBarType.none.value
    }
    return false
})

export const selectFullBleedHeaderType = createSelector(
    selectCampaignContent,
    (content) => content && content.full_bleed_header_type,
)

export const selectCampaignNavigationBarIsLogoOnly = createSelector(
    selectCampaignNavigationBarStyle,
    (style) => style === DjangIO.app.grassroots.campaign.types.CampaignNavigationBarType.logo_only.value,
)

export const headerConnection = createStructuredSelector({
    logoOnly: selectCampaignNavigationBarIsLogoOnly,
    navBarIsTransparent: selectNavigationBarIsTransparent,
})

export const selectCampaignBackgroundStyle = createSelector(
    selectCampaignContent,
    (content) => content && content.background_style_type,
)

export const selectCampaignBackgroundImage = createSelector(
    selectCampaignContent,
    (content) => content && content.background_image_override_url,
)

export const selectCampaignBackgroundColor = createSelector(
    selectCampaignContent,
    (content) => content && content.background_color_override,
)

export const selectCampaignFooterHtml = createSelector(
    selectCampaignContent,
    (content) => content && content.footer_html,
)

export const selectCampaignShouldUseFooterHtml = createSelector(
    selectCampaignContent,
    (content) => content && content.should_use_footer_html,
)

export const selectCampaignLayoutType = createSelector(
    selectCampaignContent,
    (content) => content && content.campaign_layout,
)

export const selectLogoOverrideUrl = createSelector(
    selectCampaignContent,
    (content) => content && content.logo_override_url,
)

export const selectShouldUseFooterHtml = createSelector(
    selectActionCenterShouldUseFooterHtml,
    selectCampaignShouldUseFooterHtml,
    (acsShouldUseFooterHtml, campaignShouldUseFooterHtml) => acsShouldUseFooterHtml || campaignShouldUseFooterHtml,
)

export const selectFooterHtml = createSelector(
    selectActionCenterFooterHtml,
    selectCampaignFooterHtml,
    (acsFooterHtml, campaignFooterHtml) => campaignFooterHtml || acsFooterHtml,
)

/**
 * Selects the current immutable page based on location.pathname.
 *
 * @returns {Immutable.Map | undefined} - An immutable Map of the current page, if we're on a QuorumGrassrootsPage,
 *      otherwise undefined.
 */
export const selectCurrentPageFromLocation = createSelector(
    selectImmutablePages,
    (_state, props) => props.location.path,
    (pages, path) => pages.find((page) => page.get("path") === path),
)

/**
 * Selects the disclaimer_text of the current page, based on the location's pathname. If we are not on a page or the
 * page does not have a disclaimer text this returns undefined.
 *
 * Note, this selector runs outside our routed content, so we dont' have access to the routing info given by
 * react-router.
 *
 * @returns {string | undefined} - The disclaimer text, or undefined if none should be shown
 */
export const selectFooterDisclaimerText = createSelector(selectCurrentPageFromLocation, (currentPage) => {
    const disclaimerText = currentPage && currentPage.get("disclaimer_text")
    return disclaimerText ? disclaimerText : undefined
})

export const selectShowOfficialCustomDescription = createSelector(
    selectActionCenterSettings,
    (acs) => acs.should_show_official_custom_description,
)

const createRegistrationPageSelector = (registrationPageIdsSelector, key) =>
    createSelector(registrationPageIdsSelector, selectRegistrationPages, (ids, registrationPages) => {
        const registrationPage = registrationPages.find((page) => ids.includes(page.get("id")))
        return registrationPage ? registrationPage.get(key, "") : ""
    })

export const createRegistrationPagePreTextSelector = (registrationPageIdsSelector) =>
    createRegistrationPageSelector(registrationPageIdsSelector, "pre_text")
export const createRegistrationPagePostSubmissionActionSelector = (registrationPageIdsSelector) =>
    createRegistrationPageSelector(registrationPageIdsSelector, "post_submission_action_type")
export const createRegistrationPageSubmitButtonTextSelector = (registrationPageIdsSelector) =>
    createRegistrationPageSelector(registrationPageIdsSelector, "submit_button_text")
export const createRegistrationPageThankYouTextSelector = (registrationPageIdsSelector) =>
    createRegistrationPageSelector(registrationPageIdsSelector, "thank_you_text")
export const createRegistrationPageRedirectUrlSelector = (registrationPageIdsSelector) =>
    createRegistrationPageSelector(registrationPageIdsSelector, "redirect_url")
export const createRegistrationPageCustomJavascriptSelector = (registrationPageIdsSelector) =>
    createRegistrationPageSelector(registrationPageIdsSelector, "custom_javascript")
export const createRegistrationPageCustomCssSelector = (registrationPageIdsSelector) =>
    createRegistrationPageSelector(registrationPageIdsSelector, "custom_css")
export const createRegistrationPageCustomAfterRegistrationJsSelector = (registrationPageIdsSelector) =>
    createRegistrationPageSelector(registrationPageIdsSelector, "custom_after_registration_javascript")

export const createRegistrationPageShouldDisplayThankYouTextSelector = (registrationPageIdsSelector) =>
    createSelector(
        createRegistrationPagePostSubmissionActionSelector(registrationPageIdsSelector),
        (actionType) =>
            actionType === DjangIO.app.grassroots.types.RegistrationPostSubmissionAction.thank_you_message.value,
    )

export const createRegistrationPageShouldRedirectToUrlSelector = (registrationPageIdsSelector) =>
    createSelector(
        createRegistrationPagePostSubmissionActionSelector(registrationPageIdsSelector),
        (actionType) =>
            actionType === DjangIO.app.grassroots.types.RegistrationPostSubmissionAction.redirect_to_url.value,
    )

/**
 * Creates a title for the page in the browser toolbar.
 *
 * If a user-specified name for the organization is unspecified (empty string), default to name of organization in first half of title
 * If current page title is unspecified (empty string), default to name of action center in second half of title
 *
 * Returns a title formatted like "Quorum Dev Team | Campaigns"
 * @param  {object} currentPageTitle - Title for the current page ('Campaigns', 'Home Page', etc)
 * @param  {object} organizationUserSpecifiedName - Organization name that Quorum user chooses to display in Grassroots
 * @param  {object} organizationName - Actual name of the organization (displayed if custom name is unspecified)
 * @param  {object} actionCenterName - Name of action center
 * @return {string}
 */
export const createPageTitle = (
    currentPageTitle,
    organizationUserSpecifiedName = window.action_center_settings.organization_user_specified_name,
    organizationName = window.organization.name,
    actionCenterName = window.action_center_settings.name,
) => `${organizationUserSpecifiedName || organizationName} | ${currentPageTitle || actionCenterName}`

export const createPageTitleSelector = (currentPageTitleSelector) =>
    createSelector(
        currentPageTitleSelector,
        selectOrganizationUserSpecifiedName,
        selectOrganizationName,
        selectActionCenterName,
        (currentPageTitle, organizationUserSpecifiedName, organizationName, actionCenterName) =>
            createPageTitle(currentPageTitle, organizationUserSpecifiedName, organizationName, actionCenterName),
    )
