import React from 'react'
import PropTypes from "prop-types"
import classNames from "classnames"
import "frontend/components/stylus/Icon.styl"

const propTypes = {
    dataCy: PropTypes.string,
    // If passed a name prop, the component will prepend a "fa-" to the class of the html element,
    // unless passed in a name with "fa" already prepended
    name: PropTypes.string,
    className: PropTypes.string, // additional classes to apply
    fw: PropTypes.bool,
    lg: PropTypes.bool,
    spin: PropTypes.bool, // should the component spin. Useful for applying spin to a loading indicator
}

/**
* This component renders a font awesome icon
* See the proptypes for some information on what to pass to this component via props
* If you'd like the icon to change opacity when hovering (to .5), pass in class "opacity-hover"
*/
const Icon = (props) => {
    // function to get the inherited props
    const getInheritedProps = () => {
        const reducer = (newProps, propName) => {
            // if one of these, overwrite
            if (!["fw", "spin", "name", "className", "lg"].includes(propName)) {
                return Object.assign({}, newProps, {
                    [propName]: props[propName]
                })
            }
            return newProps
        }
        return Object.keys(props).reduce(reducer, {})
    }

    const getClassNames = () => {
        const iconClasses = {
            fa: true,
            'fa-fw': !!props.fw,
            'fa-spin': !!props.spin,
            'fa-lg': !!props.lg,
        }

        if (props.name) {
            if (props.name.indexOf("fa-") === 0) {
                iconClasses[props.name] = true
            } else {
                iconClasses[`fa-${props.name}`] = true
            }
        }
        if (props.className) {
            iconClasses[props.className] = true
        }
        return iconClasses
    }

    return (
        <i
            className={classNames(getClassNames())}
            {...props.dataCy ? {"data-cy": props.dataCy} : {}}
            {...getInheritedProps()}
        />
    )
}

Icon.propTypes = propTypes
export default Icon
