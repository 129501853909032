import React from "react"
import PropTypes from "prop-types"
import { Field } from "redux-form/immutable"

import { StyledButton } from "QuorumGrassroots/styled-components/components/StyledButton"

import { requiredFieldValidation } from "QuorumGrassroots/helperFunctions"
import { InputField } from "app/static/frontend/forms/components/InputField"

export const StoryForm = ({ t, ...props }) => {
    return (
        <div className="story-form">
            <Field
                component={InputField}
                name={"text"}
                label="Tell your story:"
                validate={requiredFieldValidation}
                isTextarea
                displayErrorWithoutTouch={false}
                isCampaignPage
            />
            <StyledButton onClick={props.handleSubmit} disabled={props.submitting} isCampaignPage activateNGGTheme>
                {props.submitting ? t("form.submitting") : t("form.submit_form")}
            </StyledButton>
        </div>
    )
}

StoryForm.propTypes = {
    // redux form stuff
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    // i18n
    t: PropTypes.func.isRequired,
}

export default StoryForm
