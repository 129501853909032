// suppress linter errors about d3 being undefined
// we require("../js/libs/d3.min.js") in app/static/frontend/index.jsx
/* global d3 */

import { useEffect, useMemo } from "react"

// hooks
import { getVisualizationXAxisLabelLimit } from "app/static/frontend/widgets/components/visualization/hooks/visualizationAxesHooks"

// helpers
import { formatNumberForDisplay } from "shared/imports/sharedHelperFunctions"
import { isIE } from "app/static/frontend/imports/desktopHelperFunctions"

// constants
import dashboardConstants from "app/static/frontend/dashboards/constants"

export const useRenderSvg = ({
    height,
    isPie,
    // BarStacked/BarGrouped
    overrideHeight,
    overrideWidth,
    reference,
    width,
}) =>
    useEffect(() => {
        if (height && width) {
            d3.select(reference.current)
                .attr("height", overrideHeight || "100%")
                // the preserveAspectRatio attribute indicates how an element with a viewBox
                // providing a given aspect ratio must fit into a viewport with a different aspect ratio.

                // "xMidYMid meet" centers the visualization in the exact center of the parent container
                // and scales it to the dimensions of the div while also preserving its aspect ratio
                .attr("preserveAspectRatio", "xMidYMid meet")
                // the viewBox attribute defines the position and dimension, in user space, of an SVG viewport.
                .attr(
                    "viewBox",
                    isPie
                        ? [-width / 2, -height / 2, width, height]
                        : [0, 0, overrideWidth || width, overrideHeight || height],
                )
                .attr("width", overrideWidth || "100%")
        }
    })

export const renderTooltip = ({ reference }) => {
    d3.select(reference.current).select(".svg-tooltip").remove()

    const tooltip = d3
        .select(reference.current)
        .append("g")
        // we have a global style rule that adds a position: absolute
        // to any class named 'tooltip,' so this is named svg-tooltip
        .attr("class", "svg-tooltip")
        .attr("display", "none")

    if (isIE()) {
        tooltip.append("text").style("pointer-events", "none")
    } else {
        tooltip
            .append("foreignObject")
            .attr("height", 33)
            .attr("width", dashboardConstants.visualizationTooltipWidth)
            .style("overflow", "visible")
            .style("pointer-events", "none")

            .append("xhtml:div")
            .attr("height", 33)
            .attr("width", dashboardConstants.visualizationTooltipWidth)
            .style("align-items", "center")
            .style("background-color", "white")
            .style("border-radius", "4px")
            // QuorumDesign/src/Components/colors colors.DarkerPorcelain
            .style("border", "1px solid #F1F3F4")
            .style("box-shadow", "0 2px 5px 0 rgba(0,0,0,0.25)")
            .style("display", "flex")
            .style("font-size", "15px")
            .style("height", "inherit")
            .style("justify-content", "center")
            .style("line-height", "17px")
            .style("padding", "2px")
            .style("text-align", "center")
    }

    return tooltip
}

export const showTooltip = ({ tooltip, value, widthOverflowThreshold, x, y, width = 0 }) => {
    tooltip
        .attr("display", "initial")
        .attr("transform", `translate(${x}, ${y})`)
        .select(
            // in IE 11, the Tooltip DS component renders an svg text element
            // rather than a foreignObject > div container
            isIE() ? "text" : "div",
        )
        .text(value)

    if (!isIE()) {
        const tooltipElementNode = tooltip.select("div").node()
        const tooltipElementNodeBoundingBox = tooltipElementNode.getBoundingClientRect()
        const tooltipElementNodeHeight = tooltipElementNodeBoundingBox.height
        const tooltipElementNodeWidth = tooltipElementNodeBoundingBox.width

        if (tooltipElementNode.scrollHeight > tooltipElementNodeHeight) {
            tooltip
                .select("foreignObject")
                .attr(
                    "height",
                    tooltipElementNode.scrollHeight + (tooltipElementNode.scrollHeight - tooltipElementNodeHeight) + 10,
                )
        }

        if (
            tooltipElementNode.scrollWidth +
                // there are cases where the text just
                // barely fits into the tooltip div without overflow
                // since it looks bad, we widen them anyway
                (widthOverflowThreshold || 0) >
            tooltipElementNodeWidth
        ) {
            tooltip
                .select("foreignObject")
                .attr(
                    "width",
                    tooltipElementNode.scrollWidth + (tooltipElementNode.scrollWidth - tooltipElementNodeWidth) + 25,
                )
        }

        const tooltipObjectNode = tooltip.select("foreignObject").node()
        // after adjusting foreignObject width, the tooltip could be partially hidden by the widget frame,
        // adjust the x here based on the new width
        if (width && x > width - tooltipObjectNode.scrollWidth) {
            tooltip.attr("transform", `translate(${width - tooltipObjectNode.scrollWidth}, ${y})`)
        }
    }
}

export const hideTooltip = ({ tooltip }) => {
    tooltip.attr("display", "none")

    tooltip
        .select("foreignObject")
        .attr("height", dashboardConstants.visualizationTooltipHeight)
        .attr("width", dashboardConstants.visualizationTooltipWidth)
}

export const useGetXDomain = ({
    data,
    // grouped x0
    groupKey,
    // grouped x1
    keys,
    series,
    timestamp,
}) =>
    useMemo(() => {
        if (!timestamp) {
            return []
        }

        // stacked
        if (series) {
            // data.map(d => d.name)
            return series[0].map((d) => d.x)
            // grouped x1
        } else if (keys && groupKey) {
            return keys
            // grouped x0
        } else if (groupKey) {
            return data.map((d) => d[groupKey])
        }

        // standard
        // Sets the output range from the specified continuous interval.
        // The array interval contains two elements representing the minimum and maximum numeric value.
        // This interval is subdivided into n evenly-spaced bands, where n is the number of (unique) values in the input domain.
        // https://github.com/d3/d3-scale#band_range
        // https://d3-wiki.readthedocs.io/zh_CN/master/Ordinal-Scales/#ordinal_range
        return d3.range(data.length)
    }, [timestamp])

export const useGetXRangeRoundBands = ({
    // isHorizontal
    height,
    // grouped x1
    groupKey,
    isHorizontal,
    // grouped x1
    keys,
    margin,
    timestamp,
    width,
    // grouped x1
    xRendererMemo,
}) => {
    const memo = [timestamp, ...(isHorizontal ? [height] : [width])]

    return useMemo(() => {
        // grouped x1
        if (keys && groupKey) {
            return [
                0,
                // x0.bandwidth()
                xRendererMemo.rangeBand(),
            ]
        }

        if (isHorizontal) {
            return [margin.top, height - margin.bottom]
        }

        // standard, grouped x0, stacked
        return [margin.left, width - margin.right]
    }, memo)
}

export const useXRenderer = ({ domain, height, isHorizontal, rangeRoundBandsMemo, timestamp, width }) => {
    const memo = [timestamp, ...(isHorizontal ? [height] : [width])]

    return useMemo(
        () =>
            d3.scale // https://d3-wiki.readthedocs.io/zh_CN/master/Ordinal-Scales/#ordinal // https://github.com/d3/d3-scale#scaleOrdinal // Constructs a new ordinal scale with an empty domain and an empty range. // .scaleBand() // https://github.com/d3/d3-scale#scaleBand // https://github.com/d3/d3/blob/master/CHANGES.md#scales-d3-scale // TODO: uncomment below and delete .scale.ordinal() after d3 v5 upgrade
                .ordinal()

                // If domain is specified, sets the domain to the specified array of values.
                // The first element in domain will be mapped to the first band, the second domain value to the second band, and so on.
                // https://github.com/d3/d3-scale#band_domain
                // https://d3-wiki.readthedocs.io/zh_CN/master/Ordinal-Scales/#ordinal_domain
                .domain(domain)

                // TODO: uncomment below and delete .rangeRoundBands(...) after d3 v5 upgrade
                // https://github.com/d3/d3/blob/master/CHANGES.md#scales-d3-scale
                // https://github.com/d3/d3-scale#band_range
                // .range(getRangeRoundBands())
                // A convenience method for setting the inner and outer padding to the same padding value.
                // If padding is not specified, returns the inner padding.
                // https://github.com/d3/d3-scale#band_padding
                // .padding(0.3, 0.085)

                // Like .range (see above), guarantees that range values and band width are integers so as to avoid antialiasing artifacts.
                // https://d3-wiki.readthedocs.io/zh_CN/master/Ordinal-Scales/#ordinal_rangeRoundBands
                .rangeBands(
                    // interval[, padding[, outerPadding]]
                    rangeRoundBandsMemo,
                    0.3,
                    0.085,
                ),
        memo,
    )
}

export const xAxisRenderer =
    ({
        // standard, grouped
        data,
        height,
        isHorizontal,
        margin,
        // isHorizontal
        percentage,
        width,
        xAxisLabelsLongestLength,
        xRendererMemo,
    }) =>
    (g) => {
        // this is trivial and does not necessitate memoization
        const getTickFormat = (d, i) => {
            // standard, grouped
            if (data && data.length) {
                const { name } = data[i]
                const xAxisLabelLimit = getVisualizationXAxisLabelLimit({ height, width })

                return name.length > xAxisLabelLimit ? `${data[i].name.slice(0, xAxisLabelLimit)}...` : data[i].name
            }

            // stacked, default
            const name = d
            const xAxisLabelLimit = getVisualizationXAxisLabelLimit({ height, width })
            return name.length > xAxisLabelLimit ? `${d.slice(0, xAxisLabelLimit)}...` : d
        }

        // takes the xAxisLabelsLongestLength and decides by
        // how many degrees to rotate the x-axis labels in the svg
        const xAxisLabelRotation = () => {
            if (xAxisLabelsLongestLength < 5) {
                return 0
            } else if (xAxisLabelsLongestLength >= 5 && xAxisLabelsLongestLength < 15) {
                return -45
            }

            return -90
        }

        // takes the xAxisLabelsLongestLength and, depending on its length,
        // decides by how many pixels to
        // "shift along the x-axis on the position of an element or its content"
        // (how many pixels to shift the label along the x-axis)
        // https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/dx
        // (this is used to position the labels precisely depending on the rotation selected in xAxisLabelRotation)
        const xAxisLabelDx = () => {
            if (xAxisLabelsLongestLength < 5) {
                return ".5em"
            } else if (xAxisLabelsLongestLength >= 5 && xAxisLabelsLongestLength < 15) {
                return "-.5em"
            }

            return "-.5em"
        }

        // takes the xAxisLabelsLongestLength and, depending on its length,
        // decides by how many pixels to
        // "shift along the y-axis on the position of an element or its content"
        // (how many pixels to shift the label along the y-axis)
        // https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/dy
        // this is used to position the labels precisely depending on the rotation selected in xAxisLabelRotation
        const xAxisLabelDy = () => {
            if (xAxisLabelsLongestLength < 5) {
                return ".75em"
            } else if (xAxisLabelsLongestLength >= 5 && xAxisLabelsLongestLength < 15) {
                return ".5em"
            }

            return "-.25em"
        }

        // takes the xAxisLabelsLongestLength and, depending on its length,
        // decides if the text-anchor css rule should be "start" or "end"
        // https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/text-anchor
        // this is used to rotate the labels by either the start or end of the text
        // it is necessary because, when rotating a text svg element by 90° by the "end" of the text, it looks wrong
        const xAxisTextAnchor = () => "end"

        const xAxis = d3.svg // ) //     }) //         width, //         margin, //         groupKey, //         // grouped //         domain, //     useXRenderer({ // .axisBottom( // https://github.com/d3/d3-axis#axisBottom // https://github.com/d3/d3/blob/master/CHANGES.md#axes-d3-axis // TODO: uncomment below and delete .svg.axis().scale(useXRenderer({...})).orient("bottom") after d3 v5 upgrade
            // Create a new default axis.
            // https://d3-wiki.readthedocs.io/zh_CN/master/SVG-Axes/#axis
            .axis()
            // If scale is specified, sets the scale and returns the axis.
            // If scale is not specified, returns the current scale which defaults to a linear scale.
            // https://d3-wiki.readthedocs.io/zh_CN/master/SVG-Axes/#scale
            .scale(xRendererMemo)

            // If orientation is specified, sets the orientation and returns the axis.
            // If orientation is not specified, returns the current orientation which defaults to "bottom".
            // https://d3-wiki.readthedocs.io/zh_CN/master/SVG-Axes/#orient
            .orient("bottom")

        return (
            g
                .attr("transform", `translate(0,${height - margin.bottom})`)
                .call(
                    isHorizontal
                        ? xAxis
                              // Sets the arguments that will be passed to scale.ticks and
                              // scale.tickFormat when the axis is rendered, and returns the axis generator.
                              // https://github.com/d3/d3-axis#axis_ticks
                              // https://d3-wiki.readthedocs.io/zh_CN/master/SVG-Axes/#ticks
                              .ticks(5)
                              // If format is specified, sets the format to the specified function and returns the axis.
                              // If format is not specified, returns the current format function, which defaults to null.
                              // A null format indicates that the scale's default formatter should be used,
                              // which is generated by calling scale.tickFormat.
                              // https://d3-wiki.readthedocs.io/zh_CN/master/SVG-Axes/#tickFormat
                              .tickFormat(
                                  percentage
                                      ? // 100% stacked
                                        d3.format(".0%")
                                      : (yValue) => formatNumberForDisplay(yValue),
                              )
                              // TODO: uncomment below and delete .innerTickSize(0) after d3 v5 upgrade
                              // https://github.com/d3/d3/blob/master/CHANGES.md#axes-d3-axis
                              // https://github.com/d3/d3-axis#axis_tickSizeInner
                              // .tickSizeInner(-width + margin.left + margin.right)

                              // https://d3-wiki.readthedocs.io/zh_CN/master/SVG-Axes/#innerTickSize
                              // http://bl.ocks.org/hunzy/11110940
                              .innerTickSize(-height + margin.top + margin.bottom)
                        : // If padding is specified, sets the padding to the specified value in pixels and returns the axis.
                          // If padding is not specified, returns the current padding which defaults to 3 pixels.
                          // https://github.com/d3/d3-axis#axis_tickPadding
                          // https://d3-wiki.readthedocs.io/zh_CN/master/SVG-Axes#tickPadding
                          // .tickPadding(10)
                          xAxis
                              // If format is specified, sets the tick format function and returns the axis.
                              // If format is not specified, returns the current format function, which defaults to null.
                              // https://github.com/d3/d3-axis#axis_tickFormat
                              // https://d3-wiki.readthedocs.io/zh_CN/master/SVG-Axes/#tickFormat
                              .tickFormat(getTickFormat)

                              // TODO: uncomment below and delete .outerTickSize(0) after d3 v5 upgrade
                              // https://github.com/d3/d3/blob/master/CHANGES.md#axes-d3-axis
                              // https://github.com/d3/d3-axis#axis_tickSizeOuter
                              // .tickSizeOuter(0)

                              // If size is specified, sets the outer tick size to the specified value and returns the axis.
                              // If size is not specified, returns the current outer tick size, which defaults to 6.
                              // https://d3-wiki.readthedocs.io/zh_CN/master/SVG-Axes/#outerTickSize
                              .outerTickSize(0),
                )
                // remove the horizontal line that is automatically added to the x axis when we draw ticks (isHorizontal)
                .call((group) => group.select(".domain").remove())
                // make the x-axis gridlines visible
                .call((group) =>
                    group
                        .selectAll("line")
                        .style("fill", "none")
                        .style("stroke", "#ccc")
                        .style("shape-rendering", "crispEdges"),
                )
                // match the mocks and rotate the x-axis labels
                .call((group) => {
                    const styledGroup = group.selectAll("text").style("font-size", "14px")

                    // do not rotate the x-axis labels if isHorizontal
                    if (isHorizontal) {
                        return styledGroup
                    }

                    return styledGroup
                        .style("text-anchor", xAxisTextAnchor())
                        .attr("dx", `${xAxisLabelDx()}`)
                        .attr("dy", `${xAxisLabelDy()}`)
                        .attr("transform", `rotate(${xAxisLabelRotation()})`)
                        .append("title")
                        .text((d) => d)
                })
        )
    }

export const useRenderXAxis = ({
    // standard, grouped
    data,
    height,
    isHorizontal,
    margin,
    // isHorizontal
    percentage,
    reference,
    timestamp,
    width,
    xAxisLabelsLongestLength,
    xRendererMemo,
}) =>
    useEffect(() => {
        if (height && width) {
            d3.select(reference.current).select(".x-axis").remove()

            d3.select(reference.current).append("g").attr("class", "x-axis").call(
                xAxisRenderer({
                    // standard, grouped
                    data,
                    height,
                    isHorizontal,
                    margin,
                    // isHorizontal
                    percentage,
                    width,
                    xAxisLabelsLongestLength,
                    xRendererMemo,
                }),
            )
        }
        // useXRenderer updates on width and xAxisRenderer updates on height
    }, [timestamp, height, width])

export const useGetYDomain = ({
    // standard, grouped
    data,
    // grouped
    keys,
    // 100% stacked
    percentage,
    // stacked
    series,
    timestamp,
}) =>
    useMemo(() => {
        // the cast to Number() is necessary below because some complex x_frequency_tuples,
        // such as DateFields.sum_aggregation.querymethod
        // (which is TransactionQuerySet sum_aggregation_frequency_tuples),
        // return lossless Decimal numbers (converted from python long)
        // that we need to convert to a javascript number/BigNum

        // 100% stacked
        if (percentage) {
            if (series.length) {
                return 1
            }

            return undefined
        }
        // stacked
        else if (series) {
            // d3.max(series, d => d3.max(d, d => d[1]))
            return (
                d3.max(
                    series,
                    (seriesData) =>
                        d3.max(
                            seriesData,
                            // TODO: uncomment below and delete d => d.y0 + d.y after d3 v5 upgrade
                            // d => d[1]
                            (datum) => Number(datum.y0) + Number(datum.y),
                        ),
                    // some Bar Group By options (particularly the bulk_email FrequencyTupleField enums)
                    // return 0 results for multiple items,
                    // which prevents the y-axis from rendering since the domain is 0
                ) || 1
            )
            // grouped
        } else if (keys) {
            // d3.max(data, d => d3.max(keys, key => d[key]))
            return (
                d3.max(
                    data,
                    (datum) => d3.max(keys, (key) => Number(datum[key])),
                    // some Bar Group By options (particularly the bulk_email FrequencyTupleField enums)
                    // return 0 results for multiple items,
                    // which prevents the y-axis from rendering since the domain is 0
                ) || 1
            )
        }

        // standard
        return (
            d3.max(
                data,
                (datum) => Number(datum.value),
                // some Bar Group By options (particularly the bulk_email FrequencyTupleField enums)
                // return 0 results for multiple items,
                // which prevents the y-axis from rendering since the domain is 0
            ) || 1
        )
    }, [timestamp, percentage])

export const useYRenderer = ({
    domain,
    height,
    isHorizontal,
    margin,
    // 100% stacked
    percentage,
    timestamp,
    // isHorizontal
    width,
}) => {
    const memo = [timestamp, percentage, ...(isHorizontal ? [width] : [height])]

    return useMemo(
        () =>
            d3.scale // https://d3-wiki.readthedocs.io/zh_CN/master/Quantitative-Scales/#linear // Linear scales are a good default choice for continuous quantitative data because they preserve proportional differences. // If either domain or range are not specified, each defaults to [0, 1]. // Constructs a new continuous scale with the specified domain and range, the default interpolator and clamping disabled. // .scaleLinear() // https://github.com/d3/d3-scale#scaleLinear // https://github.com/d3/d3/blob/master/CHANGES.md#scales-d3-scale // TODO: uncomment below and delete .scale.ordinal() after d3 v5 upgrade
                .linear()
                // If domain is specified, sets the scale’s domain to the specified array of numbers.
                // The array must contain two or more elements.
                // If the elements in the given array are not numbers, they will be coerced to numbers.
                // https://github.com/d3/d3-scale#continuous_domain
                // https://d3-wiki.readthedocs.io/zh_CN/master/Ordinal-Scales/#ordinal_domain
                .domain([0, domain])
                // Extends the domain so that it starts and ends on nice round values as
                // determined by the specified time interval and optional step count.
                // https://github.com/d3/d3-scale/blob/master/README.md#continuous_nice
                // https://d3-wiki.readthedocs.io/zh_CN/master/Time-Scales/#nice
                .nice()
                // Sets the output range from the specified continuous interval.
                // The array interval contains two elements representing the minimum and maximum numeric value.
                // This interval is subdivided into n evenly-spaced bands, where n is the number of (unique) values in the input domain.
                // https://github.com/d3/d3-scale#band_range
                // https://d3-wiki.readthedocs.io/zh_CN/master/Ordinal-Scales/#ordinal_range
                .range(isHorizontal ? [margin.left, width - margin.right] : [height - margin.bottom, margin.top]),
        memo,
    )
}

export const yAxisRenderer =
    ({
        // isHorizontal
        data,
        height,
        isHorizontal,
        margin,
        // 100% stacked
        percentage,
        width,
        yRendererMemo,
    }) =>
    (g) => {
        // this is trivial and does not necessitate memoization
        const getTickFormat = (d, i) => {
            const xAxisLabelLimit = getVisualizationXAxisLabelLimit({ height, width })
            return d.length > xAxisLabelLimit ? `${d.slice(0, xAxisLabelLimit)}...` : d
        }

        const yAxis = d3.svg // ) //     }) //         margin, //         height, //         domain, //     useYRenderer({ // .axisLeft( // https://github.com/d3/d3-axis#axisLeft // https://github.com/d3/d3/blob/master/CHANGES.md#axes-d3-axis // TODO: uncomment below and delete .svg.axis().scale(useYRenderer({...})).orient("left") after d3 v5 upgrade
            // Create a new default axis.
            // https://d3-wiki.readthedocs.io/zh_CN/master/SVG-Axes/#axis
            .axis()
            // If scale is specified, sets the scale and returns the axis.
            // If scale is not specified, returns the current scale which defaults to a linear scale.
            // https://d3-wiki.readthedocs.io/zh_CN/master/SVG-Axes/#scale
            .scale(yRendererMemo)
            // If orientation is specified, sets the orientation and returns the axis.
            // If orientation is not specified, returns the current orientation which defaults to "bottom".
            // https://d3-wiki.readthedocs.io/zh_CN/master/SVG-Axes/#orient
            .orient("left")

        return (
            g
                .attr("transform", `translate(${margin.left},0)`)
                .call(
                    isHorizontal
                        ? yAxis
                              // If format is specified, sets the tick format function and returns the axis.
                              // If format is not specified, returns the current format function, which defaults to null.
                              // https://github.com/d3/d3-axis#axis_tickFormat
                              // https://d3-wiki.readthedocs.io/zh_CN/master/SVG-Axes/#tickFormat
                              .tickFormat(getTickFormat)

                              // TODO: uncomment below and delete .outerTickSize(0) after d3 v5 upgrade
                              // https://github.com/d3/d3/blob/master/CHANGES.md#axes-d3-axis
                              // https://github.com/d3/d3-axis#axis_tickSizeOuter
                              // .tickSizeOuter(0)

                              // If size is specified, sets the outer tick size to the specified value and returns the axis.
                              // If size is not specified, returns the current outer tick size, which defaults to 6.
                              // https://d3-wiki.readthedocs.io/zh_CN/master/SVG-Axes/#outerTickSize
                              .outerTickSize(0)
                        : yAxis
                              // Sets the arguments that will be passed to scale.ticks and
                              // scale.tickFormat when the axis is rendered, and returns the axis generator.
                              // https://github.com/d3/d3-axis#axis_ticks
                              // https://d3-wiki.readthedocs.io/zh_CN/master/SVG-Axes/#ticks
                              .ticks(5)
                              // If format is specified, sets the format to the specified function and returns the axis.
                              // If format is not specified, returns the current format function, which defaults to null.
                              // A null format indicates that the scale's default formatter should be used,
                              // which is generated by calling scale.tickFormat.
                              // https://d3-wiki.readthedocs.io/zh_CN/master/SVG-Axes/#tickFormat
                              .tickFormat(
                                  percentage
                                      ? // 100% stacked
                                        d3.format(".0%")
                                      : (yValue) => formatNumberForDisplay(yValue),
                              )
                              // TODO: uncomment below and delete .innerTickSize(0) after d3 v5 upgrade
                              // https://github.com/d3/d3/blob/master/CHANGES.md#axes-d3-axis
                              // https://github.com/d3/d3-axis#axis_tickSizeInner
                              // .tickSizeInner(-width + margin.left + margin.right)

                              // https://d3-wiki.readthedocs.io/zh_CN/master/SVG-Axes/#innerTickSize
                              // http://bl.ocks.org/hunzy/11110940
                              .innerTickSize(-width + margin.left + margin.right)
                              // If padding is specified, sets the padding to the specified value in pixels and returns the axis.
                              // If padding is not specified, returns the current padding which defaults to 3 pixels.
                              // https://github.com/d3/d3-axis#axis_tickPadding
                              // https://d3-wiki.readthedocs.io/zh_CN/master/SVG-Axes#tickPadding
                              .tickPadding(10),
                )
                // remove the vertical line that is automatically added to the y axis
                .call((group) => group.select(".domain").remove())
                // make the y-axis gridlines visible
                .call((group) =>
                    group
                        .selectAll("line")
                        .style("fill", "none")
                        .style("stroke", "#ccc")
                        .style("shape-rendering", "crispEdges"),
                )
                // match the mocks
                .call((group) => group.selectAll("text").style("font-size", "15px"))
        )
    }

export const useRenderYAxis = ({
    // isHorizontal
    data,
    height,
    isHorizontal,
    margin,
    // 100% stacked
    percentage,
    reference,
    width,
    timestamp,
    yRendererMemo,
}) =>
    useEffect(() => {
        if (height && width) {
            d3.select(reference.current).select(".y-axis").remove()

            d3.select(reference.current).append("g").attr("class", "y-axis").call(
                yAxisRenderer({
                    data,
                    height,
                    isHorizontal,
                    margin,
                    percentage,
                    width,
                    yRendererMemo,
                }),
            )
        }
        // useYRenderer updates on height and yAxisRenderer updates on width
    }, [timestamp, height, percentage, width])
