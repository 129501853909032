import { api, ResponseNotOkError } from "@/api"

// These default filters help exclude organizations that are not active
// Make sure to keep these for charity selects and EIN verification
export const defaultFilters = {
    organization: {
        properties: {
            pub78_verified: true,
            exclude_revoked_organizations: true,
            exclude_defunct_or_merged_organizations: true,
            bmf_status: true,
        },
    },
}

// https://developer.candid.org/reference/essentials_v3
export const getCharities = async ({
    // Can be any string you'd like to search on,
    // including EIN, organization name, keywords, etc.
    searchTerms,
    // The offset from the first result. Defaults to 0.
    from = 0,
    // The maximum number of hits to be returned.
    // Defaults to 25.
    // The maximum number allowed is 25.
    size = 25,
}: {
    searchTerms?: string
    from?: number
    size?: number
} = {}) => {
    try {
        const body = {
            // Leave blank to sort by relevance.
            sort_by: "",
            filters: defaultFilters,
        }

        if (searchTerms) {
            body["search_terms"] = searchTerms
        }

        if (from) {
            body["from"] = from
        }

        const response = await api.post("/api/charities/", body, {
            headers: {
                Cookie: document.cookie,
            },
        })

        const data = await response.json()

        if (
            (data.code !== 200 && data.code !== 201) ||
            data.message !== "Request was processed successfully!" ||
            (Array.isArray(data.errors) && data.errors.length)
        ) {
            throw data
        }

        return data
    } catch (err) {
        console.error(err)
        return false
    }
}

/**
 * Get charity object by EIN. If the charity does not exist gives null.
 * The default filters are meant to exclude organizations that aren’t active, even if their EIN exists on Candid’s database
 */
export const getCharityByEin = async (ein: string): Promise<CandidApiCharity | null> => {
    const response = await api.post(
        "/api/charities/",
        {
            filters: { ...defaultFilters },
            search_terms: ein,
        },
        { throwOnNotOk: false },
    )
    if (response.status === 404) {
        return Promise.resolve(null)
    } else if (response.status === 200) {
        const data: CharityApiResponse = await response.json()
        const charity = data.hits.find((charity) => charity.organization.ein === ein)
        return Promise.resolve(charity ? charity : null)
    } else {
        return Promise.reject(new ResponseNotOkError(response))
    }
}

/**
 * Query if a charity with a given EIN exists.
 */
export const queryDoesEinExist = (ein: string): Promise<boolean> => getCharityByEin(ein).then((charity) => !!charity)

/**
 * The (partial) shape of a 200 response from the charities API.
 *
 * https://developer.candid.org/reference/essentials_v3
 */
export type CharityApiResponse = {
    code: 200
    errors: unknown[]
    hits: CandidApiCharity[]
    message: string
    page_count: number
    results_count: number
    time: string
    took: number
}

/**
 * A single charity as returned by the Candid API.
 *
 * This structure isn't complete, since we don't use all the fields. We should fill this out as we use more fields.
 */
export type CandidApiCharity = {
    geography: {
        address_line_1: string
        address_line_2: string
        city: string
        congressional_district: string
        county: string
        latitude: number
        longitude: number
        msa: string
        state: string
        zip: string
    }
    organization: {
        ein: string
    }
}
