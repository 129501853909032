import { VisitOfficeForm } from "QuorumGrassroots/campaign-forms/components/VisitOfficeForm"
import { getMessageFormWrapper } from "QuorumGrassroots/campaign-forms/wrappers/GetMessagesFormWrapper"

export default getMessageFormWrapper({
    formName: "visit_office",
    generateGrassrootsActions: (messageValues, globalFormValues, props) => {
        return messageValues.reduce((acc, value) => {
            acc.push({
                text: [value.get("prebody"), value.get("body"), value.get("postbody")]
                    .filter((text) => text)
                    .join("\n\n"),
                subject: value.get("subject"),
                person: DjangIO.app.person.models.Person.resourceUriFromId(value.getIn(["target", "id"])),
                campaign: DjangIO.app.grassroots.campaign.models.Campaign.resourceUriFromId(props.campaign.id),
                supporter_action_type: props.campaign.campaign_type,
            })

            return acc
        }, [])
    },
    getIdFromMessage: (message) => message.target.id.toString(),
})(VisitOfficeForm)
