import { successfullySubmittedWidget } from "QuorumGrassroots/widgets/action-creators"
import { resourcePostList } from "shared/djangio/action-creators"
import BACKENDERROR from "frontend/imports/backenderror"
import { swalConfigs } from "QuorumGrassroots/swalConfigs"
import { postGrassrootsSupporterActionActionTypes } from "QuorumGrassroots/campaign-forms/action-types"

export const onSubmit = (immutableValues, dispatch, props) => {
    const actionCenter = props.actionCenter.resource_uri
    const { text } = immutableValues.toJS()
    try {
        let result = dispatch(
            resourcePostList(
                DjangIO.app.grassroots.models.GrassrootsSupporterAction.objects,
                postGrassrootsSupporterActionActionTypes,
                {
                    supporter: DjangIO.app.grassroots.models.Supporter.resourceUriFromId(props.supporterId),
                    organization: DjangIO.app.userdata.models.Organization.resourceUriFromId(props.organizationId),
                    supporter_action_type:
                        DjangIO.app.grassroots.enums.SupporterActionType.submit_contact_us_widget.value,
                    action_center: actionCenter,
                    interaction_type: DjangIO.app.userdata.types.GrassrootsInteractionType.contact_us.value,
                    text,
                },
                props.uniqueWidgetId,
            ),
        ).then((response) => {
            return dispatch(successfullySubmittedWidget(props.uniqueWidgetId))
        })
    } catch (error) {
        BACKENDERROR(error, swalConfigs.postGrassrootsActionError, true, false)
        throw error
    }
}
