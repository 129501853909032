// this is so we import backenderror in QuorumGrassroots.
if (typeof Userdata === "undefined") {
    window.Userdata = {}
}

// this file should be used to store global swal configurations that can be reused across the site
// if you need to only modify one (or a few keys) in an object, you can use the following syntax
// to retain some reusability: Object.assign({}, originalConfig, { overideKey: overideValue })
// the default error shown in the BACKENDERROR function is in the defaultError object
const swalContents = {
    emptyDownload: {
        icon: "warning",
        title: "No items",
        text: "There is nothing to download!",
    },
    defaultError: {
        icon: "error",
        title: "Oops!",
        text: "Please check your connection and try again. If the problem continues, contact our support team.",
        buttons: ["OK", "Refresh"],
    },
    notAllowedError: {
        icon: "error",
        title: "Error",
        text: "It looks like you tried to do something you aren't allowed to do. If you just entered a password, you may have typed it incorrectly, or you may be trying to access a resource that you aren't allowed access to.",
    },
    validationError: {
        icon: "error",
        title: "Error",
        text: "We are unable to complete that action.",
    },
    downloadError: {
        icon: "error",
        title: "We're sorry!",
        text: "There was an error downloading your file.",
    },
    downloadErrorDirectToCSV: {
        icon: "error",
        title: "We're sorry!",
        text: "Due to the large number of data requested, please download the data into a CSV file.",
    },
    willDownload: {
        icon: "info",
        title: "Confirm Download",
        text: "Please confirm you would like to proceed.",
        buttons: {
            cancel: "Cancel",
            confirm: {
                text: "Download",
                closeModal: false,
                value: true,
            },
        },
    },
    successDownload: {
        icon: "success",
        title: "Success!",
        text: "Your download is ready!",
    },
    downloadInProgress: {
        icon: "info",
        title: "Download in Progress",
        text: "Your download will be ready shortly.",
    },
    listDownloadLimitPrompt: {
        icon: "warning",
        title: "Your list is large",
        text: `Only the first ${Userdata.max_csv_size} items will be downloaded`,
        buttons: {
            cancel: "Cancel",
            confirm: {
                text: "Download",
                closeModal: false,
                value: true,
            },
        },
    },
    removeAlertPrompt: {
        title: "Are you sure?",
        text: "This will permanently delete the alert",
        icon: "warning",
        buttons: [true, true],
    },
    removeSheetColumnPrompt: {
        title: "Are you sure?",
        text: "Are you sure you want to remove this column? This cannot be undone",
        icon: "warning",
        buttons: ["Cancel", "Remove Column"],
    },
    outboxBadTestEmail(
        swalText = "Please make sure the addresses are separated by a comma. Ex: not-a-real-email@quorum.us, also-not-real@quorum.us",
    ) {
        return {
            icon: "error",
            title: "Failed to Send Test Emails",
            text: swalText,
        }
    },
    outboxFailedEmailSend(swalText = "Please check your network connection and try again.") {
        return {
            icon: "error",
            title: "The email was not sent",
            text: swalText,
        }
    },
    outboxFailedPreview(swalText = "Please make sure your placeholders are valid.") {
        return {
            icon: "error",
            title: "Failed to Generate Previews",
            text: swalText,
        }
    },
    OUTBOX_DELETE_DRAFT_SWALCONFIG: {
        icon: "warning",
        title: "Discard Draft?",
        text: "This will permanently discard this draft message",
        buttons: ["Cancel", "Discard Draft"],
    },
    OUTBOX_DELETE_DRAFT_ALERT_CONFIG: {
        icon: "trash-alt",
        iconFamily: "fas",
        iconColor: "#503ABD",
        title: "Discard Draft?",
        text: "This will permanently discard this draft message.",
        actions: {
            cancel: true,
            discard: "Discard Draft",
        },
    },
    OUTBOX_SAVE_TEMPLATE_SWALCONFIG: {
        buttons: ["Cancel", "Create"],
        title: "Save message content as a new template?",
        text: "Please name your template",
        content: {
            element: "input",
            attributes: {
                placeholder: "Your Template Name...",
            },
        },
    },
    OUTBOX_SAVE_TEMPLATE_ALERT_CONFIG: {
        title: "Save message content as a new template?",
        text: "Please name your template",
        content: {
            element: "TextInput",
            attributes: {
                placeholder: "Your Template Name...",
                id: "name",
            },
        },
        actions: {
            cancel: true,
            create: true,
        },
    },
    OUTBOX_LOAD_TEMPLATE_SWALCONFIG: {
        buttons: ["Cancel", "Okay"],
        title: "Load this template into your current message?",
        text: "This will replace the current content of your message",
        icon: "warning",
    },
    OUTBOX_LOAD_TEMPLATE_ALERT_CONFIG: {
        actions: {
            cancel: true,
            okay: true,
        },
        title: "Load Template?",
        text: "This will replace the current content of your message",
        icon: "copy",
        iconFamily: "fas",
    },
    OUTBOX_SAVE_SUCCESS_SWAL_CONFIG: {
        buttons: ["Continue Editing", "Go to overview page"],
        icon: "success",
        title: "Your message has been saved",
        text: "Do you want to continue editing?",
        closeOnClickOutside: false,
    },
    OUTBOX_SAVE_CONFLICT_SWAL_CONFIG: {
        buttons: {
            overwrite: {
                text: "Overwrite",
                value: "overwrite",
            },
            save: {
                text: "Save New",
                value: "save",
            },
            refresh: {
                text: "Refresh",
                value: "refresh",
            },
            cancel: "Cancel",
        },
        className: "save-conflict-swal",
        icon: "warning",
        title: "Save Conflict",
        text: "You or another user have this email draft open in other tab and there’s a newer version available.\nWould you like to:",
        closeOnClickOutside: false,
    },
    OUTBOX_SAVE_CONFLICT_OVERWRITE_SWAL_CONFIG: {
        title: "Are you sure?",
        text: "Save this version and overwrite any work in other tabs you or another user may have open.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    },
    OUTBOX_SAVE_CONFLICT_REFRESH_SWAL_CONFIG: {
        title: "Are you sure?",
        text: "Update Outbox form with the most recent changes that you or another user have saved in another tab. You will lose changes to this current version.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    },
    OUTBOX_CHANGE_COMPOSE_TOOL_CONFIG: {
        title: "Switch Editors?",
        text: "If you switch email tools your current text will be lost",
        icon: "exchange-alt",
        iconFamily: "fas",
        actions: {
            cancel: true,
            confirm: "Yes, switch tools and reset",
        },
    },

    // prof-new swals
    DELETE_SUPPORTER_SWALCONFIG: {
        title: "Are you sure you want to delete this contact?",
        icon: "warning",
        buttons: [true, "Delete"],
    },
    DELETE_ORGANIZATION_SWALCONFIG: {
        title: "Are you sure you want to delete this organization? All contacts associated with this organization will be unaffiliated.",
        icon: "warning",
        buttons: [true, "Delete"],
    },
    DELETE_EVENT_SWALCONFIG: {
        title: "Are you sure you want to delete this event?",
        icon: "warning",
        buttons: [true, "Delete"],
    },
    DELETE_TEAM_SWALCONFIG: {
        title: "Are you sure you want to delete this team?",
        text: "Items that were tagged to only this team may disappear",
        icon: "warning",
        buttons: [true, "Delete"],
    },
    DELETE_DOWNLOAD_SWALCONFIG: {
        title: "Are you sure you want to delete this download?",
        icon: "warning",
        buttons: [true, "Delete"],
    },
    CANCEL_SCHEDULED_EMAIL: {
        title: "Are you sure you want to cancel this message?",
        text: "After you cancel this email, it will be moved back into your drafts",
        icon: "warning",
        buttons: ["No", "Yes, cancel and edit"],
    },
    CANCEL_SCHEDULED_TEST: {
        title: "Are you sure you want to cancel this test?",
        text: "After you cancel this test, it will be moved back into your drafts",
        icon: "warning",
        buttons: ["No", "Yes, cancel and edit"],
    },
    CANCEL_EMAIL_FAIL: (errorMessage) => ({
        title: "Failed to cancel email",
        text: errorMessage,
        icon: "error",
    }),
    CANCEL_TEST_FAIL: (errorMessage) => ({
        title: "Failed to cancel test",
        text: errorMessage,
        icon: "error",
    }),
    invalidKeyword: (errorMessage) => ({
        title: "Duplicate Keyword",
        icon: "error",
        text: errorMessage,
    }),
    OUTBOX_PROJECT_VALIDATION_FAIL: {
        icon: "error",
        text: "Please associate at least one Issue with this email.",
        title: "Issues are required",
    },
    OUTBOX_PROJECT_VALIDATION_FAIL_ALERT: {
        icon: "exclamation-circle",
        iconFamily: "fas",
        title: "Issues are required",
        text: "Please associate at least one Issue with this email.",
        actions: { ok: "OK" },
    },
    PAC_RECONCILIATION_MATCHING_FAIL: {
        icon: "error",
        text: "There was an error saving these matches.",
        title: "Error!",
    },
    OUTBOX_FALLBACK_TIMER: {
        title: "Are you still there?",
        icon: "info",
        text:
            "We've detected that you haven't made any changes in a while. If " +
            "you would like to stay on this page, please click below and make sure to " +
            "save your email. Otherwise, you will be taken back to the previous page in " +
            "60 seconds.",
        button: "Stay on Page",
        closeOnClickOutside: false,
        closeOnEsc: false,
        timer: 60000,
    },
    SCHEDULE_EMAIL_WARNING: {
        icon: "warning",
        title: "Warning: A scheduled email can only be canceled or edited up to 10 minutes before the scheduled send time",
    },
    SEND_WITHOUT_SUBJECT: {
        title: "No Email Subject",
        text: "Are you sure you want to send an email without a subject?",
        info: "warning",
        buttons: {
            cancel: true,
            confirm: {
                closeModal: false,
            },
        },
    },
    MISSING_SUBJECT_ALERT: {
        title: "Missing Email Subject",
        text: "You must include a subject with your message.",
        actions: {
            ok: "OK",
        },
    },
    MISSING_SUBJECT_OR_CONTENT_ALERT: {
        title: "Missing Email Subject or Content",
        text: "The email must includes a subject and some content.",
        actions: {
            ok: "OK",
        },
    },
    TRY_AGAIN_LATER_ALERT: {
        title: "Please try again later",
        text: "Too many requests at the moment. Please try again later.",
        actions: {
            ok: "OK",
        },
    },
    VARIANT_MISSING_SUBJECT_ALERT: (badVariants) => ({
        icon: "exclamation-circle",
        iconFamily: "fas",
        iconColor: "#503ABD",
        title: `Variation${badVariants.length > 1 ? "s" : ""} Missing Email Subject`,
        text: badVariants.map((variant) => `Variation ${variant} is missing a subject line.`).join("\n"),
        actions: { ok: "OK" },
    }),
    OUTBOX_NOT_CONFIGURED_ALERT: {
        icon: "exclamation-triangle",
        iconFamily: "fas",
        title: "Sorry, Outbox isn't configured yet!",
        text: "Please get in touch with our Customer Success team or LiveChat to set up your sending domains",
        actions: {
            okay: true,
            cancel: true,
        },
    },
    OUTBOX_NOT_CONFIGURED: {
        buttons: ["Cancel", "Okay"],
        icon: "warning",
        title: "Sorry, Outbox isn't configured yet!",
        text: "Please get in touch with our Customer Success team or LiveChat to set up your sending domains",
        type: "warning",
    },
    SCHEDULED_WITHOUT_TIME: {
        icon: "exclamation-circle",
        iconFamily: "fas",
        iconColor: "#503ABD",
        title: "Invalid Scheduled Time",
        text: "You must provide both a start time and an end time.",
        actions: { ok: "OK" },
    },
    SCHEDULED_BEFORE_NOW: {
        title: "Invalid Scheduled Time",
        text: "The scheduled time you entered is before the current time.",
        icon: "error",
    },
    SCHEDULED_BEFORE_NOW_ALERT: {
        icon: "exclamation-circle",
        iconFamily: "fas",
        iconColor: "#503ABD",
        title: "Invalid Scheduled Time",
        text: "The scheduled time you entered is before the current time.",
        actions: { ok: "OK" },
    },
    SCHEDULED_START_BEFORE_NOW_ALERT: {
        icon: "exclamation-circle",
        iconFamily: "fas",
        iconColor: "#503ABD",
        title: "Invalid Start Time",
        text: "The scheduled start time you entered is before the current time.",
        actions: { ok: "OK" },
    },
    SCHEDULED_START_TOO_SOON_ALERT: {
        icon: "exclamation-circle",
        iconFamily: "fas",
        iconColor: "#503ABD",
        title: "Invalid Start Time",
        text: "The scheduled start time must be least 10 minutes from now.",
        actions: { ok: "OK" },
    },
    SCHEDULED_END_BEFORE_START_ALERT: {
        icon: "exclamation-circle",
        iconFamily: "fas",
        iconColor: "#503ABD",
        title: "Invalid End Time",
        text: "The scheduled end time you entered is before the scheduled start time.",
        actions: { ok: "OK" },
    },
    SCHEDULED_END_TOO_SOON_ALERT: {
        icon: "exclamation-circle",
        iconFamily: "fas",
        iconColor: "#503ABD",
        title: "Invalid End Time",
        text: "The scheduled end time must be at least 2 hours after the scheduled start time.",
        actions: { ok: "OK" },
    },
    REMOVE_ISSUE_AND_SUBISSUES: (qdt, issueName) => ({
        icon: "warning",
        title: "Remove from issue and subissues?",
        text: `This will remove the ${qdt.singular} from the issue "${issueName}" and from all the subissues of that issue.`,
        buttons: {
            cancel: true,
            confirm: {
                text: "Yes, remove from issues.",
            },
        },
    }),
    OUTBOX_CONFIRM_TEST_EMAIL: {
        title: "Test Email",
        text: "Where would you like this test email to be sent? Enter one or more email addresses separated by commas.",
        content: {
            element: "input",
            attributes: {
                placeholder: "Email Address...",
                value: window.Userdata.email,
            },
        },
        buttons: {
            cancel: "Cancel",
            confirm: {
                text: "Send Test",
                closeModal: false,
                value: true,
            },
        },
    },
    OUTBOX_CONFIRM_TEST_EMAIL_ALERT: {
        icon: "envelope",
        iconFamily: "fas",
        title: "Send Test Email",
        text: "Where would you like to send this test email? Enter one or more email addresses separated by commas.",
        content: {
            element: "TextInput",
            attributes: {
                label: "Test Email Recipient",
                placeholder: "Email Address...",
                value: window.Userdata.email,
                id: "emails",
                dataCy: "testEmailRecipientsInput",
            },
        },
        actions: {
            cancel: true,
            send: {
                text: "Send Test",
                value: true,
                closeAlert: false,
            },
        },
    },
    OUTBOX_SAVING_IN_PROGRESS_ALERT: {
        title: "Saving",
        text: "Just a moment...",
        closeOnClickOutside: false,
    },
    OUTBOX_SAVE_SUCCESSFUL_ALERT: {
        icon: "save",
        iconFamily: "fas",
        title: "Your message has been saved.",
        text: "Do you want to continue editing?",
        closeOnClickOutside: false,
        actions: {
            overviewPage: {
                text: "Overview Page",
                value: true,
            },
            continue: {
                text: "Continue Editing",
                value: false,
            },
        },
    },
    OUTBOX_VALIDATING_ALERT: {
        title: "Validating",
        text: "Just a moment...",
        closeOnClickOutside: false,
    },
    OUTBOUND_TEXTING_CONFIRM_TEST_NUMBER: {
        title: "Test Text Message",
        text: "Where would you like this test text message to be sent? Enter a phone number.",
        content: {
            element: "input",
            attributes: {
                placeholder: "Phone number (e.g. 1-202-780-7921)",
            },
        },
        buttons: {
            cancel: "Cancel",
            confirm: {
                text: "Send Test",
                closeModal: false,
                value: true,
            },
        },
    },
    NICKNAME_SEARCH_FAVORITE: {
        title: "Name your favorited search",
        text: "Give your favorited search a nickname to quickly refer to it later.",
        content: {
            element: "input",
            attributes: {
                placeholder: "Enter nickname...",
                type: "text",
            },
        },
        buttons: {
            cancel: "Cancel",
            confirm: {
                text: "Okay",
            },
        },
    },
    SESSION_EXPIRED: {
        title: "Session expired",
        text: "Your account has been logged out due to inactivity. Please click here to log back in.",
        icon: "info",
    },
}

export default swalContents
