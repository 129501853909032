import { WriteMemberForm } from "QuorumGrassroots/campaign-forms/components/WriteMemberForm"
import { getMessageFormWrapper } from "QuorumGrassroots/campaign-forms/wrappers/GetMessagesFormWrapper"
import { generateGrassrootsActionsWithImmutableValues } from "QuorumGrassroots/campaign-forms/helpers"

export const generateWriteAMemberActions = (messageValues, globalFormValues, props) => {
    return messageValues.reduce((acc, value) => {
        const gsaInfo = {
            subject: value.subject,
            text: [value.prebody, value.body, value.postbody].filter((text) => text).join("\n\n"),
            campaign: DjangIO.app.grassroots.campaign.models.Campaign.resourceUriFromId(props.campaign.id),
            supporter_action_type: props.campaign.campaign_type,
            message_group_id: props.messageGroups?.length ? value?.message_group_id : null,
        }

        if (!value.target.is_custom) {
            gsaInfo["person"] = DjangIO.app.person.models.Person.resourceUriFromId(value.target.id)
        } else {
            gsaInfo["custom_person"] = value.target.id
        }

        acc.push(gsaInfo)

        return acc
    }, [])
}

export default getMessageFormWrapper({
    formName: "write_member",
    generateGrassrootsActions: (messageValues, globalFormValues, props) =>
        generateGrassrootsActionsWithImmutableValues(
            generateWriteAMemberActions,
            messageValues,
            globalFormValues,
            props,
        ),
    getIdFromMessage: (message) => message.target.id.toString(),
})(WriteMemberForm)
