import { useQuery } from "@tanstack/react-query"
import { api } from "@/api"
import { Campaign } from "@/types/djangio"
import { replaceEditablePlaceholders } from "QuorumGrassroots/helperFunctions.js"

export const useMultiActionChildren = (parentId: string) =>
    useQuery<{ objects: Campaign[] }>({
        queryKey: ["multi-action", parentId],
        queryFn: fetchCampaign,
        enabled: Boolean(parentId),
        staleTime: Infinity,
        select: convertMessages,
    })

function convertMessages(data: { objects: Campaign[] }) {
    const result = data.objects.map((campaign) => {
        if (campaign.campaign_type === DjangIO.app.grassroots.campaign.types.CampaignType.write_member.value) {
            return convertWriteALetterMessages(campaign)
        }
        if (campaign.campaign_type === DjangIO.app.grassroots.campaign.types.CampaignType.comment_on_regulation.value) {
            return convertRegulationMessages(campaign)
        }
        if (campaign.campaign_type === DjangIO.app.grassroots.campaign.types.CampaignType.write_white_house.value) {
            return convertWriteWhiteHouseMessages(campaign)
        }
        return campaign
    }) as Campaign[]
    return { objects: result }
}

export const convertWriteALetterMessages = (campaign: Campaign) => {
    const messages = {}
    const targets = {}
    const existing_target_ids = {}
    for (const message of campaign.messages as WriteALetterMessages) {
        // Here we want to make sure a legislator receives only one message even if they are in multiple groups
        // We are adding it to the first group they are in - similar to GET_CAMPAIGN_MESSAGES_SUCCESS for Redux
        if (message.target.id in existing_target_ids) {
            continue
        }
        existing_target_ids[message.target.id] = true

        messages[message.group.id] = {
            subject: replaceEditablePlaceholders(message.subject),
            preBody: replaceEditablePlaceholders(message.fixed_pre_body),
            body: replaceEditablePlaceholders(message.bodies[0]),
            postBody: replaceEditablePlaceholders(message.fixed_post_body),
            advocate_editing_permission: message.group.advocate_editing_permission,
            id: message.group.id,
            name: message.group.name,
        }
        if (!targets[message.group.id]) targets[message.group.id] = []
        targets[message.group.id].push({
            imageUrl: message.target.image_url,
            label: message.target.name,
            value: message.target.id,
            targeted: true,
            ...(message.target.is_custom && { is_custom: true }),
        })
    }
    return { ...campaign, messages, targets }
}

export const convertRegulationMessages = (campaign: Campaign) => {
    const messages = {
        0: {
            preBody: replaceEditablePlaceholders(campaign.messages[0].fixed_pre_body),
            body: replaceEditablePlaceholders(campaign.messages[0].bodies[0]),
            postBody: replaceEditablePlaceholders(campaign.messages[0].fixed_post_body),
        },
    }
    return { ...campaign, messages }
}

export const convertWriteWhiteHouseMessages = (campaign: Campaign) => {
    const messages = {
        0: {
            body: replaceEditablePlaceholders(campaign.messages[0].bodies[0]),
            preBody: replaceEditablePlaceholders(campaign.messages[0].fixed_pre_body),
            postBody: replaceEditablePlaceholders(campaign.messages[0].fixed_post_body),
            subject: replaceEditablePlaceholders(campaign.messages[0].subject),
        },
    }
    return { ...campaign, messages }
}

const fetchCampaign = async ({ queryKey }) => {
    const parentId = queryKey[1]
    const response = await api.get({
        model: DjangIO.app.grassroots.campaign.models.GrassrootsCampaign,
        action: "get_multi_action_children",
        params: {
            parent_campaign_id: parentId,
            decode_enums: "false",
            dehydrate_extra: "can_participate,message_groups",
        },
    })
    return response?.json?.()
}

export const useSimpleGetCampaignMessages = (campaignId: string) =>
    useQuery({
        queryKey: ["get_campaign_messages", campaignId],
        queryFn: fetchSimpleCampaignMessages,
        enabled: true,
        staleTime: Infinity,
    })

const fetchSimpleCampaignMessages = async ({ queryKey }) => {
    const campaignId = queryKey[1]
    const response = await api.get({
        model: DjangIO.app.grassroots.campaign.models.GrassrootsCampaign,
        action: "get_campaign_messages",
        params: { campaign_id: campaignId, decode_enums: "false", count: "false" },
    })
    return response.json()
}

export const getCampaignUrlsFromId = async (campaignIds: string[], missingRawUrls: string[]) => {
    return await api
        .get({
            model: DjangIO.app.grassroots.campaign.models.GrassrootsCampaign,
            action: "get_campaign_ac_urls_from_campaign_placeholder_ids",
            params: {
                ids: campaignIds.join(","),
                urls: missingRawUrls.join(","),
                decode_enums: "false",
            },
        })
        .then((response) => response.json())
        .catch((error) => ({ error }))
}
