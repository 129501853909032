const { QuorumDataType } = DjangIO.app.models

export const searchPaths = {
    search: "/search/",
    power_search: "/powersearch/",
    livetranscripts: "/livetranscripts/",
}

const prefixes = {
    outbox: "/outbox/",
    supporterProfile: QuorumDataType.supporter.profile,
    campaign: "/campaign/",
}

const urlActionPaths = {
    urlAction: "/action/:actionType/:args",
}

export const loggerForms = {
    // prefix
    logInteractionPrefix: "/log_interaction/",
    logRelationshipPrefix: "/log_relationship/",
    logCommentPrefix: "/log_comment/",
    logDonationPrefix: "/log_donation/",
    // full - for editing
    logInteraction: "/log_interaction/:datumId?/",
    logRelationship: "/log_relationship/:datumId?/",
    logComment: "/log_comment/:datumId?/",
    logDonation: "/log_donation/:datumId?/",
}

// TODO: All of these should have consistent suffixes and get consitent props!
const issueManagementProfile = `${QuorumDataType.issue_management.profile}:profileId`
const amendmentProfile = `${QuorumDataType.amendment.profile}:profileId`
const articleProfile = `${QuorumDataType.media.profile}:profileId`
const billProfile = `${QuorumDataType.bill.profile}:profileId`
const bulkEmailProfile = `${QuorumDataType.bulk_email.profile}:profileId`
const bulkSMSProfile = `${QuorumDataType.bulk_sms.profile}:profileId`
const campaignProfile = `${QuorumDataType.campaign.profile}:profileId`
const caucusProfile = `${QuorumDataType.caucus.profile}:profileId`
const committeeProfile = `${QuorumDataType.committee.profile}:profileId`
const documentProfile = `${QuorumDataType.document.profile}:profileId`
const politicalCommitteeProfile = `${QuorumDataType.political_committee.profile}:profileId`
const oldIssueProfile = `${QuorumDataType.issue.profile}:profileId`
const publicOrganizationProfile = `${QuorumDataType.public_organization.profile}:profileId`
const officialProfile = `${QuorumDataType.person.profile}:profileId`
const pressContactProfile = `${QuorumDataType.press_contact.profile}:profileId`
const stafferProfile = `${QuorumDataType.staffer.profile}:profileId`
const contactProfile = `${QuorumDataType.supporter.profile}:profileId`
const voteProfile = `${QuorumDataType.vote.profile}:profileId`
const eventProfile = `${QuorumDataType.event.profile}:profileId`
const noteProfile = `${QuorumDataType.note.profile}:profileId`
const grassrootsActionProfile = `${QuorumDataType.grassroots_action.profile}:profileId`
const fileProfile = `${QuorumDataType.attachment.profile}:profileId`
const regProfile = `${QuorumDataType.regulation.profile}:profileId`
const docketProfile = `${QuorumDataType.docket.profile}:profileId`
const customEventProfile = `${QuorumDataType.custom_event.profile}:profileId`
const trackingDashboardProfile = `${QuorumDataType.tracking_dashboard.profile}:profileId/:boardSlug?`
const trackingDashboardPrimaryIssueProfile = `${QuorumDataType.tracking_dashboard.profile}:profileId/:boardSlug/:parentIssue`
const trackingDashboardNoBoardSlug = `${QuorumDataType.tracking_dashboard.profile}:profileId`
const geoshapeProfile = `${QuorumDataType.geoshape.profile}:profileId`

export const profilePaths = {
    amendmentProfile,
    articleProfile,
    billProfile,
    bulkEmailProfile,
    bulkSMSProfile,
    campaignProfile,
    caucusProfile,
    committeeProfile,
    documentProfile,
    politicalCommitteeProfile,
    oldIssueProfile,
    issueManagementProfile,
    publicOrganizationProfile,
    officialProfile,
    pressContactProfile,
    stafferProfile,
    contactProfile,
    voteProfile,
    eventProfile,
    noteProfile,
    grassrootsActionProfile,
    fileProfile,
    regProfile,
    docketProfile,
    customEventProfile,
    geoshapeProfile,
    trackingDashboardNoBoardSlug,
    trackingDashboardProfile,
    trackingDashboardPrimaryIssueProfile,
    overview: "/overview/",
    committees: "/committees/",
    caucuses: "/caucuses/",
    amendments: "/amendments/",
    staffers: "/staffers/",
    eu_staffers: "/eu_staffers/",
    votes: "/votes/",
    bills: "/bills/",
    documents: "/documents/",
    political_committees: "/political_committees/",
    transactions: "/transactions/",
    disbursements: "/disbursements/",
    receipts: "/receipts/",
    eu_documents: "/eu_documents/",
    articles: "/articles/",
    more_like_this_article: "/more_like_this/",
    other_syndicated_articles: "/syndication/",
    notes: "/notes/",
    contacts: "/contacts/",
    grassroots_actions: "/grassroots_actions/",
    events: "/events/",
    relationships: "/relationships/",
    participants: "/participants/",
    press: "/press/",
    actions: "/actions/",
    officials: "/officials/",
    regulations: "/regulations/",
    custom_event_participant: "/custom_events/",
    campaigns: "/campaigns/",
    intl_contacts: "/i_contacts/",
    transcript: "/transcript/",
    geoshape_map: "/geoshape_map/",
    affiliated_committees: "/affiliated_committees/",
    joint_fundraiser_participants: "/joint_fundraiser_participants/",
    joint_fundraiser_representatives: "/joint_fundraiser_representatives/",
}

// routes specific to only officialProfiles
export const officialPaths = {
    issues: "/issue_management/",
    issueStats: "/issue_stats/",
    district: "/district/",
    stats: "/stats/",
    organizations: "/organizations/",
    organizationsRepresenting: "/organizations_representing/",
    history: "/history/",
    disbursements: "/disbursements/",
    receipts: "/receipts/",
}

export const pressContactPaths = {
    notes: "/notes/",
    documents: "/documents/",
    outlets: "/outlets/",
    press: "/press/",
}

// routes specific to only billProfiles (used in the tabSelector)
export const billPaths = {
    billText: "/text/",
    billTextWithSection: "/text/:section_id?",
    relatedBills: "/relatedBills/",
    sponsors: "/sponsors/",
}

export const contactPaths = {
    representatives: "/representatives/",
}

export const organizationPaths = {
    eu_officials: "/eu_officials/",
    subsidiary_organizations: "/subsidiary_organizations/",
    legislation: "/legislation/",
    org_officials: "/org_officials/",
    org_staffers: "/org_staffers/",
    custom_officials: "/custom_officials/",
    official_documents: "/official_documents/",
    webpages: "/webpages/",
    articles: "/articles/",
    nomination_bills: "/nomination_bills/",
}

export const campaignPaths = {
    visitors: "/visitors/",
    bills: "/bills/",
    regulations: "/regulations/",
}

export const projectPaths = {
    subprojects: "/subprojects/",
    uploads: "/uploads/",
    lists: "/lists/",
}

export const votePaths = {
    yea_votes: "/yea_votes/",
    nay_votes: "/nay_votes/",
    abstentions: "/abstentions/",
    absences: "/absences/",
}

export const bulkEmailPaths = {
    recipients: "/recipients/",
    links: "/links/",
    replies: "/replies/",
    replies_truncated: "/replies_truncated/",
}

export const committeePaths = {
    schedule: "/schedule/",
    subcommittees: "/subcommittees/",
}

export const regPaths = {
    relatedRegulation: "/relatedRegulation/",
    agencies: "/agencies/",
    comments: "/comments/",
    dockets: "/dockets/",
}

export const issueManagementPaths = {
    createIssue: "/issue_management/new/",
    officials: "/officials/",
    officialsAndStaff: "/officials_and_staff/",
    editIssue: "/issue_management/:issueId/edit/",
    issueProfile: "/issue_management/:issueId/",
    children: "/subissues/",
    files: "/files/",
    grassroots_actions: "/grassroots_actions/",
    contacts: "/contacts/",
    staffers: "/staffers",
    public_organizations: "/public_organizations/",
    votes: "/votes/",
    articles: "/articles/",
}

export const trackingDashboardPaths = {
    createTrackingDashboard: "/tracking_board/new/",
    editTrackingDashboard: `${trackingDashboardNoBoardSlug}/edit/`, // includes slash
    trackingDashboards: "/tracking_boards/",
}

export const docketPaths = {
    relatedDockets: "/related_dockets/",
    comments: "/comments/",
    agencies: "/agencies/",
}
// eslint-disable-next-line
export default {
    home: "/home/",
    permissionDenied: "/permission_denied/",
    ...searchPaths,
    ...profilePaths,
    ...prefixes,
    ...officialPaths,
    ...pressContactPaths,
    ...billPaths,
    ...contactPaths,
    ...organizationPaths,
    ...campaignPaths,
    ...projectPaths,
    ...votePaths,
    ...bulkEmailPaths,
    ...committeePaths,
    ...regPaths,
    ...urlActionPaths,
    ...loggerForms,
    ...issueManagementPaths,
    ...trackingDashboardPaths,
    ...docketPaths,
}
