import { actionTypes } from "QuorumGrassroots/framework/action-types"
import { fromJS, Map } from "immutable"

const ContextFields = DjangIO.app.grassroots.types.GrassrootsContextField

const gamificationInitialState = (supporterPoints) => ({
    supporterPoints,
    pointsEarned: 0,
    tierModalShown: false,
})

export const getInitialState = (kwargs = {}) => {
    const initialState = fromJS({
        actionCenterSettings: window.action_center_settings,
        campaign: window.campaign,
        organization: window.organization,
        pages: window.pages,
        indexRoute: window.index_route,
        organizationDesign: window.organization_design,
        userdata: window.userdata,
        loading: {},
        permissions: window.permissions,
        ssoUrl: window.sso_url,
        cookielessSafari: window.cookielessSafari,
        gamification: gamificationInitialState(window.supporter_points),
        oneClickRegistrationCampaignId: null,
        campaignListMeta: {
            loaded: false,
            loading: false,
        },
        pageRegion: {},
        pacUserdata: window.Userdata,
    })

    // get the fields that are optionally defined on the context.
    // If they are undefined (aka they weren't passed via context),
    // then they'll get populated in preloadData in framework/index.
    const optionalFields = ContextFields.items().reduce(
        (acc, field) => acc.set(field.store_key, fromJS(window[field.store_key])),
        Map(),
    )

    return initialState.merge(optionalFields).mergeDeep(kwargs)
}

const initialState = getInitialState()

const handlers = {
    [actionTypes.LOGOUT_USER_SUCCESS](state, action) {
        return (
            state
                .merge(fromJS(action.response.data))
                .set("gamification", fromJS(gamificationInitialState(0)))
                // After logout, campaign pagination resets to fetch all available campaigns
                .setIn(["campaignListMeta", "loaded"], false)
        )
    },

    [actionTypes.LOGIN_USER_SUCCESS](state, action) {
        // Add the userdata to the window
        window.userdata = action.response.data.userdata
        return (
            state
                .merge(fromJS(action.response.data))
                // After login, campaign pagination resets to fetch all available campaigns
                .setIn(["campaignListMeta", "loaded"], false)
        )
    },

    [actionTypes.CLEAR_USERDATA](state) {
        window.userdata = {}
        return state.set("userdata", fromJS({}))
    },

    [actionTypes.LOAD_FRAMEWORK_START](state, action) {
        return state.setIn(["loading", action.fieldKey], true)
    },

    [actionTypes.LOAD_FRAMEWORK_SUCCESS](state, action) {
        const data = action.isDetail ? action.response.data : action.response.data.objects
        return state.set(action.fieldKey, fromJS(data)).setIn(["loading", action.fieldKey], false)
    },

    [actionTypes.LOAD_FRAMEWORK_FAIL](state, action) {
        return state.setIn(["loading", action.fieldKey], false)
    },

    [actionTypes.UPDATE_USERDATA_SLICE](state, action) {
        return state.update("userdata", (userdata) => userdata.merge(action.updatedSlice))
    },

    [actionTypes.UPDATE_PAC_USERDATA_SLICE](state, action) {
        return state.update("pacUserdata", (data) => data.merge(action.updatedSlice))
    },

    [actionTypes.UPDATE_PAGES_SLICE](state, action) {
        return state.set("pages", fromJS(action.updatedPages))
    },

    [actionTypes.UPDATE_SUPPORTER_POINTS](state, action) {
        const currentPoints = state.getIn(["gamification", "supporterPoints"], 0)

        return state.setIn(["gamification", "supporterPoints"], currentPoints + action.points)
    },

    [actionTypes.UPDATE_POINTS_EARNED](state, action) {
        return state.setIn(["gamification", "pointsEarned"], action.points)
    },

    [actionTypes.SHOW_GAMIFICATION_TIER_MODAL](state) {
        return state.setIn(["gamification", "tierModalShown"], true)
    },

    [actionTypes.HIDE_GAMIFICATION_TIER_MODAL](state) {
        return state.setIn(["gamification", "tierModalShown"], false)
    },

    [actionTypes.ENABLE_ONE_CLICK_REGISTRATION](state, action) {
        return state.set("oneClickRegistrationCampaignId", action.campaignId)
    },

    [actionTypes.DISABLE_ONE_CLICK_REGISTRATION](state) {
        return state.set("oneClickRegistrationCampaignId", null)
    },

    [actionTypes.LOAD_ADDITIONAL_CAMPAIGNS_START](state) {
        return state.setIn(["campaignListMeta", "loading"], true)
    },

    [actionTypes.STORE_ONE_CLICK_EDITS](state, action) {
        return state.set("oneClickEditedMessages", action.oneClickEditedMessages)
    },

    [actionTypes.LOAD_ADDITIONAL_CAMPAIGNS_SUCCESS](state, action) {
        const additionalCampaigns = action.response.data.objects
        const allCampaignsLoaded = additionalCampaigns.length < ContextFields.campaign_list.pagination_amount

        return state
            .set("campaignList", state.get("campaignList").concat(fromJS(additionalCampaigns)))
            .setIn(["campaignListMeta", "loading"], false)
            .setIn(["campaignListMeta", "loaded"], allCampaignsLoaded)
    },

    [actionTypes.LOAD_ADDITIONAL_CAMPAIGNS_FAIL](state) {
        return state.setIn(["campaignListMeta", "loading"], false)
    },

    [actionTypes.LOAD_PAGE_REGION_FILTERS_START](state, action) {
        return state.setIn(["pageRegion", action.regionKey, "loading"], true)
    },

    [actionTypes.LOAD_PAGE_REGION_FILTERS_SUCCESS](state, action) {
        const regionFilters = action.response.data

        return state
            .setIn(["pageRegion", action.regionKey, "loading"], false)
            .setIn(["pageRegion", action.regionKey, "regionFilters"], fromJS(regionFilters))
    },

    [actionTypes.LOAD_PAGE_REGION_FILTERS_FAIL](state, action) {
        return state.setIn(["pageRegion", action.regionKey, "loading"], false)
    },

    [actionTypes.UPDATE_SIGN_UP_REDIRECT_URL](state, action) {
        const pageIndex = state.get("registrationPages").findIndex((page) => page.get("id") === action.payload.id)

        if (pageIndex !== -1) {
            return state.setIn(["registrationPages", pageIndex.toString(), "redirect_url"], action.payload.redirectUrl)
        }

        return state
    },
}

export default function frameworkReducer(state = initialState, action) {
    const returnVal = handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action) : state

    return returnVal
}
