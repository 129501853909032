import { UnstyledButton } from "@mantine/core"
import styled from "styled-components"

import { socialButtonIconColor, StyledErrorButton } from "QuorumGrassroots/widgets/styled-components/styles"
import { StyledCurrentTierIcon } from "QuorumGrassroots/widgets/GamificationStats/components/style"
import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"
import {
    getFontColorForBackgroundContrast,
    styleInMobileScreenSize,
} from "QuorumGrassroots/styled-components/helperFunctions"
import { constants } from "QuorumGrassroots/constants"

export const StyledIconWrapper = styleWithOrgDesign(styled.div`
    text-align: center;

    .thanks-icon {
        font-size: 10em;
        color: ${(props) => props.organizationDesign.primary_color};
    }
`)

export const StyledCurrentTierThanksIcon = styled(StyledCurrentTierIcon)`
    height: 160px;
    width: 140px;
    margin-right: auto;
    margin-left: auto;
`

export const StyledThankYouText = styled.div`
    text-align: center;
    font-size: 1.5em;
    margin-top: 10px;
    margin-bottom: 20px;
    ${styleInMobileScreenSize(`
        text-align: left;
    `)}
`

export const StyledPointsEarnedDisplay = styleWithOrgDesign(styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    background-color: ${(props) => props.organizationDesign.background_color};
    margin-left: -15px;
    margin-bottom: 30px;
    width: calc(100% + 30px);
    font-size: 24px;

    i {
        color: ${(props) => props.organizationDesign.secondary_color};
        font-size: 46px;
        margin-right: 15px;
    }
`)

export const StyledBackButton = styled(StyledErrorButton)`
    margin-bottom: 5px;

    i {
        width: 30px;
    }
`

export const StyledSocialButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    flex-wrap: wrap;
`

export const NewStyledSocialButton = styled(UnstyledButton)`
    display: flex;
    height: 40px;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    flex: 1 0 0;

    border-radius: 4px;
    border: 1px solid var(--gray-3, #dee2e6);
    background: var(--surface-white, #fff);

    i {
        ${(props) => socialButtonIconColor(props)}
    }
`

export const StyledThankWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    flex: 1 0 0;
    .button-details {
        width: 100%;
    }
`

export const StyledSocialButtonText = styled.span`
    color: var(--gray-7, #495057);
    font-feature-settings:
        "clig" off,
        "liga" off;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 100% */
`

export const StyledGamificationSection = styleWithOrgDesign(styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;

    i {
        width: 26px;
        height: 26px;
        font-size: 26px;
        color: ${(props) =>
            getFontColorForBackgroundContrast(
                props.organizationDesign.primary_color,
                "#000000",
                constants.colors.green[5],
            )};
    }
    ${styleInMobileScreenSize(`
        align-items: baseline;
        h4 {
            align-items: left;
            text-align: left;
        }
    `)}
`)

export const StyledGamificationDescription = styleWithOrgDesign(styled.h4`
    p,
    p {
        margin: 0;
    }
    margin: 0;
    align-self: stretch;

    color: ${(props) =>
        getFontColorForBackgroundContrast(
            props.organizationDesign.primary_color,
            "#000000",
            "var(--surface-white, #ffffff)",
        )};
    text-align: center;
    font-feature-settings:
        "clig" off,
        "liga" off;

    /* Heading/H4/Regular */
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%; /* 1.63125rem */
`)

export const StyledGamificationPointSection = styled.div`
    display: flex;
    padding: 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    align-self: stretch;

    border-radius: 4px;
    border: 1px solid var(--green-5, #2acb47);
    background: var(--green-0, #f0fdf2);
`

export const StyledGamificationPointHeading = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    i {
        display: flex;
        width: 48px;
        height: 48px;
        padding: 8px;
        justify-content: center;
        align-items: center;
        gap: 8px;

        border-radius: 24px;
        background: var(--green-5, #2acb47);
        color: var(--green-1, #ddfbe1);
    }
`

export const StyledGamificationPointDescription = styled.h4`
    margin: 0;
    color: var(--gray-9, #212529);
    text-align: center;
    font-feature-settings:
        "clig" off,
        "liga" off;

    /* Heading/H4/Medium */
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 145%; /* 1.63125rem */
`
