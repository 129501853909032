import { StoryForm } from "QuorumGrassroots/campaign-forms/components/StoryForm"
import { reduxForm } from "redux-form/immutable"
import { postGrassrootsSupporterAction } from "QuorumGrassroots/campaign-forms/action-creators"

const submit = (values, dispatch, props) => {
    return dispatch(
        postGrassrootsSupporterAction({
            uniqueWidgetId: props.uniqueWidgetId,
            campaign: props.campaign,
            payload: {
                text: values.get("text"),
            },
            shouldCompleteCampaign: true,
        }),
    )
}

export default reduxForm({
    form: "share_story",
    onSubmit: submit,
})(StoryForm)
