import swal from "sweetalert"
import swalContents from "frontend/swalConfigs"
import { getNestedObjectKey } from "shared/imports/sharedHelperFunctions"
import clearCookiesAndReload from "frontend/imports/clearCookiesAndReload"
import { USER_ANALYTICS_MAX_DESCRIPTION_LENGTH } from "shared/imports/sharedConstants"
import { sendUserActionPost } from "shared/user-actions/user-actions-action-creators"
const IGNORE_ERROR_CODES = [403, 418, 422]

/**
 * Determine if a Sentry error should be raised
 * @name shouldThrowRavenError
 * @function
 * @param {Object} data - error data
 * @returns {Boolean} - Whether we should throw a Sentry error
 */
export const shouldThrowRavenError = (data) => {
    // guard against undefined statuses
    if (getNestedObjectKey(data, ["response", "status"]) && IGNORE_ERROR_CODES.includes(data.response.status)) {
        return false
    }
    return true
}

export const showSwal = async (swalConfiguration = swalContents.defaultError) => {
    // this is the default behavior of BACKENDERROR
    if (swalConfiguration.text === swalContents.defaultError.text) {
        const isConfirm = await swal({
            closeOnClickOutside: true,
            ...swalConfiguration,
        })
        if (isConfirm) {
            // TODO: do we need to clear cookies just to reload the page?
            clearCookiesAndReload()
        }

        // otherwise, the user has overridden the swal behavior
    } else {
        const resolveValue = await swal({
            closeOnClickOutside: true,
            ...swalConfiguration,
        })
        return resolveValue
    }
}

export const showQueryIssue = async (queryValData) => {
    const { type, index, endindex, query, issue, bad_example, good_example } = queryValData
    // index can be one past the length of the query.
    // If so, indicate that the issue is at the end of the query, otherwise bold+color the character in question
    let displayQuery = ""
    if (index && query && index < query.length) {
        displayQuery =
            `${query.substring(0, index)}<span style="color:#f00">${query.substring(index, endindex)}` +
            `</span>${query.substring(endindex, query.length)}`
    } else {
        displayQuery = query
    }

    const messageStart = displayQuery
        ? `${issue}<br /><br /><i>Your Search: </i><strong>${displayQuery}</strong>`
        : issue

    const displayHTML = document.createElement("p")
    if (type === "error") {
        // displayHTML.innerHTML = `Your query <strong>${displayQuery}</strong> has the following ${displayIssueType}: ${issue}`
        displayHTML.innerHTML =
            `${messageStart}<br /><br /><i>Example That Will Work</i>: ${good_example}<br />` +
            ` <i>Example That Won't Work</i>: ${bad_example}`
        swal({
            icon: "error",
            title: "Incorrect Query Syntax",
            content: displayHTML,
        })
    } else if (type === "warning") {
        // displayHTML.innerHTML = `Your query <strong>${displayQuery}</strong> may have the following ${displayIssueType}:
        // ${issue} If this is intended, ignore this warning!`
        displayHTML.innerHTML = `${messageStart}<br /><br /> If this is intended, ignore this warning!`
        swal({
            icon: "warning",
            title: "Query Warning",
            content: displayHTML,
        })
    } else if (type === "duplicate") {
        displayHTML.innerHTML = messageStart || "<strong>Duplicate request!</strong>"
        swal({
            icon: "warning",
            title: "Query Duplicate Warning",
            content: displayHTML,
        })
    }
}

// log to our user analytics that an Error swal was shown
// this function should not block or be try/caught
// Set UserAction's description to the error's status text if defined and below max character limit
// Otherwise, set description the page pathname
export const userActionLog = (data) => {
    const statusText = getNestedObjectKey(data, ["response", "statusText"])
    const description =
        statusText && statusText.length < USER_ANALYTICS_MAX_DESCRIPTION_LENGTH ? statusText : window.location.pathname

    sendUserActionPost({
        userActionType: DjangIO.app.useractions.types.UserActionType.backend_error,
        description,
    })
}
