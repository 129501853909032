// shared constants for both web and mobile
// errors and warning text
export const constants = {
    SEARCHIFY_LIMIT: 1500,
    DEFAULT_DURATION: 30,

    // errors

    minimumParticipantsError: "An interaction needs to contain at least 2 people.",
    includedParticipantsError: "An interaction must contain a team member, organization, or a contact.",
    donationAmountEmptyError: "A donation must have an amount.",
    tooFewPeopleInRelationshipError: "You must select at least two participants to make a relationship.",
    noOrganizationInBoardRelationshipError: "You must select an organization for this relationship type.",
    // samePeopleInRelationshipError: 'Person From and Persons To should be different.', // todo once directionality is added
    datetimeInvalid: "Date invalid.",
    datetimeTypedInvalid: "Date typed in is invalid.",
    datetimeHasNoAMPMWarning: "Date invalid; be sure to include AM or PM.",

    // warnings

    warnNoItemsInComment: "No items have been selected.",
    warnNoTextInComment: "A comment needs text",
    donationAmountIsNegative: "The donation amount is negative.",

    // helper text

    // Step 1: Details
    INTERACTION_DETAILS_EXPLAINER:
        "You can use this form to log an interaction between individuals or organizations. For example, you may want to tell your team that you met with three other people and talked about an important issue. Once created, you'll be able to view these interactions under the Notes tab in search or on profiles and you can use Sheets to keep track of them",
    DONATION_DETAILS_EXPLAINER:
        "You can use this form to record a donation from an individual or organization to another individual or organization. Once created, you'll be able to see your Donations under the Notes tab in search or on profiles and can use Sheets to keep track of how much or to whom different individuals or organizations have donated. ",
    RELATIONSHIP_DETAILS_EXPLAINER:
        "You can use this form to create a relationship between multiple individuals or organizations. For example, you may want to record that several people went to college together or once sat on the same corporate board. Once created, you'll be able to view these relationships under the Notes tab in search or on profiles and you can use Sheets to keep track of them.",
    COMMENT_DETAILS_EXPLAINER:
        "You can use this form to create a comment on most of the different types of data in Quorum. For example, you might want to give your team an update on one of your projects or on how your work on a particular piece of legislation is going. Once created, you'll be able to view all of your team's comments in the Notes tab in search or on individual profiles.",
    // types
    INTERACTION_DETAILS_TYPE_TOOLTIP:
        "Choose what type of interaction you had with the participating individuals or organizations.",
    DONATION_DETAILS_TYPE_TOOLTIP:
        "Choose what type of donation took place. Did the individual inform you that they intend to donate, or did the donation actually occur?",
    RELATIONSHIP_DETAILS_TYPE_TOOLTIP:
        "Choose the type of relationship that the participating individuals and organizations had. Every organization has their own list of relationship types, and if you'd like to change yours you can reach out to your CSM.",
    RELATIONSHIP_STATUS_TOOLTIP:
        "Does this relationship currently exist, or is it no longer the case? Perhaps the partcipating individuals once worked together but no longer do.",
    COMMENT_DETAILS_TYPE_TOOLTIP:
        "Which of Quorum's data types do you want to comment upon? You can only comment on one data type at a time. Heads up - once you've chosen your items, changing the item type will clear your selection.",
    // general and tooltips that only match one form
    DETAILS_WHEN_TOOLTIP: "Choose when the interaction took place.",
    DETAILS_DURATION_TOOLTIP: "How long was the interaction?",
    DETAILS_AMOUNT_TOOLTIP: "What was the donation amount? ",
    DETAILS_CHECK_NUMBER_TOOLTIP: "You can optionally record the check number used for this donation.",
    COMMENT_ITEMS_TOOLTIP:
        "Choose which individual items you'd like to comment on. You can use Power Search to select them by clicking the magnifying glass.",
    PARTICIPANTS_EXPLAINER:
        "Choose which individuals or organizations took part in this interaction. You can type in participants one at a time, or you can use the magnifying glass on the right to enter Power Search and select many participants at once.",
    OFFICIALS_TOOLTIP:
        "Choose the officials involved in this interaction. You can leave more information about each participant in the Notes section below.",
    STAFFERS_TOOLTIP: "Choose the staffers involved in this interaction.",
    PRESS_CONTACTS_TOOLTIP: "Choose the press contacts involved in this interaction.",
    CONTACTS_TOOLTIP: "Choose the contacts involved in this interaction.",
    USERS_TOOLTIP: "Choose the team members involved in this interaction.",
    ORGS_TOOLTIP: "Choose the organizations involved in this interaction.",
    BILLS_TOOLTIP:
        "If this interaction involved particular piece(s) of legislation, you can add them here so that this interaction will also show up on that particular legislation's profile.",
    INTERACTION_ISSUES_TOOLTIP: "Choose which of your Issues are related to this interaction.",
    DONATION_ISSUES_TOOLTIP: "Choose which of your Issues are related to this donation.",
    RELATIONSHIP_ISSUES_TOOLTIP: "Choose which of your Issues are related to this relationship.",
    COMMENT_ISSUES_TOOLTIP: "Choose which of your Issues are related to this comment.",
    CUSTOM_FIELDS_EXPLAINER_TEXT:
        'Use this step to set your custom fields for this interaction. A custom field is an attribute about an {interaction/comment/donation etc.} that your organization would like to keep track of -- for example, "Positivity of Meeting" or "Decisonmaker Attended". You can set values for custom fields that have already been created or you can click the "Manage Custom Fields" button to create a new custom field and its corresponding values.',
    CUSTOM_FIELDS_TOOLTIP: "Use this step to set your custom fields for this interaction.",
    TEAMS_EXPLAINER_TEXT: "Use this step to choose which of your teams can access this note.",
    TEAMS_TOOLTIP: "Choose which teams should be able to access this note.",
    INTERACTION_ATTACHMENTS_TOOLTIP:
        "You can optionally upload file(s) related to this note and attach them here. If your file is a Word document or PDF, we'll keep track of the text so that you can search for it later.",
    DONATION_ATTACHMENTS_TOOLTIP:
        "You can optionally upload file(s) to attach to this donation. If your file is a Word document or PDF, we'll keep track of the text so that you can search for it later.",
    RELATIONSHIP_ATTACHMENTS_TOOLTIP:
        "You can optionally upload file(s) to attach to this relationship. If your file is a Word document or PDF, we'll keep track of the text so that you can search for it later.",
    COMMENT_ATTACHMENTS_TOOLTIP:
        "You can optionally upload file(s) to attach to this comment. If your file is a Word document or PDF, we'll keep track of the text so that you can search for it later.",
    // For use with DOMPurify, https://www.npmjs.com/package/dompurify.
    // Allowed tags and attributes are based on what's available in the log a
    // Comment form's Mantine/Tiptap rich text editor (in Notes step) and @ mention
    // parsing with <span> tags.
    WHITELIST_HTML_ATTRIBUTES_FOR_COMMENT_TEXT: {
        ALLOWED_TAGS: [
            "#text",
            "a",
            "b",
            "br",
            "code",
            "col",
            "div",
            "em",
            "h1",
            "h2",
            "h3",
            "h4",
            "h5",
            "h6",
            "hr",
            "i",
            "li",
            "ol",
            "p",
            "pre",
            "q",
            "s",
            "small",
            "span",
            "strong",
            "sub",
            "sup",
            "u",
            "ul",
        ],
        ALLOWED_ATTR: ["class", "classname", "href", "rel", "style", "target", "title"],
        KEEP_CONTENT: true,
    },
}

export default constants
