/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*
 * Quorum Objects Tag
 *   - this is the tag representation of a quorum obj
 *   - it can be 1 of 4 types
 *   - it requires the below fields
 */

import Tooltip from "frontend/components/Tooltip"
import PropTypes from "prop-types"
import React from "react"

const propTypes = {
    objData: PropTypes.shape({
        text: PropTypes.string,
        styling: PropTypes.string,
        position: PropTypes.number,
        default: PropTypes.bool,
    }).isRequired,
    locked: PropTypes.bool,
    onClickObj: PropTypes.func.isRequired,
    onObjDelete: PropTypes.func.isRequired,
    additionalIcon: PropTypes.object, // returns a JSX element, typically an icon
}
const defaultProps = { locked: false, additionalIcon: null }

const QuorumObjectTag = (props) => {
    // determine if you want the delete tag to be visable
    let deleteTag = null
    if (props.locked) {
        deleteTag = (
            <Tooltip label="This item cannot be removed">
                <i className="keep-tag fa fa-lock fa-lg" />
            </Tooltip>
        )
    } else if (!("objData" in props) || !props.objData.default) {
        const { onObjDelete } = props
        deleteTag = (
            <>
                <i className="remove-tag fa fa-minus-circle fa-lg" onClick={() => onObjDelete(props.objData)} />
                <span className="remove-tag-new-theme">×</span>
            </>
        )
    }
    return (
        <li
            className={`quorum-tag ${props.objData.styling} ${props.objData.default ? "default" : ""}`}
            style={{ order: props.objData.position }}
        >
            <a className="quorum-tag-button">
                <div
                    className={`quorum-tag-text-container ${props.clickable && "clickable"}`}
                    onClick={() => props.onClickObj(props.objData)}
                >
                    <div
                        className={`quorum-tag-text${props.additionalIcon ? " has-additional-icon" : ""}${
                            props.clickable ? " has-caret" : ""
                        }`}
                        dangerouslySetInnerHTML={{ __html: props.objData.text }}
                    />
                </div>
                <div className="tag">
                    {props.clickable && <i className="additional-tag fa fa-angle-right" />}
                    {props.additionalIcon}
                    {deleteTag}
                </div>
            </a>
        </li>
    )
}

QuorumObjectTag.propTypes = propTypes
QuorumObjectTag.defaultProps = defaultProps
export default QuorumObjectTag
