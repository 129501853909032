import React from "react"
import { TransparentOverlay, OverlayLink } from "QuorumGrassroots/framework/components/CookielessSafariOverlay/style"

export const CookielessSafariOverlay = (props) => {
    return (
        <TransparentOverlay>
            <OverlayLink href={window.location.href} target="_blank">
                Click here to take action
            </OverlayLink>
        </TransparentOverlay>
    )
}

export default CookielessSafariOverlay
