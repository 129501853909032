import { createSelector, createStructuredSelector } from "reselect"
import Immutable from "immutable"

// helpers
import { createToJsSelector } from "shared/imports/sharedHelperFunctions"
import { isFeatureEnabled } from "shared/featureflags/helperFunctions"

export const userdataState = (state) => state.userdata

// it useful to provide a default value here because some selectors need an empty array as their
// default value
export const makeUserdataSelector = (key, defaultValue = undefined) =>
    createSelector(userdataState, (userdata) => userdata && userdata.get(key, defaultValue))

export const makeUserdataLoadingStateSelector = (key) =>
    createSelector(makeUserdataSelector("loadingState"), (loadingStateSlice) => loadingStateSlice.get(key))

// get the id of the logged in user
export const selectUserId = makeUserdataSelector("id")

// get the URI of the logged in user
export const selectUserUri = createSelector(
    selectUserId,
    (userId) => userId && DjangIO.app.userdata.models.QuorumUser.resourceUriFromId(userId),
)

// select the teams the user is on
export const selectTeams = makeUserdataSelector("teams", [])

// devteam mode can be activated by typing "d e v t e a m" in some places
export const selectIsDevTeamMode = makeUserdataSelector("isDevTeamMode", false)

// selector that encapsulates both if the limited to teams feature flag is enabled
// and whether the user is on teams or the organization has any teams
export const selectIsLimitedToTeamsEnabled = createSelector(selectTeams, (teams) => Boolean(teams.size))

export const selectTeamsForComponent = createToJsSelector(selectTeams)

export const selectIsTeamsLoading = makeUserdataLoadingStateSelector("loadingTeams")

export const selectOrganizationPartners = makeUserdataSelector("partner_uris", [])

export const selectDefaultLimitedToTeams = makeUserdataSelector("default_limited_to_teams", [])

export const selectIsQuorumEmployee = makeUserdataSelector("is_quorum_employee", false)

export const selectUserRole = makeUserdataSelector("user_role", DjangIO.app.userdata.types.UserRole.user.value)

export const selectIsOrgAdmin = makeUserdataSelector("is_admin", false)

export const selectIsLoggedInAsUser = makeUserdataSelector("logged_in_as_user", false)

export const selectIsDemoAccount = makeUserdataSelector("is_demo_account", false)

export const selectName = makeUserdataSelector("name")

export const selectUsername = makeUserdataSelector("username")

export const selectEmailDomain = createSelector(makeUserdataSelector("email"), (email) => email.split("@")[1])

export const selectOutboxDomains = makeUserdataSelector("outbox_domains")
export const selectOUtboxDomainsForComponent = createToJsSelector(selectOutboxDomains)
export const selectSingleOutboxDomain = createSelector(
    selectOUtboxDomainsForComponent,
    (domains) => domains.length === 1,
)

export const selectOrganizationName = makeUserdataSelector("organization")

export const selectShouldDisplayInteractionForm = makeUserdataSelector("should_display_interaction_form")
export const selectShouldDisplayRecordDonationForm = createSelector(
    makeUserdataSelector("should_display_record_donation_form"),
    (shouldDisplayRecordDonationForm) => !isFeatureEnabled("ff_pac") && shouldDisplayRecordDonationForm,
)
export const selectShouldDisplayRelationshipForm = makeUserdataSelector("should_display_relationship_form")

export const selectShouldLimitEditingNotesToAdminsAndCreators = makeUserdataSelector(
    "should_limit_editing_notes_to_admins_and_creators",
)

export const selectPermissions = makeUserdataSelector("Permissions")

export const selectOrgHasMediaMonitoring = createSelector(
    selectPermissions,
    (permissions) => permissions && permissions.get("org_media_monitoring"),
)

export const selectOrgHasMediaRelations = createSelector(
    selectPermissions,
    (permissions) => permissions && permissions.get("org_media_relations"),
)
export const selectOrgHasPac = createSelector(
    selectPermissions,
    (permissions) => permissions && permissions.get("qp_pac"),
)

export const selectIsCommsOnlyUser = createSelector(
    selectPermissions,
    (permissions) => permissions && permissions.get("is_comms_only"),
)

export const selectPersona = makeUserdataSelector("persona")

export const selectTeamMembers = makeUserdataSelector("teamMembers")

export const selectTeamMembersForComponent = createToJsSelector(selectTeamMembers)

export const selectTeamMembersMentionTag = createSelector(selectTeamMembersForComponent, (teamMembers) =>
    teamMembers
        ? teamMembers.reduce(
              (acc, member) => ({
                  ...acc,
                  ["@" + member.name.replace(/ /g, "")]: `[@${member.username}|||${member.name}]`,
              }),
              {},
          )
        : {},
)

const selectTeamMembersForPicker = createSelector(selectTeamMembersForComponent, (teamMembers) =>
    teamMembers
        ? teamMembers.map((member) => ({ filterOn: member.username + member.email + member.name, ...member }))
        : [],
)

export const selectShouldShowFeatureFlagButton = createSelector(
    selectIsLoggedInAsUser,
    selectIsQuorumEmployee,
    selectIsDemoAccount,
    (isLoggedInAsUser, isQuorumEmployee, isDemoAccount) => isLoggedInAsUser || isQuorumEmployee || isDemoAccount,
)

export const selectIsPacPersona = createSelector(
    selectPersona,
    (persona) => persona === DjangIO.app.userdata.models.Persona.pac.value,
)

export const selectIsPacHybridPersona = createSelector(
    selectPersona,
    selectPermissions,
    (persona, permissions) =>
        persona === DjangIO.app.userdata.models.Persona.pac.value && permissions && permissions.get("qp_pac_hybrid"),
)

/**
 * matches a team url to the corresponding object in the array of all teams
 */
export const findTeamByResourceUri = (teamMap, resourceUri) =>
    teamMap.find((obj) => obj.get("resource_uri") === resourceUri) || Immutable.fromJS({})

// return the name of the teams, data provided the the userdata.teams slice of the store
export const selectTeamNamesFromUserdataSlice = (selector) =>
    createSelector(selectTeams, selector, (allTeams, teamValues) => {
        // process multiple items
        if (teamValues && teamValues.constructor === Immutable.List) {
            return teamValues
                .map((resourceUri) => findTeamByResourceUri(allTeams, resourceUri).get("name") || "")
                .join(", ")
        }

        // process single item
        return findTeamByResourceUri(allTeams, teamValues).get("name") || ""
    })

// determine if the User is in a States mode - this can include being in a custom region with a
// single states
export const selectIsUserInStatesMode = () => Userdata.isStatesMode()

export const selectIsLimitedOrg = makeUserdataSelector("limited", false)

// For some users, we want Team Permissioning to apply, but limit their ability to make any changes
// to these settings. We use this boolean to hide these form components (but still render them)
export const selectShouldHideTeamPermissioningInForms = makeUserdataSelector(
    "should_hide_team_permissionining_in_forms",
    false,
)

/**
 * Selector to determine if the Region selector should be shown
 * Hides the region selector if the user has no regions
 * @name selectShouldShowRegionSelector
 * @function
 * @param {Object} Permissions - an Object of Permissions
 * @param {Boolean} mediaRelationsPermissions - boolean if org/user has media relations/comms product
 * @param {Boolean} mediaMonitoringPermissions - boolean if org/user has media monitoring add-on product
 * @returns {Type} - Something
 */
export const selectShouldShowRegionSelector = createSelector(
    selectPermissions,
    selectOrgHasMediaRelations,
    selectOrgHasMediaMonitoring,
    (Permissions, mediaRelationsPermissions, mediaMonitoringPermissions) => {
        if (mediaRelationsPermissions || mediaMonitoringPermissions) {
            return true
        }
        return Boolean(Permissions.filter((v /*, k*/) => v.size).size)
    },
)
const selectUserdataPermissions = makeUserdataSelector("Permissions")
const selectQpLocal = createSelector(
    selectUserdataPermissions,
    (permissions) => (permissions && permissions.get("qp_local")) || Immutable.fromJS([]),
)
const selectQpGrassroots = createSelector(
    selectUserdataPermissions,
    (permissions) => (permissions && permissions.get("qp_grassroots")) || Immutable.fromJS([]),
)

export const selectShouldHideLocalOfficialsContact = createSelector(
    [selectQpLocal, selectQpGrassroots],
    (qp_local, qp_grassroots) => {
        const hasLocal = Boolean(qp_local.size)
        const hasGrassroots = Boolean(qp_grassroots.size)

        // if you don't have the local product, but you have the grassroots product,
        // local contact information should be hidden. In all other cases,
        // don't hide the information because you either:
        //
        // - have access to the local product, so you see everything
        //                              OR
        // - you don't have either product, so local people won't appear, so
        //   why worry about hiding their information?
        return !hasLocal && hasGrassroots
    },
)

export const shouldHideLocalOfficialsContact = () => {
    let { qp_local = [], qp_grassroots = [] } = Userdata.Permissions

    return selectShouldHideLocalOfficialsContact.resultFunc(Immutable.fromJS(qp_local), Immutable.fromJS(qp_grassroots))
}

/**
 * This function determines whether or not the user is in an EU region (EU member states) or in the EU itself.
 * Will only return true if *every* region the user is in is either an EU member state or the EU itself.
 * @returns {Boolean} t/f - whether the User is in the EU
 */
export const isUserInEURegion = () => {
    return Boolean(
        Userdata.current_regions_values.every(
            (val) =>
                DjangIO.app.models.SupportedRegion.by_value(val).is_eu_member_state ||
                val === DjangIO.app.models.Region.eu.value,
        ),
    )
}

/**
 * This function determines whether or not the user is in a US region.
 * Will only return true if every region the user is in all_regions ("entire us")
 * @returns {Boolean} - whether the user is in the US
 */
export const isUserInUSRegion = () => {
    return Boolean(
        Userdata.current_regions_values.every(
            (val) =>
                val === DjangIO.app.models.SupportedRegion.all_regions ||
                DjangIO.app.models.SupportedRegion.all_regions.child_regions.find((region) => region === val),
        ),
    )
}

// Returns true if the user should be allowed to use outbound texting
export const shouldShowOutboundTexting = () => {
    const inUS = isUserInUSRegion()
    const qpActionCenter = window.Userdata.Permissions.qp_action_center
    return Boolean(qpActionCenter && inUS)
}

export const atMentionConnection = createStructuredSelector({
    teamMembers: selectTeamMembersForComponent,
    teamMembersForPicker: selectTeamMembersForPicker,
})

export const selectHomepageDashboardId = makeUserdataSelector("homepage_dashboard_id")

export const selectSearchIsTableView = createSelector(
    makeUserdataSelector("search_is_table_view"),
    (searchIsTableView) => searchIsTableView,
)

/**
 * @param {Number} userdataUserId the user ID from the Userdata object
 * @param {Number} refUserId the user ID being referenced
 * @returns {Boolean}
 * Note: expects neither IDs to be 0.
 */
export const getIsLoadingUserdataUser = (userdataUserId, refUserId) =>
    Boolean(userdataUserId && refUserId && userdataUserId === refUserId)

export const selectSearchShouldHideFilters = createSelector(
    makeUserdataSelector("search_should_hide_filters"),
    (searchShouldHideFilters) => searchShouldHideFilters,
)
