import React from "react"
import { connect } from "react-redux"

import { singleBodySelectors } from "campaign-forms/selectors/write-selectors"

import { StyledBox } from "QuorumGrassroots/widgets/styled-components/styles"

import WriteMessageFormSection from "QuorumGrassroots/campaign-forms/components/WriteMessageFormSection"

const SingleBodyMessageLayout = ({ t, ...props }) => {
    return (
        <div className="single-body-message-layout">
            {props.shouldShowMessage && (
                <StyledBox
                    className="single-body-message"
                    padding="10px"
                    margin="20px 0px 20px 0px"
                    data-cy="message-placeholder-box"
                >
                    {t("campaign.write.single_body_fixed_message")}
                </StyledBox>
            )}
            <WriteMessageFormSection
                activeId="global"
                draftOption={props.campaign.draft_requirements}
                formName={props.formName}
                isCampaignPage
                maxLength={props.maxLength}
                submitting={props.submitting}
                t={t}
                uniqueWidgetId={props.uniqueWidgetId}
                writeSectionConfigs={props.writeSectionConfigs}
            />
        </div>
    )
}

export default connect(singleBodySelectors, null)(SingleBodyMessageLayout)
