import { createSelector, createStructuredSelector } from "reselect"
import { createActionCenterSelector } from "framework/selectors"

import * as loginRegistrationSelectors from "QuorumGrassroots/widgets/LoginRegistration/selectors"

import { selectAugmentedWidgetContent, selectIsWidgetContentLoading } from "QuorumGrassroots/widgets/selectors"

export const selectOfficialList = createSelector(selectAugmentedWidgetContent, (officialList) =>
    officialList && officialList.map ? officialList : [],
)

const selectAllowedMinorRoleTypes = createActionCenterSelector("allowed_minor_role_types")

export const officialListSelector = createStructuredSelector({
    registrationPageIds: loginRegistrationSelectors.selectDefaultRegistrationPageIds,
    allowedMinorRoleTypes: selectAllowedMinorRoleTypes,
    officialList: selectOfficialList,
    loading: selectIsWidgetContentLoading,
})
