import { CloseButton, MultiSelect } from "@mantine/core"
import { constants } from "QuorumGrassroots/constants"
import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"
import styled from "styled-components"

export const BadgeContainer = styled.div`
    display: flex;
    cursor: default;
    align-items: center;
    justify-content: center;

    background-color: ${constants.freshLightGrey};

    padding: 4px 8px;
    border-radius: 4px;

    & > .close-button {
        color: ${constants.freshMediumGray};
    }
`

export const BadgeImageContainer = styled.div`
    width: 16px;
    height: 16px;
    min-width: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-right: 8px;

    & > img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
    }
`

export const BadgeTextContainer = styled.p`
    margin: 0;
    color: ${constants.freshMediumGray};
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    padding-top: 5px;
`

export const BadgeCloseButton = styled(CloseButton)`
    color: ${constants.freshMediumGray};
    padding-top: 2px;
`

export const ItemContainer = styleWithOrgDesign(styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    padding: 12px;
    overflow: hidden;

    input[type="checkbox"],
    p {
        margin: 0;
    }

    ${(props) =>
        props.selected &&
        `
        background-color: ${constants.freshLightGrey};

        .mantine-Checkbox-input {
            background-color: ${props.organizationDesign.primary_color};
        }
        `}
`)

export const ItemTextContainer = styled.p`
    margin: 0;
`

export const StyledMultiSelect = styleWithOrgDesign(styled(MultiSelect)``)
