import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import { FormLabel } from "react-bootstrap"
import Tooltip from "components/Tooltip"

import Icon from "Icon"
import "FormStyles.styl"

/**
 * This is a wrapper that can be used to give redux forms a label and tooltip
 * This also renders any errors or warnings from redux form
 * @param {String} accessibilityId
 * @param {String} additionalClassNames - need more?
 * @param {String} dataCy - identifier for scripting
 * @param {Boolean} error - A props nested inside Redux Form's meta prop, specifies if the form is failing a validation
 * @param {Boolean} warning - Nested in meta prop, similar to error, but less visually severe to user
 * @param {Boolean} touched - Nested in meta prop, conveys if the field has been 'touched' or altered by the user
 * @param {Boolean} displayErrorWithoutTouch - If the error message should be visible when the form is first rendered, without ever being touched. Error would then show when user touches field or tries to submit form
 * @param {String} label - The text above the redux form field, name of the field
 * @param {Object} labelStyle - An object with style rules for the label
 * @param {String} tooltipText - Text describing the function/use of the redux form field
 * @param {String} className - A custom classname, will be part of the label/error classnames
 * @param style - optional style object
 * @param {Component} children - The redux form field below the labels/error message
 * @param {Component} banner - A banner to display after the field header but before children
 * @param {Boolean} renderErrorBelowField - if true, place the error below instead of above
 * @param {Boolean} hideError - if true, don't render errors or warnings. Useful when this field has children, such as with
 * MultipleValuesField, and the children should render errors instead. In that case, the error for this field will be an array.
 * @returns {Component}
 */
export const ReduxFormFieldWrapper = ({
    accessibilityId,
    additionalClassNames,
    banner,
    children,
    className,
    dataCy,
    displayErrorWithoutTouch,
    error,
    hideError,
    label,
    labelStyle,
    renderErrorBelowField,
    showAsterisk,
    style,
    tooltipText,
    touched,
    warning,
    description,
}) => (
    <div
        className={`redux-form-field form-${className}-field ${additionalClassNames}`}
        data-cy={`form-${className}-field-${dataCy}`}
        style={style}
    >
        <div className={`form-${className}-header`}>
            {label && (
                <FormLabel
                    className={classNames("control-label", { "error-label": !!error })}
                    style={labelStyle}
                    htmlFor={accessibilityId}
                >
                    {`${label} `}
                    {showAsterisk && <span className="required">*</span>}
                    {tooltipText && <Tooltip label={tooltipText} />}
                </FormLabel>
            )}
            {
                /* Show the error or warning */
                !renderErrorBelowField && !hideError && (error || warning) && (displayErrorWithoutTouch || touched) && (
                    <div className={`${className}-meta form-${className}-${error ? "error" : "warning"}`}>
                        <span className={`${className}-meta-text`} data-cy="error-meta-text">
                            {
                                // Stringified in case it's an array -- really that means hideError should be true and children
                                // will render errors instead, but this avoids a crash.
                                JSON.stringify(error || warning)
                            }
                        </span>
                    </div>
                )
            }
        </div>
        {description && <p>{description}</p>}
        {banner}
        <div className={`form-${className}-container`}>{children}</div>
        {
            /* Show the error or warning */
            renderErrorBelowField && !hideError && (error || warning) && (displayErrorWithoutTouch || touched) && (
                <div className={`${className}-meta form-below-${className}-${error ? "error" : "warning"}`}>
                    <span className={`${className}-meta-text`} data-cy="error-meta-text">
                        {JSON.stringify(error || warning)}
                    </span>
                </div>
            )
        }
    </div>
)

ReduxFormFieldWrapper.defaultProps = {
    accessibilityId: "",
    additionalClassNames: "",
    dataCy: "",
    displayErrorWithoutTouch: true,
    label: "",
    labelStyle: {},
    tooltipText: "",
    touched: true,
    renderErrorBelowField: false,
}

ReduxFormFieldWrapper.propTypes = {
    accessibilityId: PropTypes.string,
    additionalClassNames: PropTypes.string,
    children: PropTypes.node.isRequired,
    className: PropTypes.string.isRequired,
    dataCy: PropTypes.string,
    // Boolean to determine if the error should be shown before the field is 'touched'. Defaulted to true
    displayErrorWithoutTouch: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.array]),
    hideError: PropTypes.bool,
    label: PropTypes.string,
    labelStyle: PropTypes.object, // styles to apply to the FormLabel
    renderErrorBelowField: PropTypes.bool,
    style: PropTypes.object, // styles to apply to the toplevel div element
    tooltipText: PropTypes.string,
    touched: PropTypes.bool,
    warning: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.array]),
    description: PropTypes.string,
}

export default ReduxFormFieldWrapper
