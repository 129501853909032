import React from "react"
import PropTypes from "prop-types"
import { Col, Row } from "react-bootstrap"

import {
    StyledMemberGroupRow,
    StyledRegionThumbnailWrapper,
    StyledRegionStateThumbnail,
    StyledRegionName,
} from "QuorumGrassroots/widgets/RegionList/components/style"
import "base_css_new_3.styl"
import { Link } from "react-router-dom"

export const RegionsCardView = ({ regionList, regionPagePath }) => (
    <StyledMemberGroupRow className="region-list-widget">
        {regionList.map((region) => (
            <Col md={6} sm={6} xs={12}>
                <Link to={`${regionPagePath}?state=${region.abbrev}`}>
                    <StyledRegionThumbnailWrapper>
                        <Row>
                            <Col md={8} sm={6} xs={12}>
                                <StyledRegionName>{region.name}</StyledRegionName>
                            </Col>
                            <Col md={4} sm={6} xs={12}>
                                <StyledRegionStateThumbnail
                                    className="icon-wrapper state-icon"
                                    dangerouslySetInnerHTML={{
                                        __html: DjangIO.app.models.Region.by_value(region.region).icon_element
                                            ? DjangIO.app.models.Region.by_value(region.region).icon_element
                                            : DjangIO.app.models.Region.by_value(1).icon_element,
                                    }}
                                />
                            </Col>
                        </Row>
                    </StyledRegionThumbnailWrapper>
                </Link>
            </Col>
        ))}
    </StyledMemberGroupRow>
)

RegionsCardView.propTypes = {
    regionList: PropTypes.array,
    regionPagePath: PropTypes.string.isRequired,
}

export default RegionsCardView
