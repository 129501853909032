import sharedPaths, { issueManagementPaths, loggerForms, searchPaths } from "shared/imports/sharedPaths"

export const outBoxPaths = {
    outboxSent: "/outbox/sent",
    outboxScheduled: "/outbox/scheduled",
    outboxDraft: "/outbox/draft",
    outboxCompose: "/outbox/compose/",
    outboxDownloadPrefix: "/outbox/download/",
    outboxDetails: "/outbox/details/",
    outboxManageTemplates: "/outbox/manage_templates/",
    outboxCreateTemplate: "/outbox/create_template/",
}

export const sheetPaths = {
    sheetsTable: "/sheetstable/",
    sheet: "/sheet/",
    sheets: "/sheets/",
    newSheetForm: "/sheets/new/",
    sheetDetail: "/sheet/:sheetId/",
    sheetDetailEdit: "/sheet/:sheetId/edit/",
    sheetDetailVisualize: "/sheet/:sheetId/visualize/",
    columnForm: "/sheet/:sheetId/add_column/:order?/",
    resourceTable: "/resourcetable/:sheetId/",
    setupCustomColumnColors: "/sheet/:sheetId/setup_custom_column_colors/:index",
}

export const accountsPaths = {
    account: "/account/",
    addOrganization: "/accounts/add_organization/",
    addUser: "/accounts/add_user/",
    allUsers: "/all_users/",
    recover: "/recover/",
}

export const getActionCenterPaths = (
    actionCenterId = ":actionCenterId",
    campaignId = ":campaignId",
    grassrootsRegistrationPageId = ":grassrootsRegistrationPageId",
) => ({
    _actionCenterList: `/action_centers/`,
    _actionCenterHome: `/action_centers/${actionCenterId}/`,
    _actionCenterIssues: `/action_centers/${actionCenterId}/issues/`,
    _actionCenterCampaigns: `/action_centers/${actionCenterId}/campaigns/`,
    _actionCenterCampaignNew: `/action_centers/${actionCenterId}/campaigns/new/`,
    _actionCenterCampaignDetail: `/action_centers/${actionCenterId}/campaigns/${campaignId}/`,
    _actionCenterSettings: `/action_centers/${actionCenterId}/settings/`,
    _actionCenterRegistration: `/action_centers/${actionCenterId}/registration_forms/`,
    _actionCenterRegistrationNew: `/action_centers/${actionCenterId}/registration_forms/new/`,
    _actionCenterRegistrationDetail: `/action_centers/${actionCenterId}/registration_forms/${grassrootsRegistrationPageId}/`,
    _actionCenterDonation: `/action_centers/${actionCenterId}/donation_forms/`,
    _actionCenterDonationNew: `/action_centers/${actionCenterId}/donation_forms/new/`,
    _actionCenterDonationDetail: `/action_centers/${actionCenterId}/donation_forms/${grassrootsRegistrationPageId}/`,
    _actionCenterPages: `/action_centers/${actionCenterId}/pages/`,
    _actionCenterEvents: `/action_centers/${actionCenterId}/events/`,
    _actionCenterEventsNew: `/action_centers/${actionCenterId}/events/new/`,
})

export const grassrootsPaths = {
    campaigns: "/campaigns/",
    createCampaign: "/campaigns/new/",
    campaignDetail: "/campaigns/:campaignId/",
    actionCenterSettings: "/action_center_settings/",
    actionCenterSettingsNonDefault: "/action_center_settings/:actionCenterId/",
    bulkUploadForm: "/bulk_upload_form/",
    grassrootsCampaign: "/grassroots_campaign/",
    editContact: "/edit_contact/:entityId?",
    editOrganization: "/edit_organization/:entityId?",
    // Simpler form specific to Outlets and Government Orgs
    modifyOrganization: "/modify_organization/:profileId",
    uploadFile: "/bulk_upload/",
    uploadHeaders: "/finalize_bulk_upload/",
    grassrootsPageList: "/action_centers/:actionCenterId/pages/",
    createGrassrootsPageForm: "/action_centers/:actionCenterId/pages/new/",
    editGrassrootsPageForm: "/action_centers/:actionCenterId/pages/:grassrootsPageId/",
    editGrassrootsSupporterAction: "/add_action/",
    domainsReport: "/grassroots_report/domains-report/:campaignId/",
    targetReport: "/grassroots_report/recipients-single/:campaignId/",
    ...getActionCenterPaths(),
}

export const grassrootsUrls = {
    editContactPrefix: "/edit_contact/",
    editEventPrefix: "/edit_event/",
    editNotePrefix: "/edit_note/",
    editOrganizationPrefix: "/edit_organization/",
    modifyOrganizationPrefix: "/modify_organization/",
    editOfficialPrefix: "/edit_official/",
    editCustomOfficialPrefix: "/edit_custom_official/",
    editBillPrefix: "/edit_bill/",
    editCommitteePrefix: "/edit_committee/",
}

export const alertsPaths = {
    alertsPage: "/alerts/",
    alerts: "/alerts/:isAdmin?",
    alertGroup: "/alert/groups/",
    alertDetail: "/alert/:modelProfile/:datumId?/:fromList?/",
}

export const projectsPaths = {
    lists: "/lists/",
    createProject: "/projects/new/",
    projectProfilePrefix: "/project_profile/",
    projectProfile: "/project_profile/:projectId/",
    boardProfile: "/projects/:projectId?/:boardId?/",
}

export const issuesPaths = {
    issues: "/issue_management/",
    createIssue: "/issue_management/new/",
    ...issueManagementPaths,
}

export const downloadCenterPaths = {
    profileDownload: "/profiledownload/",
    profileDownloadCenterPrefix: "/downloadcenter/",
    profileDownloadCenter: "/downloadcenter/:profileQdt?/:templateId?/:profileId?/",
    downloadCenterTable: "/download_center/",
    uploadAttachment: "/generatepdf/attachment/",
    uploadStatus: "/uploadstatus/",
    impactReports: "/impact_reports/", // new download center DLC 2.0
    impactEdit: "/impact_reports/edit/", // new download center DLC 2.0
}

export const eventPaths = {
    event: "/event/",
    eventCspan: "/event_cspan/",
    transcripts: "/transcripts/",
}

export const accountPaths = {
    account: "/account/",
    myAccount: "/my_account/",
    downloadStatus: "/downloadstatus/",
    addUser: "/accounts/add_user/",
    editUser: "/edit_user/",
    manageOrg: "/account/manage_org/",
    manageOrgs: "/manage_orgs/",
    addOrg: "/add_org/",
    editOrg: "/edit_org/",
    manageTeams: "/manage_teams/",
    addTeam: "/add_team/",
    editTeam: "/edit_team/",
    secureUpload: "/secure_upload/",
    addOrganization: "/accounts/add_organization/",
    changePermissions: "/accounts/change_permissions/",
    editOrgDesign: "/design/edit/",
    twoFactorAuth: "/my_account/two_factor/",
}

export const accountManagementPaths = {
    settings: "/settings/",
    files: "/files/",
    myInformation: "/settings/my_information/",
    searchbar: "/settings/search/",
    security: "/settings/security/",
    sidebar: "/settings/sidebar/",

    orgGeneral: "/settings/organization/",
    orgUsers: "/settings/organization/users/:organizationId?",
    orgTeams: "/settings/organization/teams/:organizationId?",
}

export const userManagementOnBehalf = {
    settingsOnBehalf: "/settings/user/:targetUserId",
    myInformationOnBehalf: "/settings/my_information/user/:targetUserId",
    sidebarOnBehalf: "/settings/sidebar/user/:targetUserId",
    searchbarOnBehalf: "/settings/search/user/:targetUserId",
}

export const duplicateManagementPaths = {
    duplicateManagement: "/duplicate_management/",
}

export const customFieldsPaths = {
    customFields: "/customfields/",
    customFieldsNotes: "/customfields/note/",
    customFieldsDetail: "/customfields/edit/:fieldId",
    customFieldsList: "/customfields/:fieldType",
    customFieldsNew: "/customfields/new",
    customFieldsNewWithParams: "/customfields/new/:fieldType",
}

export const customFieldGroupsPaths = {
    customFieldGroups: "/custom_field_groups/",
    customFieldGroupsDetail: "/custom_field_groups/edit/:fieldId",
    customFieldGroupsList: "/custom_field_groups/:fieldType",
    customFieldGroupsNew: "/custom_field_groups/new",
    customFieldGroupsNewWithParams: "/custom_field_groups/new/:fieldType",
}

export const interactionTypesPaths = {
    interactionTypes: "/interactiontypes/",
    interactionTypesDetail: "/interactiontypes/edit/:interactionTypeId",
    interactionTypesNew: "/interactiontypes/edit/",
}

export const alignmentTypesPaths = {
    alignmentTypes: "/alignmenttypes/",
}

export const stanceCustomLabelsPaths = {
    stanceCustomLabels: "/stance_settings/",
}

export const officialsPath = {
    editOfficialPrefix: "/edit_official/",
    editOfficial: "/edit_official/:profileId/",
    editCustomOfficialPrefix: "/edit_custom_official/",
    editCustomOfficial: "/edit_custom_official/:profileId/",
}

export const pressContactsPath = {
    editPressContactPrefix: "/edit_press/",
    editPressContact: "/edit_press/:profileId/",
}

export const billsPath = {
    editBillPrefix: "/edit_bill/",
    editBill: "/edit_bill/:profileId/",
}

export const committeesPath = {
    editCommitteePrefix: "/edit_committee/",
    editCommittee: "/edit_committee/:profileId/",
}

export const regulationsPath = {
    editRegulationPrefix: "/edit_regulation/",
    editRegulation: "/edit_regulation/:profileId/",
}

export const customEventPaths = {
    customEvents: "/custom_event/",
    editCustomEvent: "/edit_custom_event/:profileId?",
    editCustomEventPrefix: "/edit_custom_event/",
}

export const regionPaths = {
    switchRegion: "/switch_region/",
    customRegionManagement: "/custom_regions/",
    customRegionFormPrefix: "/edit_custom_region/",
    customRegionForm: "/edit_custom_region/:datumId?",
}

export const helpCenterPath = {
    helpCenter: "/help/",
    helpCenterModuleList: "/help/modules/",
    helpCenterFeatureList: "/help/features/",
    helpCenterResourceList: "/help/resources/",
    helpModule: "/help/module/:id/",
    helpFeature: "/help/:featureType/",
    helpHowToPost: "/help/:postType/:postId/:shortCode?",
    helpHowToPostReactRouter: "/help/:postType/:postId/:shortCode?",
    helpTutorial: "/tutorial/",
    helpGlossary: "/glossary/",
    contactUs: "/contactus/",
}

export const zenDeskHelpCenterPaths = {
    zdHelpCenterHome: "https://quorumsupport.zendesk.com/hc/en-us",
    zdHelpCenterLeadAds:
        "https://quorumsupport.zendesk.com/hc/en-us/articles/10975924088731-Use-Zapier-to-Connect-Facebook-or-LinkedIn-Lead-Ads-with-Quorum",
    zdHelpCenterActionCenter: "https://quorumsupport.zendesk.com/hc/en-us/sections/10734627338139-Action-Center",
    zdHelpCenteratMentions: "https://quorumsupport.zendesk.com/hc/en-us/articles/10977889512347-Use-mentions-in-Quorum",
    zdHelpCenterPerson404: "https://quorumsupport.zendesk.com/hc/en-us/articles/12605020460315-US-Officials-and-Staff",
    zdHelpCenterTeamPermissions:
        "https://quorumsupport.zendesk.com/hc/en-us/articles/10778754177563-Limit-Team-Access-Using-Team-Permissions",
}

export const undeletePaths = {
    unarchive: "/unarchive/",
    undeleteAlerts: "/unarchive/alerts/",
    undeleteBulkEmails: "/unarchive/bulkemails/",
    undeleteCustomFields: "/unarchive/customfields/",
    undeleteCustomInteractionTypes: "/unarchive/interactiontypes/",
    undeleteCustomRegions: "/unarchive/customregions/",
    undeleteDashboards: "/unarchive/dashboards/",
    undeleteDownloads: "/unarchive/downloads/",
    undeleteEvents: "/unarchive/events/",
    undeleteIssues: "/unarchive/issues/",
    undeleteLists: "/unarchive/lists/",
    undeleteNotes: "/unarchive/notes/",
    undeletePages: "/unarchive/pages/",
    undeleteRegistrationForms: "/unarchive/registrationforms/",
    undeleteSheets: "/unarchive/sheets/",
    undeleteTrackingDashboards: "/unarchive/trackingboards/",
}

export const dashboardPaths = {
    createDashboard: "/dashboard/new/",
    editDashboard: "/dashboard/:dashboardId/edit/",
    editWidget: "/dashboard/:dashboardId/edit_widget/:widgetType/:widgetKey?/",
    createWidget: "/dashboard/:dashboardId/edit_widget/:widgetType/",
    dashboardPrefix: "/dashboard/",
    dashboard: "/dashboard/:dashboardId/",
}

export const outboundTextingPaths = {
    textingSent: "/texting/sent/",
    textingDraft: "/texting/draft/",
    textingScheduled: "/texting/scheduled/",
    textingCompose: "/texting/compose/:id?/",
    textingDetails: "/texting/details/",
}

export const marketingPaths = {
    caseStudies: "/resources/case-studies/",
    bestPractices: "/resources/best-practices/",
    resources: "/resources/",
}

const pacPrefix = "/pacinternal/"

export const getPacFilingsDetailPath = (ledgerSettingsId, filingsId) =>
    `${pacPrefix}${ledgerSettingsId}/filings/${filingsId}/`

export const getPacPaths = ({
    accountId = ":accountId",
    disbursementId = ":disbursementId",
    filingsId = ":filingsId",
    formType = ":formType",
    jurisdictionId = ":jurisdictionId",
    jurisdictionKey = ":jurisdictionKey",
    ledgerSettingsId = ":ledgerSettingsId",
    politicalCommitteeId = ":politicalCommitteeId",
    receiptId = ":receiptId",
    reconciliationId = ":reconciliationId",
    refundType = ":refundType",
    transactionId = ":transactionId",
} = {}) => ({
    _pacDetailHome: `${pacPrefix}${ledgerSettingsId}/`,
    _pacTransactionsList: `${pacPrefix}${ledgerSettingsId}/transactions/`,
    _pacTransactionEdit: `${pacPrefix}${ledgerSettingsId}/transactions/${transactionId}/`,
    _pacTransactionDetailRoute: `${pacPrefix}${ledgerSettingsId}/transactions/${transactionId}/`,
    _pacTransactionRefundNew: `${pacPrefix}${ledgerSettingsId}/transactions/${transactionId}/:refund`,
    _pacTransactionNewRoute: `${pacPrefix}${ledgerSettingsId}/transactions/:disbursementOrReceipt/new/`,
    _pacReceiptNew: `${pacPrefix}${ledgerSettingsId}/transactions/receipt/new/`,
    _pacDisbursementNew: `${pacPrefix}${ledgerSettingsId}/transactions/disbursement/new/`,

    _pacFinancials: `${pacPrefix}${ledgerSettingsId}/financials/`,
    _pacAccountReconciliationList: `${pacPrefix}${ledgerSettingsId}/financials/${accountId}/account_reconciliations/`,
    _pacAccountErrorList: `${pacPrefix}${ledgerSettingsId}/account_state_errors/`,
    _pacReconciliationList: `${pacPrefix}${ledgerSettingsId}/account/${accountId}/reconciliations/`,
    _pacReconciliationNew: `${pacPrefix}${ledgerSettingsId}/account/${accountId}/reconciliations/new/`,
    _pacReconciliationDetail: `${pacPrefix}${ledgerSettingsId}/reconciliations/${reconciliationId}/`,
    _pacReconciliationSummary: `${pacPrefix}reconciliations/${reconciliationId}/summary/`,
    _pacFilingsList: `${pacPrefix}${ledgerSettingsId}/filings/`,
    _pacPoliticalCommitteesNew: `${pacPrefix}${ledgerSettingsId}/political_committees/new/`,
    _pacPoliticalCommitteesEdit: `${pacPrefix}${ledgerSettingsId}/political_committees/${politicalCommitteeId}/`,
    _pacStateFilingsNew: `${pacPrefix}${ledgerSettingsId}/filings/state/${jurisdictionKey}/new/`,
    _pacStateFilingsEdit: `${pacPrefix}${ledgerSettingsId}/filings/state/${jurisdictionKey}/${filingsId}/edit/`,
    _pacStateFilingsReview: `${pacPrefix}${ledgerSettingsId}/filings/state/${jurisdictionKey}/${filingsId}/`,
    _pacFilingsPrepareFormOne: `${pacPrefix}${ledgerSettingsId}/filings/prepare_form_one/`,
    _pacFilingsPrepareFormOneExisting: `${pacPrefix}${ledgerSettingsId}/filings/prepare_form_one/${filingsId}`,
    _pacFilingsDetail: getPacFilingsDetailPath(ledgerSettingsId, filingsId),
    _pacFilingsReview: `${pacPrefix}${ledgerSettingsId}/filings/${filingsId}/review/${formType}`,
    _pacFilingsReviewNew: `${pacPrefix}${ledgerSettingsId}/filings/${jurisdictionKey}/review/${filingsId}`,
    _pacSettings: `${pacPrefix}${ledgerSettingsId}/settings/`,
    _pacBankAccountListView: `${pacPrefix}${ledgerSettingsId}/settings/financials/`,
    _pacSettingsFinancials: `${pacPrefix}${ledgerSettingsId}/settings/financials/${accountId}/`,
    _pacSettingsForm1: `${pacPrefix}${ledgerSettingsId}/settings/form1`,
})

export const pacPaths = {
    pacList: `${pacPrefix}`,
    pacLanding: `${pacPrefix}landing/`,
    ...getPacPaths(),
}

const paths = {
    ...accountPaths,
    ...accountsPaths,
    ...userManagementOnBehalf,
    ...accountManagementPaths,
    ...alertsPaths,
    ...billsPath,
    ...committeesPath,
    ...regulationsPath,
    ...downloadCenterPaths,
    ...eventPaths,
    ...customFieldsPaths,
    ...customFieldGroupsPaths,
    ...interactionTypesPaths,
    ...alignmentTypesPaths,
    ...grassrootsPaths,
    ...grassrootsUrls,
    ...helpCenterPath,
    ...officialsPath,
    ...pressContactsPath,
    ...outBoxPaths,
    ...projectsPaths,
    ...regulationsPath,
    ...sharedPaths,
    ...sheetPaths,
    ...customEventPaths,
    ...issuesPaths,
    ...regionPaths,
    ...stanceCustomLabelsPaths,
    ...undeletePaths,
    ...duplicateManagementPaths,
    ...dashboardPaths,
    ...outboundTextingPaths,
    ...marketingPaths,
    ...pacPaths,
    ...zenDeskHelpCenterPaths,
    change: "/change/",
    email_support: "mailto:support@quorum.us",
    explore: "/explore/",
    featureflags: "/featureflags/",
    geographies: "/geographies/",
    glossary: "/glossary/",
    helpCenter: "/help/",
    home: "/home/",
    livetranscripts: "/livetranscripts/",
    logout: "/logout/",
    missingPerson: "/person_not_found/", // path when officials/staffers are not found
    notFound: "/notfound/",
    permissionDenied: "/permission_denied/",
    registration: "/registration/",
    statements: "/statements/",
    tutorial: "/tutorial/",
    uploadFile: "/bulk_upload/",
    whatsnew: "/whatsnew/",
}

export default paths

export const searchWhitelist = [
    ...DjangIO.app.models.QuorumDataType.items()
        .filter(
            (item) =>
                item.profile &&
                // whenever we segue to anywhere in Quorum,
                // we fire app/static/frontend/containers/Frame.jsx componentDidUpdate

                // if we segue from a location.pathname that is included in app/static/frontend/imports/desktopPaths.js searchWhitelist
                // (selected from app/static/frontend/main-selectors.jsx selectIsInSearchWhitelist)
                // to a location.pathname that is not included in searchWhitelist,
                // we completely clear all FilterableListView (including the current searchTerm) from the redux store using clearAllFilterableListViews() and setSearchTerm("").

                // the problem here is the fact that, in sheets, when we segue to a SafedSearch, we apply its searchTerm (advanced_search)
                // to the app/static/frontend/containers/Frame.jsx TopNav SearchTextarea which persists across datasets

                // this means that, if we were to click a SafedSearch cell with a searchTerm (to segue to its Search dataset),
                // segue back to the sheet (by clicking the browser back button), and then click a SafedSearch cell without a searchTerm (to segue to its Search dataset),
                // then the second search dataset segue would still have the stale searchTerm from the previous segue applied and return an incorrect number of results

                // since we now persist the searchTerm (advanced_search) across datasets in new search,
                // we need to ensure that we clear all FilterableListView and searchTerm information from the store
                // if we segue back to the sheet from search

                // so, because DjangIO.app.models.QuorumDataType.sheet.profile === "/sheet/",
                // it is currently being included in the searchWhitelist which means that we will not clear
                // the FilterableListView content and current searchTerm when we segue from search back to the sheet

                // now, since we can't remove DjangIO.app.models.QuorumDataType.sheet.profile since the sheet resource table
                // relies on it in order to properly segue to individual sheets,
                // we manually exclude it here in order to properly clear the searchTerm before segueing to the sheet
                ![
                    DjangIO.app.models.QuorumDataType.dashboard.key,
                    DjangIO.app.models.QuorumDataType.new_download_template.key,
                    DjangIO.app.models.QuorumDataType.sheet.key,
                    DjangIO.app.models.QuorumDataType.tracking_dashboard.key,
                ].includes(item.key),
        )
        .map((item) => item.profile),
    ...Object.values(searchPaths),
    ...Object.values(loggerForms),
    ...Object.values(grassrootsUrls),
    "/alert/search/",
    paths.livetranscripts,
    paths.home,
]

export const searchBlacklist = [
    // while base profile URIs are being explicitly included in the searchWhitelist above,
    // (such as /issue_management/, /bill/, /regulation/, /committee/, etc.),
    // there are cases where we want to whitelist most of the sub-tabs in one of those profiles
    // (such as /issue_management/:profileId/bill/, /issue_management/:profileId/regulation/, etc.)
    // but blacklist a specific one
    // (such as/issue_management/:profileId/lists/ )

    // this is necessary because, in the profile examples above,
    // while there may be one or two tabs that we want to blacklist from search,
    // most of the tabs are just search FLVs that need to retain their state across segues
    "/issue_management/:profileId/lists/",
]

export function profileEndpointFromDataType(quorumDataType) {
    return window.DjangIO.app.models.QuorumDataType.by_value(quorumDataType).profile
}

export const profileUrlFromTypeAndId = (dataType, id) => `${profileEndpointFromDataType(dataType)}${id}/`

export const projectProfileUrl = (id) => `${paths.projectProfilePrefix}${id}/overview/`

// given the url we need to set the document title
export function documentTitleFromUrl(url) {
    for (const item of window.DjangIO.app.models.SidebarItem.items()) {
        if (url.includes(item.href)) {
            return item.label
        }
    }
    switch (true) {
        case url.includes("/add_column/"):
            return "New Column"
        case url.includes("/sheet/"):
            return "Sheets"
        case url.includes("/home/"):
            return "Home"
        case url.includes("/whatsnew/"):
            return "What's New"
    }

    // otherwise, try to match the url to one of the urls above
    // if it does, return the non-pascalCase key
    const mappedValue = Object.entries(paths)
        .filter(([, urlValue]) => url.includes(urlValue))
        .map(([urlName]) => {
            // though we need two routes for int'l / us contacts, we still want
            // the tab to say "Contact"
            if (urlName === "intl_contacts") {
                return "Contacts"
            }
            // replace any capital with " Capital"
            let title = urlName.replace(/([A-Z])/g, " $1")
            if (title.includes("_")) {
                title = title.replace(/_/g, " ").replace(/(?: |\b)(\w)/g, (key) => key.toUpperCase())
            }

            // capitalize the first letter
            const returnValue = title.charAt(0).toUpperCase() + title.slice(1)
            return returnValue.replace("Prefix", "")
        })
        .find((label) => label)

    // Iterate over sidebar item
    return mappedValue || "Best-in-Class Public Affairs Software"
}
