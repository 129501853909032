import React from "react"
import { fromJS } from "immutable"
import PropTypes from "prop-types"
import { FormLabel } from "react-bootstrap"
import classNames from "classnames"

import { Label } from "QuorumDesign"
import Icon from "Icon"
import Tooltip from "components/Tooltip"
import "FormStyles.styl"

/**
 * This creates the callback function to give to any redux-form Field with SearchifySelectField components
 * Since a user could use searchify to add lists of participants or log an interaction with all current staffers by the name of Angela
 * (which is saved that by searchify a query), we need a way to get the ids from all the objects a user selects through searchify.
 *
 * @param  {Object}   obj.resource    [Resource to request objects from, e.g. DjangIO.app.person.models.NewPerson (resource of objects being selected through searchify)]
 * @param  {Function} obj.changeFunc  [either props.change or props.arrayPush (both are props given to component by redux-form)]
 * @param  {String}   obj.formField   [Name of form field to change with results of the searchifyFilters, e.g. 'officials', 'bills']
 * @param  {Number}   obj.maxSearchifyCount   [Number of selections to include from searchify]
 * @param  {String}   obj.objectField [Optional -- name of object field to use from the requests response]
 * @param  {Function} obj.customOnChange [optional -- if present, a custom function that gets run before the existing callback
 *                                          should take the selected object or, if multi-select, a list of objects as argument]
 */
export const searchifyCallBackFunc =
    ({ resource, changeFunc, formField, maxSearchifyCount, multi, objectField = "id", customOnChange }) =>
    (selection) => {
        const { searchifyFilters } = selection
        const kwargs = { current_searchify_selection: JSON.stringify(searchifyFilters) }
        const searchifyCount = searchifyFilters && searchifyFilters.searchifyCount

        if (maxSearchifyCount) {
            kwargs.limit = maxSearchifyCount
        }

        if (searchifyCount > maxSearchifyCount) {
            swal({
                icon: "info",
                text:
                    `Since your selection of ${searchifyCount} ${formField} is larger than the overall limit of ${maxSearchifyCount} ` +
                    `Power Search selections, we have truncated your selections to the first ${maxSearchifyCount}.`,
                title: "Warning",
            })
        }

        resource.objects
            .filter(kwargs)
            .GET()
            .then((response) => {
                // if it is a multi select field, we need to return an array of all objects
                // if not, we just return a single value to the select
                const changeFuncParam = multi
                    ? fromJS(response.objects.map((object) => object[objectField]))
                    : fromJS(response.objects[0][objectField])

                if (customOnChange) {
                    customOnChange(multi ? response.objects : response.objects[0])
                }

                // changeFunc will usually be props.change from redux-form
                // this will ensure that we update the select value in the store
                return changeFunc(formField, changeFuncParam)
            })
    }

/**
 * This component wraps our pie select components (e.g. PersonSelect, UserSelect) and funnels
 * redux-form props to the select component, adds error/warning styling and messages, and adds
 * the typical form field label and tooltip to match our other fields.
 * A 'createButton' react element can be optionally passed in. If defined, then it will be displayed
 * to the right of the select field (as seen in the InteractionLoggerForm at Search > Notes > Add Note > Participants step)
 */
export const SearchifySelectField = (props) => {
    const {
        className,
        createButton,
        customProps,
        dataCy,
        label,
        meta,
        selectComponent,
        shouldRenderNewLabel = true,
        showAsterisk,
        tooltipText,
    } = props

    // show error if user has touched the field and it is invalid
    // show warning whether or not user has touched field as long as not already showing an error for this field
    const error = meta.touched && meta.error
    const warning = !error && meta.warning

    const SelectComponent = selectComponent

    const getLabel = () => {
        if (SelectComponent.defaultProps) {
            return SelectComponent.defaultProps.label
        } else if (customProps) {
            return customProps.label
        }

        return undefined
    }

    const getSelectComponent = () => (
        <SelectComponent
            dataCy={dataCy}
            onChange={(selected) => {
                // PieFormSection, LineFormSection, BarFormSection WidgetEditForm sidebar forms
                // need to reset some form fields when we choose a new data_type
                if (customProps.customOnChange) {
                    customProps.customOnChange(selected)
                }

                if (customProps.multi) {
                    props.change(props.name, fromJS(selected.map((p) => p.id)))
                } else {
                    props.change(props.name, fromJS(selected.id))
                }
            }}
            searchifyCallBack={searchifyCallBackFunc({
                resource: props.model,
                changeFunc: props.change,
                formField: props.name,
                maxSearchifyCount: customProps.maxSearchifyCount,
                multi: customProps.multi,
                customOnChange: customProps.customOnChange,
            })}
            value={props.searchifyValue}
            // onChange/searchifyCallBack can be optionally overridden when we spread props/customProps below
            {...props}
            {...customProps}
            label={getLabel()}
        />
    )

    return (
        <div className={`form-select-field ${className}`}>
            <div className="form-select-label" style={label ? { marginBottom: "8px" } : undefined}>
                {label &&
                    (shouldRenderNewLabel ? (
                        <Label
                            className="control-label"
                            title={label}
                            tooltipElement={tooltipText ? <Tooltip label={tooltipText} /> : undefined}
                        />
                    ) : (
                        <FormLabel className="control-label">
                            {`${label} `}
                            {showAsterisk && <span className="required">*</span>}
                            {tooltipText && <Tooltip label={tooltipText} />}
                        </FormLabel>
                    ))}
                {
                    // Show error message on top level
                    (error || warning) && (
                        <div>
                            <div className={`select-meta-text form-select-${error ? "error" : "warning"}`}>
                                <span data-cy="searchify-select-field-form-error-text">{error || warning}</span>
                                <Icon name={error ? "times" : "exclamation-triangle"} className="select-icon" lg />
                            </div>
                        </div>
                    )
                }
            </div>
            <div
                className={
                    classNames({
                        "select-error-outline": error,
                        "select-warning-outline": warning,
                    }) || undefined
                }
            >
                {createButton ? (
                    <div className="create-button">
                        <div className="create-button-left">{getSelectComponent()}</div>
                        <div className="create-button-right">{createButton}</div>
                    </div>
                ) : (
                    getSelectComponent()
                )}
            </div>
        </div>
    )
}

SearchifySelectField.propTypes = {
    // from app/static/frontend/search-new/components/BulkActions/BulkActionsConsole
    change: PropTypes.func,
    model: PropTypes.object,
    name: PropTypes.string,
    searchifyValue: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),

    label: PropTypes.string,
    tooltipText: PropTypes.string,
    className: PropTypes.string,
    meta: PropTypes.object,
    customProps: PropTypes.object.isRequired,
    selectComponent: PropTypes.func,
    createButton: PropTypes.object,
    multi: PropTypes.bool,
    shouldRenderNewLabel: PropTypes.bool,
}

export default SearchifySelectField
