/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import * as djangioActionCreators from "shared/djangio/action-creators"
import {
    createTransactionEventActionDict,
    POP_AFTER_TRANSACTION_FORM_SUBMISSION,
    START_NEW_TRANSACTION_AFTER_TRANSACTION_FORM_SUBMISSION,
} from "shared/pac/types"
import { isEnumEqual, idFromResourceUri } from "shared/imports/sharedHelperFunctions"
import {
    COMMITTEE_ENTITY_TYPE_KEY,
    ORGANIZATION_ENTITY_TYPE_KEY,
    VALIDATION_MESSAGE_FOR_EMPTY_DESCRIPTION,
} from "shared/pac/constants"
import { positiveNumberValidation } from "shared/imports/validationFunctions"
import { isFeatureEnabled } from "shared/featureflags/helperFunctions"

const { TransactionEvent, LedgerSettings } = DjangIO.app.ledger.models
const { LedgerAccountType, TransactionMethodType, TransactionType } = DjangIO.app.ledger.types
const { PoliticalCommittee } = DjangIO.app.pac.models.political_committee
const { PoliticalCommitteeType, EntityType } = DjangIO.app.pac.types

/**
 * This function returns the first account that matches the accountType provided
 * @name findLedgerAccountByAccountType
 * @function
 * @param {List[Map]} ledgerAccounts - a List of ledger Accounts
 * @param {Number} accountType - an enum value from the LedgerAccountType enum
 * @returns {Map|undefined} - a Map of the Account if it exists, otherwise undefined
 */
export const findLedgerAccountByAccountType = (ledgerAccounts, accountType) => {
    if (!accountType) {
        return undefined
    }
    return ledgerAccounts && ledgerAccounts.find((account) => isEnumEqual(account.get("account_type"), accountType))
}

/**
 * Decides the transaction's entity classification group.
 * For example, there are multiple organization entity types, however, they should return the same ORGANIZATION_ENTITY_TYPE_KEY classification.
 * Mostly, these are determined by seeing if a certain field is defined on the transaction.
 *
 * @param {Object} transaction - object with API accessible fields of a Transaction
 */
export const getTransactionEntityToggleType = (transaction) => {
    if (transaction.get("public_organization")) {
        return ORGANIZATION_ENTITY_TYPE_KEY
    }
    if (transaction.get("committee")) {
        return COMMITTEE_ENTITY_TYPE_KEY
    }
    return EntityType.individual.value
    // TODO: Possibly extend to other classifications.
}

/**
 * This function takes the values from the form and generates the data object that is required
 * to create a TransactionEvent
 * @name someName
 * @function
 * @param {Type} argument - a description
 * @returns {Type} - Something
 */
export const prepareFormValuesForSubmit = ({
    creditAccount,
    debitAccount,
    formValues,
    isCreatingRefund,
    isEditing,
    attributionData,
    transactionId,
    transactionType,
    transactionSpecialType,
}) => {
    const {
        additional_notes,
        committee,
        contribution_type, // Receipt account
        description,
        disbursement_type, // Disbursement account
        election,
        entity_type, // the selection in the Who is it from section
        fromBank,
        allocation,
        non_pac_public_organization,
        payroll_frequency_type,
        public_organization,
        raw_amount,
        reference_number,
        supporter,
        exclude_from_limits_calc,
        force_itemize,
        transaction_method,
        batch,
        expense_type,
        enable_allocation_data,
    } = formValues

    let data = {}
    if (entity_type === EntityType.individual.value) {
        data.supporter = DjangIO.app.grassroots.models.Supporter.resourceUriFromId(supporter)
    } else if (
        PoliticalCommitteeType.items()
            .map((item) => item.value)
            .includes(entity_type)
    ) {
        if (committee) {
            data.committee = PoliticalCommittee.resourceUriFromId(committee)
        }
    } else if (
        entity_type &&
        DjangIO.app.pac.types.EntityType.by_value(entity_type).parent === EntityType.public_organization.value
    ) {
        data.public_organization = DjangIO.app.person.models.PublicOrganization.resourceUriFromId(public_organization)
    }

    // Make sure we have date as ISO date format, YYYY-MM-DD
    let { occurred_date } = formValues
    if (occurred_date instanceof Date) {
        occurred_date = occurred_date.toISOString().slice(0, 10)
    }

    if (expense_type) {
        data.expense_type = expense_type.value ? expense_type.value : expense_type
    }

    data = {
        additional_notes,
        amount: raw_amount,
        credit_account: DjangIO.app.ledger.models.Account.resourceUriFromId(creditAccount),
        debit_account: DjangIO.app.ledger.models.Account.resourceUriFromId(debitAccount),
        description,
        occurred_date,
        exclude_from_limits_calc,
        enable_allocation_data,
        force_itemize,
        reference_number,
        transaction_method,
        transaction_type:
            transactionSpecialType === DjangIO.app.ledger.types.TransactionSpecialType.reversal.value
                ? DjangIO.app.ledger.types.TransactionType.by_value(transactionType).reverse_type
                : transactionType,
        ...data,
    }

    if (non_pac_public_organization) {
        data.non_pac_public_organization = non_pac_public_organization
    }

    if (batch) {
        data.batch = batch
    }

    if (allocation && allocation.length > 0) {
        let committeeToTransactionIdMap
        if (isEditing) {
            committeeToTransactionIdMap = getAllocationCommitteeToExistingTransactionIdMap(attributionData)
        }
        data.attribution_data = allocation.map((item) => {
            const _attribution = {
                amount: item.amount,
                committee: item.committee,
                description: item.description,
                ...(isEditing ? { transaction_id: committeeToTransactionIdMap[item.committee] } : {}),
            }
            if (item.election) {
                // Committees that don't track limits via election should not pass an election to create this Memo
                _attribution.election = item.election
            }
            return _attribution
        })
        return data
    } else if (transaction_method === TransactionMethodType.in_kind.value && allocation && allocation.length > 0) {
        // remove committee_type from allocations -- it's just there as a helper
        data.attribution_data = allocation.map((original) => {
            const newItem = { ...original }
            delete newItem.committee_type
            return newItem
        })
    }
    if (transaction_method === TransactionMethodType.payroll.value) {
        data = {
            payroll_frequency_type,
            ...data,
        }
    }

    if (isCreatingRefund && transactionId) {
        data.reference_transaction = transactionId
    }

    if (election) {
        data.election = DjangIO.app.pac.models.election.Election.resourceUriFromId(election)
    }

    if (transaction_method === TransactionMethodType.printing_check) {
        data.reference_number = undefined
        // Auto Printed Checks should have no reference number. A reference number can exist if the client
        // switched from Manual Check to Auto Printed when filling out the form.
    }

    return data
}

/**
 * This docstring is for all validateXValue functions
 * Called in Receipt and Disbursement form 'validate' functions
 * Checks that individual form value is defined and valid given form value and props
 * @name validateXValue
 * @function
 * @param {Immutable.Map} immutableValues - immutable map of Redux Form values
 * @param {object} props - props of the form
 * @returns {object} - Object with field name as key and error message as value
 */
export const validateBankValue = (immutableValues, props) => {
    const errors = {}

    const fromBank = immutableValues.get("fromBank")
    if (props.isBankEntityType && (!fromBank || fromBank.size < 1)) {
        errors.fromBank = "Select a bank account."
    }

    return errors
}

export const validateCommitteesValue = (immutableValues, props) => {
    const errors = {}

    const committees = immutableValues.get("committees")
    if (props.isAffiliatedCommitteesEntityType && (!committees || committees.size < 1)) {
        errors.committees = "Select a committee."
    }

    return errors
}

export const validateInternalBankAccountValue = (immutableValues, props) => {
    const errors = {}

    const internalBankAccount = immutableValues.get("internalBankAccount")
    if (props.displayInternalBankAccountField && (!internalBankAccount || internalBankAccount.size < 1)) {
        errors.internalBankAccount = "Select a bank account."
    }

    return errors
}

export const validateReattributeContactsValue = (immutableValues, props) => {
    const errors = {}

    if (props.displayReattributeContactField) {
        const contacts = immutableValues.get("reattributeContacts")

        if (contacts.size < 1) {
            errors.reattributeContacts = "Add a contact."
        }

        contacts.forEach((contact) => {
            if (!contact.get("receiptAmount")) {
                errors.reattributeContacts = "Specify a receipt amount."
            }

            if (!contact.get("id")) {
                errors.reattributeContacts = "Choose a contact."
            }
        })
    }

    return errors
}

export const validateSupporterValue = (immutableValues, props) => {
    const errors = {}

    if (props.isIndividualEntityType && !immutableValues.get("supporter")) {
        errors.supporter = "Select a contact."
    }

    return errors
}
export const validateOrganizationValue = (immutableValues, props) => {
    const errors = {}

    if (props.isOrganizationEntityType && !immutableValues.get("public_organization")) {
        errors.public_organization = "Select an organization."
    }

    return errors
}
export const validateCommitteeValue = (immutableValues, props) => {
    const errors = {}

    if (props.isCommitteeEntityType && !immutableValues.get("committee")) {
        errors.committee = "Select a committee."
    }

    return errors
}

export const validateExpenseTypeValue = (immutableValues) => {
    const errors = {}

    if (!immutableValues.get("expense_type")) {
        errors.expense_type = "Select an expense type."
    }

    return errors
}

export const validateAmountValue = (immutableValues) => {
    const errors = {}

    if (!immutableValues.get("raw_amount")) {
        errors.raw_amount = "Input an amount."
    } else if (immutableValues.get("raw_amount") === 0) {
        errors.raw_amount = "Enter an amount greater than $0.00"
    }

    return errors
}

export const validateDateValue = (immutableValues) => {
    const errors = {}

    if (!immutableValues.get("occurred_date")) {
        errors.occurred_date = "Enter a date for this transaction"
    }
    return errors
}

export const validateAccountTypeValue = (immutableValues) => {
    const errors = {}

    if (!immutableValues.get("contribution_type") && !immutableValues.get("disbursement_type")) {
        errors.contribution_type = "Select a contribution type."
        errors.disbursement_type = "Select a disbursement type."
        // Any given form only has one of the above, so is a concise solution.
    }

    return errors
}

export const validateTransactionMethodValue = (immutableValues) => {
    const errors = {}

    if (typeof immutableValues.get("transaction_method") !== "number") {
        errors.transaction_method = "Select a transaction method."
    }

    return errors
}

export const validateBankAccountValue = (immutableValues) => {
    const errors = {}

    if (!immutableValues.get("bank_account")) {
        errors.bank_account = "Select a bank account."
    }

    return errors
}

export const validatePayrollFrequencyTypeValue = (immutableValues) => {
    const errors = {}

    if (
        immutableValues.get("transaction_method") === TransactionMethodType.payroll.value &&
        !immutableValues.get("payroll_frequency_type")
    ) {
        errors.payroll_frequency_type = "For payroll receipts, the payroll frequency is required"
    }

    return errors
}

/**
 * This validation checks that there is a description if the disbursement is a refund.
 *
 * This is a requirement of the FEC.
 *
 * @param {Object} immutableValues - An immutable map of the Redux form values
 * @param {Object} props - component props
 *
 * @returns {Object}
 */
export const validateDescription = (immutableValues, props) => {
    const errors = {}

    if (props.refundAccountIfAny && !immutableValues.get("description")) {
        errors.description = VALIDATION_MESSAGE_FOR_EMPTY_DESCRIPTION
    }

    return errors
}

/**
 * This validation checks that the sum of allocations, if any, sums to the transaction's amount.
 *
 * @param {Object} immutableValues - An immutable map of the Redux form values
 *
 * @returns {Object}
 */
export const validateAllocationTotals = (immutableValues) => {
    const errors = {}

    const allocation = immutableValues.get("allocation")

    if (allocation && allocation.size !== 0) {
        const raw_amount = immutableValues.get("raw_amount")
        if (raw_amount) {
            let allocationAmountTotal = 0
            allocation.forEach((item) => {
                const amount = item.get("amount") || 0
                allocationAmountTotal += amount
            })
            if (raw_amount !== allocationAmountTotal) {
                errors.raw_amount = "Amount must match sum of allocations."
            }
        }
    }
    return errors
}

export const validateAllocationDetails = (immutableValues) => {
    let hasError = false
    const allocations = immutableValues?.get("allocation")?.toJS()
    // other fields present are description, transaction_id
    const allocationErrors = allocations?.map(({ amount, committee, committee_type, election }) => {
        const errors = {}
        if (!committee) errors.committee = "Select a committee."
        if (!amount) errors.amount = "Enter an amount greater than $0.00."
        if (
            committee_type === PoliticalCommitteeType.candidate_committee.value &&
            !election &&
            ![
                LedgerAccountType.non_reporting_disbursement_to_political_committees.value,
                LedgerAccountType.non_reporting_receipt_to_political_committees.value,
                LedgerAccountType.refunds_of_non_reporting_disbursement_to_political_committees.value,
                LedgerAccountType.refunds_of_non_reporting_receipt_to_political_committees.value,
            ].includes(immutableValues.get("disbursement_type"))
        ) {
            errors.election = "Select an election."
        }
        if (Object.keys(errors).length > 0) hasError = true
        return errors
    })
    return hasError ? { allocation: allocationErrors } : {}
}

/**
 * An action creator that prompts the form to have normal popPage behavior.
 *
 * @returns {Object} an action
 */
export const setPopAfterSubmit = () => ({ type: POP_AFTER_TRANSACTION_FORM_SUBMISSION })

/**
 * An action creator that prompts the form to reset fields to empty after submission.
 * In anticipation of new submission.
 *
 * @returns {Object} an action
 */
export const startNewTransactionAfterSubmit = () => ({ type: START_NEW_TRANSACTION_AFTER_TRANSACTION_FORM_SUBMISSION })

/**
 * Map each committee ID to name of the field in the form, according to the form values.
 *
 * @param {Object} allocationFormValue
 * @returns {Object} {Number} committee ID -> {String} field name
 */
export const getAllocationCommitteeToFieldNameMap = (allocationFormValue) => {
    const map = {}
    if (allocationFormValue) {
        for (let rowId = 0; rowId < allocationFormValue.size; rowId++) {
            const row = allocationFormValue.get(rowId)
            map[row.get("committee")] = `allocation[${rowId}].amount`
        }
    }
    return map
}

/**
 * For use in editing memo/attribution transactions.
 *
 * Map committees to existing transaction IDs, according to the attribution data.
 *
 * @param {Object} attributionData
 * @returns {Object}
 */
export const getAllocationCommitteeToExistingTransactionIdMap = (attributionData) => {
    if (attributionData) {
        return attributionData.reduce((map, tx) => {
            map[tx.committee] = tx.transaction_id
            return map
        }, {})
    }
    return {}
}

/**
 * Map each error to name of the field in the form, according to the form values.
 *
 * @param {Object} allocationErrors response errors
 * @param {Object} allocation form values
 * @returns {SubmissionError}
 */
export const getAllocationFieldErrors = (allocationErrors, allocation) => {
    const fieldErrors = {}
    const committeeToFieldNameMap = getAllocationCommitteeToFieldNameMap(allocation)
    allocationErrors.forEach((error) => {
        const fieldName = committeeToFieldNameMap[error.committee_id]
        fieldErrors[fieldName] = error.message
    })
    return fieldErrors
}

/**
 * Send a list of IDs to the Ledger API to set `matched=True` for transactions with those IDs.
 *
 *  @param {Number} currentReconciliationStatus enum value
 *  @param {Function} dispatch
 *  @param {Boolean} isFinished whether or not to finalize the reconciliation
 *  @param {Number} ledgerSettingsId
 *  @param {Array} matchedBatchDiff ids of the newly matched batches
 *  @param {Number} reconciliationId,
 *
 * @returns {Undefined | Promise}
 */
export const submitReconciliation = async ({
    currentReconciliationStatus,
    dispatch,
    isFinished,
    ledgerSettingsId,
    currentMatchedBatchSet,
    currentMatchedTransactionSet,
    reconciliationId,
}) => {
    await dispatch(
        djangioActionCreators.sendAction(TransactionEvent, createTransactionEventActionDict, {
            action: "batch_match_transaction_events",
            kwargs: {
                current_reconciliation_status: currentReconciliationStatus,
                is_finished: isFinished,
                ledger_settings: LedgerSettings.resourceUriFromId(ledgerSettingsId),
                matched_batches: currentMatchedBatchSet,
                matched_transactions: currentMatchedTransactionSet,
                reconciliation_id: parseInt(reconciliationId),
            },
            method: "post",
            payload: {
                current_reconciliation_status: currentReconciliationStatus,
                is_finished: isFinished,
                matched_batches: [...(currentMatchedBatchSet || {})],
                matched_transactions: [...(currentMatchedTransactionSet || {})],
                ledger_settings: LedgerSettings.resourceUriFromId(ledgerSettingsId),
                reconciliation_id: parseInt(reconciliationId),
            },
        }),
    )
}

/**
 * Determine if the transaction is a receipt by checking if the transaction type is
 * either money_received or refund_received.
 *
 * @param {Number} transactionType
 * @returns {Boolean}
 */
export const isReceiptTransaction = (transactionType) =>
    [TransactionType.money_received.value, TransactionType.refund_received.value].includes(transactionType)

/**
 * Determine if the transaction is a disbursement by checking if the transaction type is
 * either money_given or refund_given.
 *
 * @param {Number} transactionType
 * @returns {Boolean}
 */
export const isDisbursementTransaction = (transactionType) =>
    [TransactionType.money_given.value, TransactionType.refund_given.value].includes(transactionType)

/**
 * Given an entity (name) and a resource uri for a supporter, public org, or committee,
 * render a hyperlink string to the entity's profile.
 *
 * @param {String} entity - name of entity (John/Quorum/Some Committee)
 * @param {String} supporter - resource uri
 * @param {String} publicOrganization - resource uri
 * @param {String} politicalCommittee - resource uri
 * @returns {String}
 */
export const generateTransactionHyperlink = (entity, supporter, publicOrganization, politicalCommittee) => {
    if (supporter) {
        const supporterQdtProfile = DjangIO.app.models.QuorumDataType.supporter.profile
        return supporter && `<a href="${supporterQdtProfile}${idFromResourceUri(supporter)}"/>${entity}</a>`
    }
    if (publicOrganization) {
        const pubOrgQdtProfile = DjangIO.app.models.QuorumDataType.public_organization.profile
        return (
            publicOrganization && `<a href="${pubOrgQdtProfile}${idFromResourceUri(publicOrganization)}"/>${entity}</a>`
        )
    }
    if (politicalCommittee) {
        const politicalCommitteeQdtProfile = DjangIO.app.models.QuorumDataType.political_committee.profile
        return (
            politicalCommittee &&
            `<a href="${politicalCommitteeQdtProfile}${idFromResourceUri(politicalCommittee)}"/>${entity}</a>`
        )
    }

    return undefined
}

/**
 * Take raw amount of cents and convert to dollars string, for example 114836 --> "$1,148.36".
 * Undefined and NaN are represented as "$$$$", since it usually means things are still loading.
 *
 * converts 3261850 to "$32,618.50"
 *
 * @param {number} val raw cents
 * @returns {false|string}
 */
export const centsToCurrencyStr = (val) =>
    // Note: isNaN(undefined) is true
    isNaN(val)
        ? ""
        : (val / 100).toLocaleString("en-US", {
              currency: "USD",
              style: "currency",
          })

export const currencyStrToCents = (amountString) => {
    const strippedAmountString = amountString.replace(/[^\d.-]/g, "")
    const rawAmount = parseFloat(strippedAmountString).toFixed(2) * 100
    if (isNaN(rawAmount)) {
        return undefined
    }
    return rawAmount
}

/**
 * Sends an action to sync backend values on relevant component mount/re-mount.
 *
 * @param {List} bankAccounts
 * @param {Function} fetchFunction
 * @param {Number} ledgerSettingsId
 */
export const updateBankAccountBalances = async (bankAccounts, fetchFunction, ledgerSettingsId) => {
    const bankAccountIds = bankAccounts.map((account) => account.id)

    // Do not request bank account balances if there are no bank accounts
    if (!bankAccountIds.length) {
        return
    }

    await fetchFunction(bankAccountIds, ledgerSettingsId)
}

export const updateLedgerSettingsAccountsState = (action, ledgerSettings) => {
    return ledgerSettings.map((ledgerInfo) => {
        if (ledgerInfo.get("id") === parseInt(action.response.ledgerSettingsId)) {
            const accounts = ledgerInfo.get("accounts")
            const relevantAccountIds = Object.keys(action.response.accountsInfo).map((id) => parseInt(id))
            const newAccounts = accounts.map((account) => {
                const accountId = account.get("id")
                if (relevantAccountIds.includes(accountId)) {
                    const currentBalance = action.response.accountsInfo[accountId]
                    return account.setIn(["current_balance"], currentBalance)
                }
                return account
            })
            return ledgerInfo.setIn(["accounts"], newAccounts)
        }
        return ledgerInfo
    })
}

export function validateInitialCheckNumber(beginningCheckNumber) {
    if (!positiveNumberValidation(beginningCheckNumber)) {
        return "Invalid initial check number"
    }
}

/**
 * Get the url to edit a transaction
 *
 * @param {object} transaction a transaction
 */
export function getTransactionEditUrl({ id, ledger_settings_id }) {
    return `/pacinternal/${ledger_settings_id}/transactions/${id}/`
}
