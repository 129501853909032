import { RegulationCommentForm } from "QuorumGrassroots/campaign-forms/components/RegulationCommentForm"
import { writeSectionConfigs } from "QuorumGrassroots/campaign-forms/write-form-constants"
import { getMessageFormWrapper } from "QuorumGrassroots/campaign-forms/wrappers/GetMessagesFormWrapper"
import { generateGrassrootsActionsWithImmutableValues } from "QuorumGrassroots/campaign-forms/helpers"

const { CampaignType } = DjangIO.app.grassroots.campaign.types

export const generateCommentOnRegulationActions = (messageValues, globalFormValues, props) => {
    return messageValues.reduce((acc, value) => {
        const submittingOrg = globalFormValues.submitting_org_name ?? value.submitting_org_name
        acc.push({
            text: [value.prebody, value.body, value.postbody].filter((text) => text).join("\n\n"),
            campaign: DjangIO.app.grassroots.campaign.models.Campaign.resourceUriFromId(props.campaign.id),
            supporter_action_type: props.campaign.campaign_type,
            ...(submittingOrg ? { submitting_org_name: submittingOrg } : {}),
        })
        return acc
    }, [])
}

export default getMessageFormWrapper({
    formName: "comment_on_regulation",
    generateGrassrootsActions: (messageValues, globalFormValues, props) =>
        generateGrassrootsActionsWithImmutableValues(
            generateCommentOnRegulationActions,
            messageValues,
            globalFormValues,
            props,
        ),
    writeSectionConfigs: writeSectionConfigs[CampaignType.comment_on_regulation.value],
})(RegulationCommentForm)
