import { makePieActionObjs } from "shared/imports/pieActionGenerators"

const simpleGetCampaignMessagesActions = makePieActionObjs("simple_get", "campaign_messages")
const getCampaignMessagesActions = makePieActionObjs("get", "campaign_messages")
const submitCampaignMessages = makePieActionObjs("submit", "campaign_messages")
const postGrassrootsSupporterAction = makePieActionObjs("post", "grassroots_supporter_action")
const patchGrassrootsSupporterAction = makePieActionObjs("patch", "grassroots_supporter_action")
const postExternalLog = makePieActionObjs("post", "external_log")
const callOfficeActions = makePieActionObjs("call", "office")
const getInteractionSupportersActions = makePieActionObjs("get", "interaction_supporters")

export const actionTypes = {
    ...getCampaignMessagesActions.actionTypes,
    ...submitCampaignMessages.actionTypes,
    ...postGrassrootsSupporterAction.actionTypes,
    ...patchGrassrootsSupporterAction.actionTypes,
    ...simpleGetCampaignMessagesActions.actionTypes,
    ...callOfficeActions.actionTypes,
    ...getInteractionSupportersActions.actionTypes,
    TOGGLE_TARGET: "TOGGLE_TARGET",
    SELECT_ALL_TARGETS: "SELECT_ALL_TARGETS",
    DESELECT_ALL_TARGETS: "DESELECT_ALL_TARGETS",
    CHANGE_MESSAGE_GROUP: "CHANGE_MESSAGE_GROUP",
    CHANGE_TARGET: "CHANGE_TARGET",
    STORE_CURRENT_MESSAGE: "STORE_CURRENT_MESSAGE",
    VIEW_NEXT_MESSAGE: "VIEW_NEXT_MESSAGE",
    RESET_WRITE_CAMPAIGN_SLICE: "RESET_WRITE_CAMPAIGN_SLICE",
    UPDATE_SENT_IDS: "UPDATE_SENT_IDS",
}

export const simpleGetCampaignMessagesActionTypes = simpleGetCampaignMessagesActions.pieDict
export const getCampaignMessagesActionTypes = getCampaignMessagesActions.pieDict
export const submitCampaignMessagesActionTypes = submitCampaignMessages.pieDict
export const postGrassrootsSupporterActionActionTypes = postGrassrootsSupporterAction.pieDict
export const patchGrassrootsSupporterActionActionTypes = patchGrassrootsSupporterAction.pieDict
export const postExternalLogTypes = postExternalLog.pieDict
export const callOfficeActionTypes = callOfficeActions.pieDict
export const getInteractionSupportersActionTypes = getInteractionSupportersActions.pieDict
