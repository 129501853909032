import { createSelector, createStructuredSelector } from "reselect"
import { campaignMessagePreviewReplacePlaceholders } from "QuorumGrassroots/helperFunctions"
import { selectActiveGroupId } from "QuorumGrassroots/campaign-forms/selectors/general-selectors"

const { CampaignType } = DjangIO.app.grassroots.campaign.types

export const selectCampaignCombinedCampaignsInfo = createSelector(
    (_, props) => props.campaign,
    (campaign) => (campaign && campaign.combined_campaigns_info) || [],
)

export const selectSubject = createSelector(
    (_, props) => props.campaign,
    (campaign) => campaignMessagePreviewReplacePlaceholders(campaign.pos_subject),
)

const messageReplacePlaceholdersHelper = (campaign) => {
    if (!campaign) return ""
    if (campaign.message_groups && campaign.message_groups.length > 0) {
        return campaignMessagePreviewReplacePlaceholders(
            [campaign.message_groups[0].prebody, campaign.message_groups[0].body, campaign.message_groups[0].postbody]
                .filter((item) => Boolean(item))
                .join("\n\n"),
        )
    }
    return campaignMessagePreviewReplacePlaceholders(
        [campaign.fixed_pre_message, campaign.pos_message, campaign.fixed_post_message]
            .filter((item) => Boolean(item))
            .join("\n\n"),
    )
}

/**
 * Given the campaign's pre-body, main body, and post-body message, join all existing values
 * into a single string separated by two newlines.
 *
 * @param  {object} campaign  - object with campaign information
 * @return {string}
 */
export const selectMessage = createSelector(
    (_, props) => props.campaign,
    (campaign) => messageReplacePlaceholdersHelper(campaign),
)

/**
 * Replaces placeholders while keeping the body, prebody and postbody separate, differing from selectMessage
 * so that we can edit the message properly on one click campaigns.
 * When all campaign types are using this, deprecate the selectMessage selector
 */
export const selectMessageForEditablePreview = createSelector(
    (_, props) => props.campaign,
    (campaign) => ({
        prebody: campaignMessagePreviewReplacePlaceholders(campaign.fixed_pre_message),
        body: campaignMessagePreviewReplacePlaceholders(campaign.pos_message),
        postbody: campaignMessagePreviewReplacePlaceholders(campaign.fixed_post_message),
    }),
)

export const selectMessageGroups = createSelector(
    (_, props) => props.campaign,
    (campaign) => campaign?._extra?.message_groups ?? [],
)

export const selectIsCombinedCampaign = createSelector(
    (_, props) => props.campaign,
    (campaign) => campaign.campaign_type === CampaignType.combined_campaign.value,
)

export const selectCombinedCampaignMessages = createSelector(
    selectCampaignCombinedCampaignsInfo,
    (campaignCombinedCampaignsInfo) =>
        campaignCombinedCampaignsInfo
            .map((info) => {
                const message = messageReplacePlaceholdersHelper(info)
                const subject = campaignMessagePreviewReplacePlaceholders(info.pos_subject)

                if (message || subject) {
                    return {
                        name: info.name,
                        message,
                        subject,
                    }
                }
                return null
            })
            .filter((info) => info),
)

export const selectCombinedCampaignInitialValues = createSelector(
    selectCombinedCampaignMessages,
    (combinedCampaignMessages) =>
        combinedCampaignMessages.reduce((acc, campaign, idx) => {
            acc[`campaign-message-${idx}`] = campaign.message
            acc[`campaign-subject-${idx}`] = campaign.subject
            return acc
        }, {}),
)

export const selectInitialMessageForGroups = createSelector(selectMessageGroups, (messageGroups) => {
    const initialValues = {}
    messageGroups.forEach((group) => {
        initialValues[group.id] = {
            subject: campaignMessagePreviewReplacePlaceholders(group.subject),
            prebody: campaignMessagePreviewReplacePlaceholders(group.prebody),
            body: campaignMessagePreviewReplacePlaceholders(group.body),
            postbody: campaignMessagePreviewReplacePlaceholders(group.postbody),
        }
    })
    return initialValues
})

export const selectInitialValues = createSelector(
    selectSubject,
    selectMessage,
    selectCombinedCampaignInitialValues,
    selectInitialMessageForGroups,
    selectMessageForEditablePreview,
    (subject, message, combinedCampaignInitialValues, messageGroupsInitialValues, messageForEditablePreview) => ({
        subject,
        message,
        ...combinedCampaignInitialValues,
        ...messageGroupsInitialValues,
        global: {
            ...(subject ? { subject } : {}),
            ...messageForEditablePreview,
        },
    }),
)

export const campaignMessagePreviewConnection = createStructuredSelector({
    activeGroupId: selectActiveGroupId,
    campaignSubject: selectSubject,
    campaignMessage: selectMessage,
    combinedCampaignMessages: selectCombinedCampaignMessages,
    initialValues: selectInitialValues, //default values for redux form
    isCombinedCampaign: selectIsCombinedCampaign,
    messageGroups: selectMessageGroups,
})
