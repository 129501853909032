import React from "react"
import PropTypes from "prop-types"
import { Field, FormSection } from "redux-form/immutable"
import { connect } from "react-redux"
import { Textarea } from "@mantine/core"

import { StyledButton } from "QuorumGrassroots/styled-components/components/StyledButton"

import { callFormSectionSelector } from "QuorumGrassroots/campaign-forms/selectors/form-section-selectors"
import { viewNextBody, callOffice } from "QuorumGrassroots/campaign-forms/action-creators"
import { StyledSpacing } from "QuorumGrassroots/styled-components/components/StyledSpacing"
import { InputField } from "app/static/frontend/forms/components/InputField"

interface CallFormSectionProps {
    // pass through props
    activeId: string
    campaignId: number
    feedbackText: string
    formName: string
    isCampaignPage: boolean
    isMultiAction: boolean
    key: string
    setFeedbackText: (text: string) => void
    t: (text: string) => string
    uniqueWidgetId: string

    // connect props
    callOffice: (uniqueWidgetId: string, campaignId: number, messageKey: string, targetPhone: string) => void
    grassrootsActionId?: number
    isCalling: boolean
    target: {
        is_custom: boolean
        custom_target: string
        office_label: string
        name: string
        phone: string
    }
    viewNextBody: () => void
}

const UnconnectedCallFormSection = ({ t, target, ...props }: CallFormSectionProps) => {
    const callOffice = () => {
        const phoneNumber = target?.phone || target?.custom_target

        props.callOffice(props.uniqueWidgetId, props.campaignId, props.activeId, phoneNumber)
    }

    const callString = target
        ? target.is_custom
            ? target.custom_target
            : `the ${target.office_label} of ${target.name} at ${target.phone}`
        : ""

    return (
        <FormSection className="call-form-section" name={props.activeId}>
            <Field component={InputField} name="script" label={t("campaign.call.script")} isTextarea dataCy="script" />
            {!props.grassrootsActionId && (
                <StyledButton
                    onClick={callOffice}
                    disabled={props.isCalling}
                    data-cy="call"
                    isCampaignPage
                    activateNGGTheme
                    applyMarginTop
                >
                    {props.isCalling
                        ? t("campaign.call.calling", { call_string: callString })
                        : t("campaign.call.click_to_call", { call_string: callString })}
                </StyledButton>
            )}
            {props.grassrootsActionId && !props.isMultiAction && (
                <>
                    <StyledSpacing />
                    <Field
                        component={InputField}
                        name="text"
                        label={t("campaign.call.follow_up")}
                        isTextarea
                        dataCy="call-follow-up"
                        isCampaignPage
                    />
                </>
            )}
            {props.grassrootsActionId && props.isMultiAction && (
                <>
                    <StyledSpacing />
                    <form>
                        <Textarea
                            autosize
                            label={t("campaign.call.follow_up")}
                            minRows={3}
                            onChange={(e) => props.setFeedbackText(e.target.value)}
                            value={props.feedbackText}
                        />
                    </form>
                </>
            )}
        </FormSection>
    )
}

UnconnectedCallFormSection.propTypes = {
    // pass through props
    activeId: PropTypes.string.isRequired,
    campaignId: PropTypes.number.isRequired,
    feedbackText: PropTypes.string.isRequired,
    formName: PropTypes.string.isRequired,
    isCampaignPage: PropTypes.bool.isRequired,
    isMultiAction: PropTypes.bool.isRequired,
    key: PropTypes.string.isRequired,
    setFeedbackText: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    uniqueWidgetId: PropTypes.string.isRequired,

    // connect props
    callOffice: PropTypes.func.isRequired,
    grassrootsActionId: PropTypes.number,
    isCalling: PropTypes.bool.isRequired,
    target: PropTypes.object.isRequired,
    viewNextBody: PropTypes.func.isRequired,
}

export const CallFormSection = connect(callFormSectionSelector, { viewNextBody, callOffice })(
    UnconnectedCallFormSection,
)
