import { Campaign } from "@/types/djangio"

const { CommentCampaignOrganizationTypes } = DjangIO.app.grassroots.campaign.types

const gamificationCampaignTypePoints = window.action_center_settings?.gamification_campaign_type_points
const gamificationAllCampaignPoints = window.action_center_settings?.gamification_all_campaign_points
const gamificationEnabled = window.action_center_settings?.gamification_enabled
const gamificationPointType = window.action_center_settings?.gamification_point_type

export const getPointsForGamificationType = (
    campaignPoints: Record<number, number>,
    pointsMode: number | undefined,
    customPoints: number | undefined,
    campaignType: number | undefined,
) => {
    const defaultForCampaign = campaignPoints[campaignType]
    switch (pointsMode) {
        case DjangIO.app.grassroots.types.GamificationPointsMode.default.value:
            return defaultForCampaign
        case DjangIO.app.grassroots.types.GamificationPointsMode.custom_points.value:
            return customPoints
        case DjangIO.app.grassroots.types.GamificationPointsMode.disable.value:
            return 0
        default:
            console.error(`Bad gamification_points_mode ${pointsMode}`)
            return defaultForCampaign
    }
}

export const getPointsForCampaign = (campaign: Campaign) => {
    return getPointsForGamificationType(
        getParsedCampaignPoints(
            gamificationEnabled,
            gamificationPointType,
            gamificationAllCampaignPoints,
            gamificationCampaignTypePoints,
        ),
        Number(campaign?.gamification_points_mode),
        Number(campaign?.custom_gamification_points),
        Number(campaign?.campaign_type),
    )
}

export const getParsedCampaignPoints = (
    gamificationEnabled: boolean,
    gamificationPointType: number,
    gamificationAllCampaignPoints: number,
    gamificationCampaignTypePoints: Record<number, number>,
) => {
    if (!gamificationEnabled) {
        return {}
    }

    const { CampaignType } = DjangIO.app.grassroots.campaign.types
    const samePointType =
        gamificationPointType === DjangIO.app.grassroots.types.GamificationCampaignPointType.same.value

    return CampaignType.items().reduce((result, campaignType) => {
        const selectedPointValue = samePointType
            ? gamificationAllCampaignPoints
            : gamificationCampaignTypePoints[campaignType.value]

        result[campaignType.value] =
            selectedPointValue || selectedPointValue === 0
                ? selectedPointValue
                : campaignType.gamification_default_point_value

        return result
    }, {})
}

export const getCSRF = () => {
    const cookies = document.cookie.split(";")
    if (!cookies?.length) return ""
    const csrfCookie = cookies.find((cookie) => cookie.includes("csrftoken"))
    return csrfCookie?.split("=")[1]
}

export const adaptTargetedMessagesToActionsGenerator = (messages: object, targets: object) => {
    const adaptedMessages = []
    const messageGroups = Object.keys(messages)
    for (const [groupId, message] of Object.entries(messages)) {
        const targetsForGroup = targets[groupId]
        for (const target of targetsForGroup) {
            if (!target.targeted) continue
            adaptedMessages.push({
                subject: message.subject,
                prebody: message.preBody,
                body: message.body,
                postbody: message.postBody,
                target: {
                    ...(target.is_custom ? { is_custom: true } : {}),
                    id: target.value,
                },
                message_group_id: groupId,
            })
        }
    }
    return { adaptedMessages, messageGroups }
}

export const adaptMessagesWithoutTargetsToActionsGenerator = (messages: object) => {
    const message = messages[Object.keys(messages)[0]]
    const adaptedMessages = [
        {
            subject: message.subject,
            prebody: message.preBody,
            body: message.body,
            postbody: message.postBody,
        },
    ]
    const globalValues = message.submitting_org_name ? { submitting_org_name: message.submitting_org_name } : {}
    return { adaptedMessages, globalValues }
}

const isImmutableObject = (obj: any): obj is ImmutableObject => {
    return obj?.toJS !== undefined
}

export const generateGrassrootsActionsWithImmutableValues = (
    generateActionFunc: GenerateGrassrootsAction,
    messageValues: ImmutableObject,
    globalFormValues: ImmutableObject | object,
    props: object,
) => {
    const jsMessageValues = messageValues.toJS()
    const arrayMessageValues = Object.values(jsMessageValues)
    const jsFormValues = isImmutableObject(globalFormValues) ? globalFormValues.toJS() : globalFormValues
    return generateActionFunc(arrayMessageValues, jsFormValues, props)
}

/**
 * Checks if a Comment on Regulation Campaigns allows supporters to add
 * the name of the organization they are part of on the comment submission form
 */
export const shouldShowOrganizationField = (campaign: Partial<Campaign>): boolean => {
    return campaign.comment_organization_type === CommentCampaignOrganizationTypes.supporter_chooses.value
}
